// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from 'react'
import { ApexOptions } from 'apexcharts';
import { setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

interface CustomChartOptions extends ApexOptions{
    chart: {
      type: 'bar';
     toolbar: {
        show: false 
    }
    };
    plotOptions: {
      bar: {
        borderRadius: number;
        borderRadiusApplication: 'end' | 'around';
        horizontal: boolean;
        barHeight:string;
        dataLabels: {
          position: 'top',
        },
      };
    };
    colors:string[],
    grid: {
        borderColor:string,
        xaxis: {
            lines: {
                show: boolean 
            }
        },
        yaxis: {
            lines: {
                show: boolean 
            }
        }
    },
    tooltip: {
        enabled: boolean 
    },
    dataLabels: {
      textAnchor: "start"
      offsetX:number,
      enabled: boolean,
      formatter: (value: string) => string,
      style: {
        fontSize: string,
        fontWeight:number,
        colors: string[] ,
        },
    };
    xaxis: {
      categories: string[],
      labels: {
        formatter: (value: string) => string; 
        };
    };
    yaxis: {
        labels: {
            align: 'left', 
            offsetX: number,
            style: {
                fontSize: string,
                fontWeight: number,
                color: string,
            }
        },
     
    },
    states: {
        hover: {
            filter: {
                type: string
            }
        },
        active: {
            filter: {
                type: string
            }
        }
    }
}

interface CustomChartSeries {
    data: number[];
}

interface VendorData {
    name: string;
    value: number;
}

interface VendorsResponse {
    vendors_data: VendorData[];
}
export interface DialogProps {
    openDialog: boolean;
    handleCloseDialog: () => void;
    apiError: boolean;
    errMsg: string;
    successMsg: string;
}

export interface Props {
    history: any;
    navigation: any;
}
interface SS { }
interface S {
    rows: any;
    columns: any;
    series: any;
    invoiceRows: any;
    tabValue: any;
    isNoDataAvailable: boolean;
    inputName: any;
    addNameErrMsg: any;
    openDialog: any;
    vendorItems: any;
    vendorValue: any;
    ProjectDataFlag: boolean;
    ReportDataFlag: boolean;
    projectData: any;
    type: string;
    vendorChangeReportData: any;
    reportWithoutInvoiceData: any;
    isLoading: any;
    page: any;
    rowsPerPage: any;
    isChartDataLoading: boolean;
    openModel: boolean;
    projectDropDown: any;
    reportDropDown: any;
    projectValue: string;
    ReportValue: any;
    searchQueryProject: any;
    filteredDataProject: any;
    searchQueryReport: any;
    filteredDataReport: any;
    projectId: number;
    isProjectValueEmpty: boolean;
    isReportValueEmpty: boolean;
    openVendorChart:boolean;
    optionsbar:CustomChartOptions;
    seriesbar:CustomChartSeries[];
    chartHeight:number;

}

export default class DashboardController extends BlockComponent<
    Props,
    S,
    SS
> {
    /**
     *
     */
    scrollToBottomRef: React.RefObject<any>;

    addProjectId: string = "";
    getReportWithoutInvoiceId: string = "";
    getVendorsDataId: string = "";
    getProjectDataId: string = "";
    vendorChangeDataId: string = "";
    reportWithoutInvoiceId: string = "";
    getProjectDataSelectId: string = "";
    getReportDataSelectId: string = "";
    getVendorsDatachrtId: string = "";

    constructor(props: Props) {
        super(props);
        this.scrollToBottomRef = React.createRef();
        this.state = {
            rows: [
                {
                    id: "1",
                    report_id: "2sdfr32",
                    Date: "09-11-2023,1:28pm",
                    Vendor: "Core & Main",
                    Project: "Project & Infinity",
                    Quote: true,
                    Invoice: false
                },
                {
                    id: "2",
                    report_id: "2sdfr32",
                    Date: "09-11-2023,1:28pm",
                    Vendor: "Core & Main",
                    Project: "Project & Infinity",
                    Quote: true,
                    Invoice: false
                }
            ],
            columns: [
                {
                    id: "1",
                    label: "S.no"
                },
                { id: "2", label: "Report Id" },
                { id: "3", label: "Date" },
                { id: "4", label: "Vendor" },
                { id: "5", label: "Project" },
                { id: "6", label: "Quote" },
                { id: "7", label: "Invoice" },
                { id: "8", label: "Action" }
            ],
            series: [20, 50],
            invoiceRows: [],
            isNoDataAvailable: true,
            tabValue: 0,
            inputName: '',
            addNameErrMsg: '',
            openDialog: false,
            vendorItems: [],
            vendorValue: 0,
            ProjectDataFlag: false,
            ReportDataFlag: false,
            projectData: {},
            type: "",
            vendorChangeReportData: {},
            reportWithoutInvoiceData: [],
            isLoading: false,
            page: 0,
            rowsPerPage: 5,
            isChartDataLoading: true,
            openModel: false,
            projectDropDown: [],
            reportDropDown: [],
            projectValue: '',
            ReportValue: '',
            searchQueryProject: '',
            filteredDataProject: [],
            searchQueryReport: '',
            filteredDataReport: [],
            projectId: 0,
            isProjectValueEmpty: false,
            isReportValueEmpty: false,
            openVendorChart:true,
            chartHeight:380,
            seriesbar: [{
                data: []
            }],
            optionsbar: {
                chart: {
                  type: 'bar',
                  toolbar: {
                    show: false 
                }
                },
                plotOptions: {
                  bar: {
                    borderRadius: 5,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                    barHeight: '11px',
                    dataLabels: {
                        position: 'top',
                    },
                  },
                },
                colors: ['#6E6EB5'],
                grid: {
                  borderColor: 'rgba(190, 190, 190, 0.5)',
                    xaxis: {
                        lines: {
                            show: true 
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false 
                        }
                    }
                },
                tooltip: {
                    enabled: false 
                },
                dataLabels: {
                  enabled: true,
                  style: {
                    fontWeight:400,
                    fontSize: '11px',
                    colors: ['#05052B'] ,
                    },
                  formatter: (value: string) => `$ ${value}`,
                  offsetX: 30,
                  textAnchor: "start"
                },
                xaxis: {
                  categories: [],
                  labels: {
                    formatter: (value:string) => `$ ${value}`,
                  }
                },
                yaxis: {
                    labels: {
                        align: 'left', 
                        offsetX: -10,
                        style: {
                            fontSize: '10px',
                            fontWeight: 400,
                            color: '#05052B',
                        },
                    },
                 
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none'
                        }
                    },
                    active: {
                        filter: {
                            type: 'none'
                        }
                    }
                }
            },
            
        };
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }



    async componentDidMount() {
        this.getProjectData(0)
        this.getVendorsData()
        this.handleVendorChangeApiCall(0)
        this.getReportWithoutInvoice()
        this.projectData()
        this.reportData()
    }

    handleBlankBox = () => {
        this.setState({ openVendorChart: !this.state.openVendorChart })
        this.getVendorBarchart()
    }

    formattedNumber = (number: any) => {
        const result = Number(parseFloat(number).toFixed(0));
        const formatNumber = new Intl.NumberFormat('en-IN', {
            maximumFractionDigits: 2,
        }).format(result);
        return formatNumber
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.handleProjectResponse(apiRequestCallId, responseJson)

            if (apiRequestCallId === this.getVendorsDataId) {
                const data: any[] = responseJson.data.map((tableData: any) => ({
                    ...tableData.attributes,
                }));
                this.setState({ vendorItems: data })
            }
            if (apiRequestCallId === this.getProjectDataId) {
                this.setState({ projectData: responseJson.data })
            }
            if (apiRequestCallId === this.vendorChangeDataId) {
                this.setState({ vendorChangeReportData: responseJson, isChartDataLoading: false })
                this.handleSeriesData(responseJson)
            }
            if (apiRequestCallId === this.reportWithoutInvoiceId) {
                this.setState({ reportWithoutInvoiceData: responseJson.report_invoice_dashboard })
            }
            if (apiRequestCallId === this.getProjectDataSelectId) {
                this.receiveProjectData(responseJson)
            }
            if (apiRequestCallId === this.getReportDataSelectId) {
                this.receiveReportData(responseJson)
            }
            if(apiRequestCallId === this.getVendorsDatachrtId){
                this.vendorBarchartResponse(responseJson)
            }


        }
    }

    getVendorBarchart = async() => {
        this.setState({isChartDataLoading:true})
        const header = {
            "Content-Type": configJSON.quotesApiContentType,
            "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
        };

        const quotesComparisonMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getVendorsDatachrtId = quotesComparisonMessage.messageId;

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.vendorsChartSelected
        );

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(quotesComparisonMessage.id, quotesComparisonMessage);
        return true;
    }

    vendorBarchartResponse = (responseJson:VendorsResponse) =>{
        this.setState({isChartDataLoading:false})
        const dataCahrt = responseJson?.vendors_data

        const { highValues, zeroValues } = dataCahrt.reduce((accvalue, item) => {
            if (item.value > 0) {
                accvalue.highValues.push(item);
            } else {
                accvalue.zeroValues.push(item);
            }
            return accvalue;
          }, { highValues: [], zeroValues: [] }as any);

          const sortedHighValues = [];
        while (highValues.length) {
             let maxIndex = 0;
              for (let i = 1; i < highValues.length; i++) {
               if (highValues[i].value > highValues[maxIndex].value) {
               maxIndex = i;
                }
             }
              sortedHighValues.push(highValues.splice(maxIndex, 1)[0]);
            }
         const finalData = [...sortedHighValues, ...zeroValues];
        const vendorNames = finalData?.map((namevendor) => namevendor.name)
        const vendorValue = finalData?.map((namevendor) => namevendor.value)

        const dataPointHeight = 40; 
        const calculatedHeight = dataPointHeight * vendorNames.length;
        const fixHeight = 380 > calculatedHeight ? 380 : calculatedHeight
        this.setState({
            chartHeight:fixHeight,
            optionsbar: {
                ...this.state.optionsbar,
                xaxis: {
                    categories: vendorNames,
                    labels: {
                        formatter: (value:string) => `$ ${value}`,
                    }
                }
            },
            seriesbar: [{
                data: vendorValue
            }]
        });
    }

    handleSeriesData = (responseJson: any) => {
        if (responseJson.status === 'overcharged') {
            this.setState({ series: [responseJson.status_percentage, 0] })
        } else if (responseJson.status === 'undercharged') {
            this.setState({ series: [0, responseJson.status_percentage] })
        } else {
            this.setState({ series: [0, 0] })
        }
    }

    handleVendorChange = (event: any) => {
        if (event.target.value !== undefined) {
            this.setState({ vendorValue: event.target.value })
            this.handleVendorChangeApiCall(event.target.value)
        }
    }


    formatterFn = () => `$ ${this.state.vendorChangeReportData.total_discrepancy}`
    handleClickChangePage = (event: unknown, newPage: number) => {
        this.setState({ page: newPage });
    };

    handleChangePerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ page: 0, rowsPerPage: parseInt(event?.target?.value, 10) });
    };

    handleVendorChangeApiCall = (value: any) => {
        this.setState({ isChartDataLoading: true })
        const header = {
            token: this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.vendorChangeDataId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorChangeApiEndPoint}?id=${value === 0 ? "all" : value}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    handleProjectResponse = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.addProjectId) {
            if (responseJson.errors) {
                if(responseJson.errors[0].project){
                this.setState({ addNameErrMsg: responseJson.errors[0].project })
                }else{
                this.setState({ addNameErrMsg: responseJson.errors })
                }
            } else {
                this.setState({ openDialog: false, inputName: '' });
                this.projectData()
                this.reportData()
            }
        }
    }

    handleType = (value: any) => {
        switch (value) {
            case 0:
                this.setState({ type: 'week' })
                return 'week';
            case 1:
                this.setState({ type: 'month' })
                return 'month';
            case 2:
                this.setState({ type: 'year' })
                return 'year';
            case 3:
                return 'all';
            default:
                return 'week';
        }
    }

    handleValueChange = (event: React.ChangeEvent<{}> | any, newValue: number) => {
        this.setState({ tabValue: newValue });
        this.getProjectData(newValue)
    };

    handleNoData = () => {
        this.setState({ isNoDataAvailable: !this.state.isNoDataAvailable })
    }

    handleOpenDialog = (event: any) => {
        event.stopPropagation();
        this.setState({ openDialog: true })
    }

    getToken = () => {
        return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    }

    handleInputChange = (event: any) => {
        const { target: { value } } = event;
        this.setState({ inputName: value, addNameErrMsg: '' })
    }

    getVendorsData = () => {
        const header = {
            token: this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getVendorsDataId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getVendorsEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    handleClose = () => {
        this.setState({ openDialog: false, inputName: '', addNameErrMsg: '' });
    };

    getProjectData = (newValue: any) => {
        const header = {
            token: this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProjectDataId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getProjectsApiEndPoint}?interval_type=${this.handleType(newValue)}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    handleAddProject = () => {
        const header = {
            token: this.getToken()
        };

        const formData: any = new FormData();

        formData.append('project', this.state.inputName);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addProjectId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addProjectEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileUploadApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    getReportWithoutInvoice = () => {
        const header = {
            token: this.getToken()
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reportWithoutInvoiceId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reportWithoutInvoiceAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    handleSaveData = (data: any, docType: string) => {
        if (docType == "Quote") {
            this.setState({ rows: data })
        }
        if (docType == "Invoice") {
            this.setState({ invoiceRows: data })
        }
        if (docType == "") {
            this.setState({ rows: data, invoiceRows: data })
        }
    }

    handleOpenPopup = () => {
        this.setState({ openModel: true })
    }

    handleClosePopup = () => {
        this.setState({ isReportValueEmpty: false, isProjectValueEmpty: false,  openModel: false, ProjectDataFlag: false,ReportDataFlag: false, searchQueryReport: "", searchQueryProject: "", projectValue: "", ReportValue: ""  });
    };


    handleSearch = (event: any) => {
        const query = event.target.value;
        this.setState({ searchQueryProject: query }, () => {
            const filteredDataProject = this.state.projectDropDown.filter((item: { value: string; }) =>
                item.value.toLowerCase().includes(query.toLowerCase())
            );
            this.setState({ filteredDataProject });
        });
    };

    handleReport = (event: any) => {
        const query = event.target.value;
        this.setState({ searchQueryReport: query }, () => {
            const filteredDataReport = this.state.reportDropDown.filter((item: { value: string; }) =>
                item.value.toLowerCase().includes(query.toLowerCase())
            );
            this.setState({ filteredDataReport });
        });
    };

    projectData = () => {
        const header = {
            "Content-Type": configJSON.quotesApiContentType,
            "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
        };

        const quotesComparisonMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProjectDataSelectId = quotesComparisonMessage.messageId;

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.projectDataSelected
        );

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(quotesComparisonMessage.id, quotesComparisonMessage);
        return true;
    }

    receiveProjectData = (responseJson: any) => {

        if (responseJson) {
            console.log(responseJson,"Array")
            responseJson.reports.length > 0 && this.setState({isNoDataAvailable:false})
            const projectData = responseJson.reports.map((report: any) => {
                return {
                    id: report[0],
                    value: report[1],
                };
            });
            this.setState({ filteredDataProject: projectData, projectDropDown: projectData })
        }

    }

    reportData = () => {
        const header = {
            "Content-Type": configJSON.quotesApiContentType,
            "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
        };

        const quotesComparisonMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getReportDataSelectId = quotesComparisonMessage.messageId;

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reportDataSelected
        );

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        quotesComparisonMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(quotesComparisonMessage.id, quotesComparisonMessage);
        return true;
    }


    receiveReportData = (responseJson: any) => {
        if (responseJson) {
            const reportsData = responseJson.reports.map((report: any) => {
                return {
                    id: report[0],
                    value: report[1],
                };
            });

            this.setState({ reportDropDown: reportsData, filteredDataReport: reportsData })
        }

    };

    emptyValue = (e:any) => {
        if(!this.state.projectValue || !this.state.ReportValue) {
            e.preventDefault();
            this.setState({
                isProjectValueEmpty: !!!this.state.projectValue,
                isReportValueEmpty: !!!this.state.ReportValue,
            });
        }
    };

    projectSortedData = () => {
        this.setState({
            ProjectDataFlag: !this.state.ProjectDataFlag,
            ReportDataFlag: false,
            isProjectValueEmpty: false,
            searchQueryProject: ""
        }, () => { this.projectData() });
    };

    reportSortedData = () => {
        this.setState({
            ReportDataFlag: !this.state.ReportDataFlag,
            ProjectDataFlag: false, isReportValueEmpty: false,
            searchQueryReport: ""
        }, () => { this.reportData() });
    }; 
}
// Customizable Area End