// Customizable Area Start
import React, { StyleHTMLAttributes } from "react";
import {
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Card,
    Box,
    Grid,
    Tabs,
    Tab,
    Button,
    Link,
    Breadcrumbs,
    FormControlLabel,
    Switch,
    TextField

} from "@material-ui/core";

// import NavigateNextIcon from '@mui/icons-material/NavigateNextIcon';
import Header from "../../landingpage/src/Header.web";
import ReportResultsController, { Props, configJSON } from "./ReportResultsController.web";
import { createTheme, withStyles } from "@material-ui/core/styles";
import moment from "moment";
const { Document, Page } = require('react-pdf');
import { quotePdf, resize } from "./assets";
import { InvoiceDropbox, QuoteDropbox } from "./CreateReport.web";
import Pagination from '@material-ui/lab/Pagination';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import Chart from "react-apexcharts";
import { TableData } from "./VerifyReport.web";





export const theme = createTheme({});



export const RenderIframeView = (props:any)=>{
    const {classes,quoteUrl,pageNumberQuote,handleQuoteResize,quotePdfpage,handlePrevPageQuotePdf,handleNextPageQuotePdf,handleQuoteUpload} = props
                                return <>
                                    {quoteUrl ? <>

                                            <Box className={classes.previewInvoiceInnerBox}>
                                                <iframe src={`${quoteUrl} #page=${pageNumberQuote} `} className={classes.pdfView}></iframe>

                                            </Box>
                                            <Box className={classes.resizeAndPageWrapper1}>
                                                <Box><img src={resize} className={classes.ImgIconStyle} onClick={handleQuoteResize} alt="" height={20} width={20} /></Box>
                                                <Box className={classes.pageBox}>

                                                    <Typography>
                                                        Page {pageNumberQuote} of {quotePdfpage}
                                                    </Typography>

                                                    <button onClick={handlePrevPageQuotePdf} data-testid={"QuotePreviousBtnId"} style={{ background: "none" }} >
                                                        {'<'}
                                                    </button>
                                                    <Typography style={boxStyle.pageNumber}>{pageNumberQuote}</Typography>
                                                    <button onClick={handleNextPageQuotePdf} data-testid={"QuoteNextBtnId"} style={{ background: "none" }}>
                                                        {'>'}
                                                    </button>
                                                </Box>
                                            </Box>
                                        </> :
                                            <Box style={{ height: "94vh" }}>
                                                <QuoteDropbox tabValue={4} uploadAcceptedFile={undefined} acceptedFile={undefined} classes={classes} onDrop={handleQuoteUpload} data-testid="QuoteDropboxId" pdfUrl={quoteUrl} handleDelete={undefined} />
                                            </Box>
                                        }
                                    </>
}


export const TableHeadSwitch = (props:any)=>{
    const {taxSwitch1,columnWithTax1,invoiceColumn1} = props;
    return <>
    {taxSwitch1 ?<TableHead>
                                    {columnWithTax1.map((data: any) => (
                                        <TableCell
                                            key={data.id}
                                            align={data.align}
                                            style={{ minWidth: data.minWidth }}
                                        >
                                            {data.label}
                                        </TableCell>
                                    ))}
                                </TableHead>:<TableHead>
                                    {invoiceColumn1.map((data: any) => (
                                        <TableCell
                                            key={data.id}
                                            align={data.align}
                                            style={{ minWidth: data.minWidth }}
                                        >
                                            {data.label}
                                        </TableCell>
                                    ))}
                                </TableHead>}
    </>
}


export const TableCellForTax = (props:any)=>{
    const {taxSwitchReq,taxReq} = props;
    return <>
    {taxSwitchReq && <TableCell>{taxReq ?  taxReq : 0} %</TableCell>}
    </>
}


export class ReportResults extends ReportResultsController {


    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End


    }




    render() {
        const { classes, navigation, handleSaveData} = this.props 
        const invoiceMetaData = this.handleInvoiceData()


       const taxSwitch1 = this.state.updated_table_data[this.state.invoiceValue]?.taxSwitch 
        ? this.state.updated_table_data[this.state.invoiceValue]?.taxSwitch
        : false;

        
        return (
            <>
                <Card>
                    <Box className={classes.mainDivWrapper}>
                        <Box style={{ position: "relative" }} >
                            <Chart
                                options={this.state.options}
                                series={this.state.series}
                                type="radialBar"
                                height={260}
                                width={380}
                            />
                            <div style={{ position: "absolute", top: "55%", left: "45%", transform: "translate(-50%, -50%)",display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column' }}>
                                <p style={{ fontSize: "10px",fontWeight:500,color:'#05052B' }}>Total discrepancy</p>
                                <p style={{ fontSize: "16px",fontWeight:500  }}>{this.handleQuoteAndInvoiceValidation() ? this.calculateDiscrepancy(invoiceMetaData, this.props.quoteData.quotedetails) : "N/A"}</p>
                                <p style={{ fontSize: "10px", color: this.state.discrepancyMsgColor, textAlign: "center",fontWeight:500 }}>{this.handleQuoteAndInvoiceValidation() ? this.state.discrepancyMsg : ""}</p>
                            </div>
                        </Box>



                        <Box className={classes.totalInvoiceQuoteWrapperReport}>

                            <Box className={classes.totalQuoteBoxReport}>
                                <Box className={classes.innerTotalQuoteBoxReport}>
                                    <Box className={classes.QuoteSmallBoxReport}>

                                    </Box>
                                    <Box>
                                        <Typography className={classes.textStyle3}>{configJSON.totalQuoteText}</Typography>
                                        <Typography className={classes.textStyle1}>$ {Number(this.handleTotalQuoteAmount()).toFixed(2)}</Typography>

                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.totalInvoiceBoxReport}>
                                <Box className={classes.innerTotalInvoiceBoxReport}>
                                    <Box className={classes.invoiceSmallBoxReport}>

                                    </Box>
                                    <Box >
                                        <Typography className={classes.textStyle3}>{configJSON.totalInvoiceText}</Typography>
                                        <Typography className={classes.textStyle1}>$ {Number(this.handleTotalInvoiceAmount()).toFixed(2)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Card>

                <Card className={classes.containerBoxReport}>
                    <Box className={classes.toggleButtonStyle}>
                        <FormControlLabel
                            value="start"
                            control={<Switch checked={this.state.toggleValue} data-testid="pdfToFormBtn" className={classes.switchStyle} onChange={this.pdfFormToggleChangeHandler} color="default" />}
                            label={!this.state.toggleValue ? "PDF View" : "Form View"}
                            labelPlacement="start"
                        />
                    </Box>
                    <Box className={classes.innerContainerBox}>
                        <Typography variant="h6" style={{ display: `${this.state.fullViewScreen}` }} className={classes.previewTitle}>{configJSON.quoteText}</Typography>
                        <Typography variant="h6" style={{ display: `${this.state.halfViewScreen}` }} className={classes.previewTitle1}>{configJSON.invoiceText}</Typography>
                    </Box>
                    <Box className={classes.innerContainerBox}>
                        <Box style={{ width: this.state.fullViewScreen === "block" ? "100%" : "", display: this.state.fullViewScreen === "none" ? "none" : "", }} className={classes.pdfBox}>
                            <Tabs
                                value={this.state.quoteValue}
                                className={classes.pdfInnerBox1}
                                data-testid="tabChangeid1"
                            >

                                {
                                  Array.isArray(this.state.quotesTabs) && this.state.quotesTabs?.map((data: any) => {
                                        return (
                                            <Tab
                                                label={
                                                    <>
                                                        <Box className={classes.pdfcurveBox}>
                                                            <Box className={classes.pdfcurveInnerBox}></Box>
                                                        </Box>
                                                        <Typography variant="subtitle2" style={{whiteSpace: "nowrap",overflow: "hidden",
                                                            textOverflow: "ellipsis", maxWidth: "8ch", textTransform: "none"
                                                        }}>{data.name}</Typography>
                                                        <Box className={classes.pdfcurveRightBox}>
                                                            <Box className={classes.curveRightPdfInnerBox}></Box>
                                                        </Box>
                                                    </>
                                                }
                                                {...pdfProps(0)}
                                                title={data.name}
                                                className={classes.pdfTitleBox}
                                            />
                                        )
                                    })
                                }

                            </Tabs>

                            <Box className={classes.previewQuoteOutterBox}>
                            {this.state.toggleValue && this.props.quoteUrl ? <QuoteFormView handleEditChangePage={this.handleEditChangePage} segmentTotal2={this.props.segmentTotal2} quoteCategoryDescription={this.props.quoteCategoryDescription} classes={classes} quoteRowsPerPage={10} segmentName2={this.props.segmentName2} segmentName1={this.props.segmentName1} segmentTotal1={this.props.segmentTotal1} fullSize={this.state.fullViewScreen} handleResize={this.handleQuoteResize} quotePage={this.state.quotePage} handleChangePage={this.handleQuotePageChange}  quoteColumn={this.state.columns} quoteData={this.props.quoteData} />:       
                                <RenderIframeView classes={this.props.classes} quoteUrl={this.props.quoteUrl} pageNumberQuote={this.state.pageNumberQuote} handleQuoteResize={this.handleQuoteResize} quotePdfpage={this.props.quotePdfpage} handlePrevPageQuotePdf={this.handlePrevPageQuotePdf} handleNextPageQuotePdf={this.handleNextPageQuotePdf} handleQuoteUpload={this.props.handleQuoteUpload}/>
                            }
                            </Box>


                        </Box>
                        <Box style={{ display: `${this.state.halfViewScreen}`, width: this.state.halfViewScreen === "block" ? "100%" : "", }} className={classes.pdfBox}>

                            <Tabs
                                value={this.state.invoiceValue}
                                onChange={this.handleTabChange}
                                className={classes.pdfInnerBox2}
                                data-testid="tabChangeIdNew"
                                variant="scrollable"
                                scrollButtons="off"
                            >

                                {
                                    (this.props.uploadAcceptedFile.length > 0 ? this.props.uploadAcceptedFile : [{ name: 'Invoice' }]).map((obj: any) => {

                                        return (
                                            <Tab
                                                label={
                                                    <>
                                                        <Box className={classes.pdfcurveBox1}>
                                                            <Box className={classes.pdfcurveInnerBox1}></Box>
                                                        </Box>
                                                        <Typography variant="subtitle2" style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis", maxWidth: "8ch", textTransform: "none"
                                                        }}>{obj.name}</Typography>
                                                        <Box className={classes.pdfcurveRightBox1}>
                                                            <Box className={classes.curveRightPdfInnerBox1}></Box>
                                                        </Box>
                                                    </>
                                                }
                                                {...pdfProps(0)}
                                                title={obj.name}
                                                className={classes.pdfTitleBox1}
                                            />
                                        )
                                    })
                                }

                            </Tabs>


                            <Box className={classes.previewInvoiceOutterBox}>
                                {this.state.toggleValue && this.props.invoiceUrl.length > 0 ? <InvoiceFormView handleResize={this.handleInvoiceResize} fullSize={this.state.halfViewScreen} invoiceColumn={this.state.columns} invoicePage={this.state.invoicePage} handleChangePage={this.handleInvoicePageChange} classes={classes} invoiceData={this.props.invoiceData[this.state.invoiceValue]} multipleSegmentPage={this.state.multipleSegmentPage}  handleMultipleSegmentPage={this.handleMultipleSegmentPage} columnWithTax={configJSON.commonTaxColumns} taxSwitch={taxSwitch1}/> : <>
                                    {this.props.invoiceUrl.length > 0 ?

                                        <>
                                            <Box className={classes.previewInvoiceInnerBox}>         
                                                <iframe src={`${this.props.invoiceURLs[this.state.invoiceValue]} #page=${this.state.pageNumberInvoice} `} className={classes.pdfView}></iframe>
                                            </Box>

                                            <Box className={classes.resizeAndPageWrapper}>
                                                <Box><img src={resize} className={classes.ImgIconStyle} onClick={this.handleInvoiceResize} alt="" height={20} width={20} /></Box>

                                                <Box className={classes.pageBox}>

                                                    <Typography>
                                                        Page {this.state?.pageNumberInvoice} of {this.props?.invoiceData[this.state?.invoiceValue]?.pdf_page_count}
                                                    </Typography>

                                                    <button onClick={this.handlePrevPageInvoicePdf} data-testid={"InvoicePreviousBtnId"} style={{ background: "none" }} >
                                                        {'<'}
                                                    </button>
                                                    <Typography style={boxStyle.pageNumber}>{this.state.pageNumberInvoice}</Typography>
                                                    <button onClick={this.handleNextPageInvoicePdf} data-testid={"InvoiceNextBtnId"} style={{ background: "none" }} >
                                                        {'>'}
                                                    </button>
                                                </Box>
                                            </Box>
                                        </> :
                                        <Box style={{ height: "94vh" }}>
                                            <InvoiceDropbox uploadAcceptedFile={this.props.uploadAcceptedFile} acceptedFile={this.props.acceptedFile} pdfUrl={this.props.pdfUrl} handleDelete={this.props.handleDelete} tabValue={4} classes={classes} onDrop={this.handleFileDrop} />
                                        </Box>
                                    }

                                </>
                                }
                            </Box>

                        </Box>
                    </Box>



                </Card>

                <Box className={classes.resultButtonBox}>
                    <Box className={classes.innerButtonBox}>
                        <Button variant="outlined" data-testid="goBackTestid" style={{ textTransform: "none" }} onClick={this.handleGoback} className={classes.resultGoBackButton}>Go back</Button>
                        <Button variant="outlined" data-testid="deleteReportId" style={{ textTransform: "none" }} onClick={this.handleReportDelete} className={classes.deleteButton}>Delete</Button>

                    </Box>
                    <Button variant="outlined" data-testid="saveReportTestid" style={{ textTransform: "none" }} className={classes.resultContinueButton} onClick={this.NavigateToProjectDashboard}>Save report</Button>

                </Box>

            </>
        );
    }
}


export const pdfProps = (tabIndex: any) => {
    return {
        id: `simple-tab-${tabIndex}`,
        'aria-controls': `simple-tabpanel-${tabIndex}`,
    };
}

export const QuoteFormView = (props: any) => {
    const { classes, quoteData, quotePage, handleChangePage, handleResize, fullSize ,handleEditChangePage} = props
    const { quotedetails, quoteTableData } = quoteData
    const quoteRowsPerPage = 10;
    const begin = (quotePage - 1) * quoteRowsPerPage;
    const end = begin + quoteRowsPerPage;
    const totalData = Math.ceil(quoteTableData.length / quoteRowsPerPage)
    const zoomstyle = {
        display: `${fullSize}`,
        overflow: 'none',
        border: "1px solid gray",
        borderRadius: "4px"
    }


    return (
        <>
            <Box style={zoomstyle}>
                <Box className={classes.quoteInvoiceFormBox}>
                    <Typography variant="subtitle2" className={classes.viewquoteTitle}>View quote</Typography>
                    <Box className={classes.quoteInvoiceDeatilBox}>
                        <Grid container spacing={2}>

                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="body2" className={classes.quoteInvoiceDetailsContent}>{configJSON.quotedetailsContent}</Typography>
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.quoteNumberContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.quote_id} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.quoteDateContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.date ? quotedetails.date : "Unknown"} InputProps={{ readOnly: true, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.expiryquoteTitle}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.expiration_date} InputProps={{ readOnly: true, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" className={classes.quoteInvoiceDetailsContent}>{configJSON.supplierdetailsContent}</Typography>
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.supplierNameContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.vendor} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.supplierAddressContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.supplier_address} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.salesPersonTitle}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.supplier_salesperson} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.supplierContactContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.supplier_contact} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" className={classes.quoteInvoiceDetailsContent}>{configJSON.projectdetailsContent}</Typography>
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.siteNameContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.site_name} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.siteAddressContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.site_address} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.contactPersonTitle}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.site_contactperson} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.siteContactContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={quotedetails.site_contact} InputProps={{ readOnly: true }} disabled />

                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                    <Box className={classes.itemtableBox}>
                        <Typography variant="h6" className={classes.quoteInvoiceDetailsContent}>{configJSON.itemdetails}</Typography>
                        <TableData handleEditChangePage={handleEditChangePage}   handleChangePage={handleChangePage} classes={classes} quotestate={quoteTableData} quoteRowsPerPage={props.quoteRowsPerPage} quotePage={props.quotePage} segmentName={props.segmentName1} subTotalData={props.segmentTotal1} isreportPage={true} />


                    </Box>
                    <Box style={{ width: fullSize == "block" ? "45%" : "" }} className={classes.quoteInvoiceTotalAmountBox}>

                        <Box>
                            <Box className={classes.quoteInvoiceSubTotalContentBox}>
                                <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.subTotal}</Typography>
                                <Typography>$ {quotedetails.gross_subtotal || quotedetails.subtotal}</Typography>
                            </Box>
                            <Box className={classes.quoteInvoiceSubTotalContentBox}>
                                <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.charges}</Typography>
                                <Typography>$ {quotedetails.s_and_h_charges}</Typography>
                            </Box>
                            <Box className={classes.quoteInvoiceSubTotalContentBox}>
                                <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.othercharges}</Typography>
                                <Typography>$ {quotedetails.other_charges}</Typography>
                            </Box>

                            <Box className={classes.quoteInvoiceSubTotalContentBox}>
                                <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.discount}</Typography>
                                <Typography>$ {quotedetails.discount}</Typography>
                            </Box>
                            <Box className={classes.quoteInvoiceTaxContent}>
                                <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.tax}</Typography>
                                <Box className={classes.quoteInvoiceTaxInputBox}>
                                    <Typography>{quotedetails.tax_rate_amount}%</Typography>
                                    <Typography>$ {quotedetails.tax}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.quoteInvoiceSubTotalContentBox}>
                                <Typography className={classes.totalPrice}>{configJSON.total}</Typography>
                                <Typography>$ {quotedetails.total_price}</Typography>
                            </Box>
                        </Box>

                    </Box>

                </Box>
            </Box>
            <Box style={{ margin: "14px 0 7px" }}><img src={resize} onClick={handleResize} className={classes.ImgIconStyle1} alt="" height={20} width={20} /></Box>


        </>
    )
}


export const InvoiceFormView = (props: any) => {
    const { classes, invoiceData, invoiceColumn, handleChangePage, invoicePage, fullSize, handleResize, multipleSegmentPage, handleMultipleSegmentPage,columnWithTax,taxSwitch} = props
    const invoicedetails = invoiceData?.editQuoteDetail ? invoiceData?.editQuoteDetail : invoiceData.invoice.data.attributes
    const invoiceTableData = invoiceData.invoice_description
    const invoiceRowsPerPage = 10
    const begin = (invoicePage - 1) * invoiceRowsPerPage;
    const end = begin + invoiceRowsPerPage;
    const totalData = Math.ceil(invoiceTableData.length / invoiceRowsPerPage)

    const zoomstyle = {
        display: `${fullSize}`,
        overflow: 'none',
        border: "1px solid gray",
        borderRadius: "4px"
    }

    const grossSubtotalInvoiceAmount = invoiceData?.calculateValue ? invoiceData?.calculateValue : invoiceData.invoice.data.attributes.invoice_subtotal;
    const totalInvoiceAmount = invoiceData?.totalValue ? invoiceData?.totalValue : invoiceData?.invoice?.data?.attributes?.total_price;
    let segmentSubtotalValue = invoiceData?.calculateValue 
    ? invoiceData?.tablesData?.[0]?.subtotalValue || invoiceData.invoice.data.attributes.invoice_subtotal
    : invoiceData.invoice.data.attributes.invoice_subtotal;

    

    return (
        <>
            <Box style={zoomstyle}>
                <Box className={classes.quoteInvoiceFormBox}>
                    <Typography variant="subtitle2" className={classes.viewquoteTitle}>View invoice</Typography>
                    <Box className={classes.quoteInvoiceDeatilBox}>
                        <Grid container spacing={2}>

                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="body2" className={classes.quoteInvoiceDetailsContent}>{configJSON.invoiceDetailsContent}</Typography>
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.invoiceNumberContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.quote_id} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.invoiceDateText}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.date} InputProps={{ readOnly: true, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.invoiceExpiaryDateText}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.invoice_shipped_date} InputProps={{ readOnly: true, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.invoiceTerms}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.invoice_terms} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" className={classes.quoteInvoiceDetailsContent}>{configJSON.supplierdetailsContent}</Typography>
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.supplierNameContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.project} InputProps={{ readOnly: true, style: { fontSize: "10px" } }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.supplierAddressContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.invoice_supplier_address} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.salesPersonTitle}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.invoice_salesperson_name} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.supplierContactContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.invoice_supplier_contact} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" className={classes.quoteInvoiceDetailsContent}>{configJSON.projectdetailsContent}</Typography>
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.siteNameContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.invoice_site_name} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.siteAddressContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.invoice_site_address} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.contactPersonTitle}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.invoice_site_person} InputProps={{ readOnly: true }} disabled />
                                    <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.siteContactContent}</Typography>
                                    <TextField variant="outlined" className={classes.reportTextBox}
                                        value={invoicedetails.invoice_site_contact} InputProps={{ readOnly: true }} disabled />

                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                    {!invoiceData?.tablesData && <Box className={classes.itemtableBox}>
                        <Typography variant="h6" className={classes.quoteInvoiceDetailsContent}>{configJSON.itemDetailsText}</Typography>

                        <TableContainer>
                            <Table>

                                <TableHeadSwitch taxSwitch1={taxSwitch} columnWithTax1={columnWithTax} invoiceColumn1={invoiceColumn}/>

                                <TableBody className={classes.reportTableBodyBox}>

                                    {invoiceTableData.slice(begin, end).map((invoiceData: any, index: any) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={invoiceData.id}>

                                                <TableCell>{begin + index + 1}</TableCell>
                                                <TableCell>{invoiceData.description}</TableCell>
                                                <TableCell>{invoiceData.ord_quantity}</TableCell>
                                                <TableCell className={classes.unitContent}>{invoiceData.order_unit}</TableCell>
                                                <TableCell>{invoiceData.price}</TableCell>
                                                <TableCellForTax taxSwitchReq={taxSwitch} taxReq={invoiceData.tax}/>
                                                <TableCell>{invoiceData.amount}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box style={{ marginTop: "35px" }}>
                            <Pagination
                                count={totalData}
                                variant="outlined"
                                shape="rounded"
                                onChange={handleChangePage}
                                page={invoicePage}
                                data-testid="paginationTestId"
                            />
                        </Box>

                        <Box className={classes.reportSubtotalBox}>

                            <Typography>Subtotal</Typography>
                            <Typography style={{ width: fullSize == "block" ? "20%" : "" }} className={classes.quoteInvoiceContentBox}>$ {Number(segmentSubtotalValue).toFixed(2)}</Typography>


                        </Box>



                    </Box>}

                    {invoiceData?.tablesData?.length > 0 && <Typography variant="h6" className={classes.quoteInvoiceDetailsContent}>{configJSON.itemDetailsText}</Typography>}
                    {invoiceData?.tablesData?.length > 0 && invoiceData?.tablesData.map((segment:any,index:any)=>{
                        const begin1 = (multipleSegmentPage - 1) * invoiceRowsPerPage;
                        const end1 = begin1 + invoiceRowsPerPage;
                        const totalDataMultipleSegment = Math.ceil(segment.data?.length / invoiceRowsPerPage)
                        return   <Box className={classes.itemtableBox}  key={`segment-${index}`}>
                            {segment.invoice_category_name && <Box className={classes.segmentTextAndTextfield}>
                            <Typography className={classes.quoteInvoiceDetailTitle}>Segment/Category</Typography>
                            <TextField variant="outlined" className={classes.invoiceTextfieldSegment}
                            value={segment.invoice_category_name} InputProps={{ readOnly: true }} disabled />
                            </Box>}
                            <Box>
                            </Box>
    
                            <TableContainer>
                            <Table>
                            
                            <TableHeadSwitch taxSwitch1={taxSwitch} columnWithTax1={columnWithTax} invoiceColumn1={invoiceColumn}/>


                            <TableBody className={classes.reportTableBodyBox}>
                                {segment.data?.slice(begin1,end1).map((data:any,index:number)=>{
                                  return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>

                                        <TableCell>{begin1 + index + 1}</TableCell>
                                        <TableCell>{data.description}</TableCell>
                                        <TableCell>{data.ord_quantity}</TableCell>
                                        <TableCell className={classes.unitContent}>{data.order_unit}</TableCell>
                                        <TableCell>{data.price}</TableCell>
                                        <TableCellForTax taxSwitchReq={taxSwitch} taxReq={data.tax}/>
                                        <TableCell>{data.amount}</TableCell>
                                    </TableRow>
                                );
                                })}

                            </TableBody>
                            </Table>
                            </TableContainer>

                            <Box style={{ marginTop: "35px" }}>
                            <Pagination
                                count={totalDataMultipleSegment}
                                variant="outlined"
                                shape="rounded"
                                onChange={handleMultipleSegmentPage}
                                page={multipleSegmentPage}
                                data-testid="paginationTestId"
                            />
                            </Box>
                            <Box className={classes.reportSubtotalBox}>

                            <Typography>Subtotal</Typography>
                            <Typography style={{ width: fullSize == "block" ? "20%" : "" }} className={classes.quoteInvoiceContentBox}>$ {Number(segment.subtotalValue).toFixed(2)}</Typography>
                            </Box>
                            </Box>
                    })}


                    <Box style={{ width: fullSize == "block" ? "45%" : "" }} className={classes.quoteInvoiceTotalAmountBox}>

                        <Box>
                            <Box className={classes.quoteInvoiceSubTotalContentBox}>
                                <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.subTotal}</Typography>
                                <Typography>$ {Number(grossSubtotalInvoiceAmount).toFixed(2)}</Typography>                       
                            </Box>
                            <Box className={classes.quoteInvoiceSubTotalContentBox}>
                                <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.charges}</Typography>
                                <Typography>$ {invoicedetails.invoice_s_and_h_charges}</Typography>
                            </Box>
                            <Box className={classes.quoteInvoiceSubTotalContentBox}>
                                <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.othercharges}</Typography>
                                <Typography>$ {invoicedetails.invoice_other_charges}</Typography>
                            </Box>

                            <Box className={classes.quoteInvoiceSubTotalContentBox}>
                                <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.discount}</Typography>
                                <Typography>$ {invoicedetails.discount}</Typography>
                            </Box>
                            <Box className={classes.quoteInvoiceTaxContent}>
                                <Typography className={classes.quoteInvoiceDetailTitle}>{configJSON.tax}</Typography>
                                <Box className={classes.quoteInvoiceTaxInputBox}>
                                    <Typography>{invoicedetails.invoice_tax_percent}%</Typography>
                                    <Typography>$ {invoicedetails.invoice_tax}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.quoteInvoiceSubTotalContentBox}>
                                <Typography className={classes.totalPrice}>{configJSON.total}</Typography>
                                <Typography>$ {Number(totalInvoiceAmount).toFixed(2)}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box style={{ margin: "14px 0 7px" }}><img src={resize} onClick={handleResize} className={classes.ImgIconStyle1} alt="" height={20} width={20} /></Box>


        </>
    )
}

export const ReportBreadCrumb = (props: any) => {
    const { classes } = props
    return (<>
        <Box className={classes.breadcrumbStyle}>
            <Breadcrumbs
                separator={">"}
                aria-label="breadcrumb"

            >
                <Link color="inherit" className={classes.breadcrumbLinkStyle} href="/">
                    {configJSON.dashboardText}
                </Link>

                <Typography className={classes.breadcrumbText}>{configJSON.createNewReportText}</Typography>
            </Breadcrumbs>
        </Box>
    </>)

}













export const webStyle: any = (theme: any) => ({








});

export const boxStyle = {
    buttonStyle1: {
        background: "white",
        color: "black",
        height: "42px",
        marginTop: "15px",
        marginRight: "15px",
        border: "1px solid black",
        borderRadius: "4px"
    },

    pageNumber: {
        margin: "0px 2px",
        background: "#e8e2e2",
        padding: "2px 10px",
        borderRadius: "3px"
    }
}



export default withStyles(webStyle)(ReportResults)

// Customizable Area End
