// Customizable Area Start
import React, { ReactNode } from "react";
import {
  Grid, Box, Typography, AppBar, Tabs, Tab, Card, CardContent, Select,
  OutlinedInput,
  MenuItem,
  TextField,
  InputLabel,
  DialogTitle,
  Dialog,
  DialogContent,
  Button,
  TableBody,
  CircularProgress
} from "@material-ui/core";
import {
  quoteUploadIcon,
  create_new_invoice,
  create_new_project,
  no_data,
  upload_invoice,
  vendorsNext
} from "./assets";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Header from "./Header.web";
import { NavLink } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DashboardController, { configJSON } from "./DashboardController.web";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { styled } from "@material-ui/styles";
import Chart from "react-apexcharts";
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { StyledTableRowItems } from "./DashboardNewTable.web";
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { TableFooter } from "../../categoriessubcategories/src/Projects.web";
import SearchIcon from '@material-ui/icons/Search';


export const DashboardNavCard = (props: any) => {
  const classes = useStyles();
  return (
    <>
      <div style={webStyle.actionCardStyle} data-test-id={props?.datatestid} onClick={props.handleOnClick}>
        <div
          className={`${classes.iconImgWrapper}`}
          style={props.imageStyle}
        >
          <img src={props.img} className={classes.iconImg} alt="" />
        </div>
        <div className={classes.actionTxt} style={props.textStyle}>
          {props.actionCardText}
        </div>
      </div>
    </>
  );
};

const DashboardPanelBlock = styled(Box)({
  "& .MuiPaper-root.MuiAppBar-root": {
    marginBottom: "15px",
    backgroundColor: "transparent",
    boxShadow: "none",
    color: '#111'
  },
  "& .MuiPaper-root.MuiAppBar-root button.MuiButtonBase-root": {
    minWidth: "23%",
    marginRight: "4px",
    background: '#fff',
    border: '1px solid rgb(223, 219, 219)',
    borderRadius: "10%",
    color: "#111",
    "& .MuiPaper-root.MuiAppBar-root .MuiButtonBase-root.Mui-selected": {
      backgroundColor: "rgb(223, 128, 45)",
      color: "#fff !important"
    },
    "&:hover": {
      backgroundColor: "rgb(223, 128, 45)",
      color: "#fff"
    }
  },
  "& .MuiPaper-root.MuiAppBar-root .MuiTabs-indicator": {
    display: "none"
  },
  "& .MuiBox-root-16": {
    padding: "12px 24px"
  },
  "& .MuiTab-root": {
    padding: '8px',
    minHeight: 'auto'
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: 'space-between',
    width: '100%'
  },
  '& .MuiPaper-root.MuiAppBar-root .MuiButtonBase-root.Mui-selected': {
    backgroundColor: 'rgb(223, 128, 45)',
    color: '#fff !important',
  },
  "& .tabFirst > .MuiBox-root": {
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px"
  }
});

export const DashboardTabTitle = ({ title }: any) => {
  return (
    <Typography style={{ textTransform: 'initial', fontWeight: 500 }}>{title}</Typography>
  );
};

const StyledCancelButton = styled(Button)({
  "&.MuiButton-root": {
    borderRadius: "4px",
    border: "1px solid #292946",
    color: "#292946",
    width: '50%',
    textTransform: 'capitalize',
    height: "42px"
  }
})

const StyledSaveButton = styled(Button)({
  "&.MuiButton-root": {
    borderRadius: "4px",
    color: "#fff",
    background: "#292946",
    width: '50%',
    textTransform: 'capitalize',
    height: "42px"
  }
})

export const AddProjectNameDialog = (props: any) => {
  const { title, type, handleClose, handleOpen, handleInputChange, inputName, addVendor, addProject, addNameErrMsg } = props
  const classes = useStyles()

  return (
    <>
      <AddProjectDialog data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
        <DialogTitle className="createReportDahsboardTitleStyle">{title}</DialogTitle>
        <DialogContent>
          <Box className="vendorTypeDashboardContainer">
            <InputLabel className="venderNameDashboardLabel">{`${type} name`}</InputLabel>
            <TextField autoComplete="off" fullWidth error={addNameErrMsg != ""} helperText={addNameErrMsg} value={inputName} onChange={handleInputChange} className={classes.helperText} id="outlined-basic" variant="outlined" placeholder={`Enter ${type} name`} />
          </Box>
          <Box className={classes.buttonBox}>
            <StyledCancelButton variant='outlined' onClick={handleClose}>Cancel</StyledCancelButton>
            <StyledSaveButton variant='contained' onClick={type === 'vendor' ? addVendor : addProject}>Save</StyledSaveButton>
          </Box>
        </DialogContent>
      </AddProjectDialog>
    </>
  )
}

const StyledOutlinedAddButton = styled(Button)({
  "&.MuiButton-root": {
    borderRadius: "4px",
    border: "1px solid #292946",
    color: "#292946",
    textTransform: 'capitalize',
    width: '110px',
    padding: '10px 10px'
  }
})


export const ReportDataCards = (props: any) => {
  const { projectData, type } = props
  return (
    <>
      <Card style={webStyle.cardOneStyle}>
        <CardContent>
          <Typography style={webStyle.titleFontStyle}>{configJSON.totalLiveProjects}</Typography>
          <Box style={webStyle.cardBoxStyle}>
            <Typography style={webStyle.boldFontStyle}>{projectData?.total_live_projects?.value}</Typography>
            {(projectData?.total_live_projects?.percentage_change !== null && projectData?.total_live_projects?.percentage_change !== 0) &&
              <>
                <Typography style={projectData?.total_live_projects?.status === "lower" ? webStyle.negativePercentStyle : webStyle.percentStyle}>
                  {projectData?.total_live_projects?.status === "lower" ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />} {`${Math.abs(projectData?.total_live_projects?.percentage_change.toFixed(1))}%`}</Typography>
                <Typography style={{ fontSize: '14px', color: 'gray', fontWeight: 500 }}>{`Compared to last ${type}`}</Typography>
              </>
            }
          </Box>
        </CardContent>
      </Card>
      <Card style={webStyle.cardTwoStyle}>
        <CardContent>
          <Typography style={webStyle.titleFontStyle}>{configJSON.totalReportGenerated}</Typography>
          <Box style={webStyle.cardBoxStyle}>
            <Typography style={webStyle.boldFontStyle}>{projectData?.total_reports?.value}</Typography>
            {(projectData?.total_reports?.percentage_change !== null && projectData?.total_reports?.percentage_change !== 0) &&
              <>
                <Typography style={projectData?.total_reports?.status === "lower" ? webStyle.negativePercentStyle : webStyle.percentStyle}>{projectData?.total_reports?.status === "lower" ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />} {`${Math.abs(projectData?.total_reports?.percentage_change.toFixed(1))}%`}</Typography>
                <Typography style={{ fontSize: '14px', color: 'gray', fontWeight: 500 }}>{`Compared to last ${type}`}</Typography>
              </>
            }
          </Box>
        </CardContent>
      </Card>
      <Card style={webStyle.cardTwoStyle}>
        <CardContent>
          <Typography style={webStyle.titleFontStyle}>{configJSON.reportGeneratedWithoutInvoice}</Typography>
          <Box style={webStyle.cardBoxStyle}>
            <Typography style={webStyle.boldFontStyle}>{projectData?.total_reports_without_invoice?.value}</Typography>
            {(projectData?.total_reports_without_invoice?.percentage_change !== null && projectData?.total_reports_without_invoice?.percentage_change !== 0) &&
              <>
                <Typography style={projectData?.total_reports_without_invoice?.status === "lower" ? webStyle.negativePercentStyle : webStyle.percentStyle}>{projectData?.total_reports_without_invoice?.status === "lower" ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />} {`${Math.abs(projectData?.total_reports_without_invoice?.percentage_change.toFixed(1))}%`}</Typography>
                <Typography style={{ fontSize: '14px', color: 'gray', fontWeight: 500 }}>{`Compared to last ${type}`}</Typography>
              </>
            }
          </Box>
        </CardContent>
      </Card>
    </>
  )
}


export default class Dashboard extends DashboardController {

  uploadInvoice = () => {
    return (

      <Dialog open={this.state.openModel} onClose={this.handleClosePopup} PaperProps={{ style: { borderRadius: "16px", width: '30%', overflow: 'inherit' } }}>

        <DialogTitle><Typography style={webStyle.popupHeading}>{configJSON.uploadInvoice}</Typography></DialogTitle>

        <DialogContent>
          <Typography style={webStyle.popupProject}>{configJSON.popupProject} </Typography>
          <Box data-test-id='projectSelect' onClick={ this.projectSortedData } style={{
            ...webStyle.popupProjectPlaceholder,
            border: `1px solid ${!this.state.isProjectValueEmpty ? '#000000' : '#FF0000'}`,
          }}>{this.state.projectValue.length > 0 ? this.state.projectValue : 'Select project'}   <ExpandMoreIcon /></Box>

          {this.state.ProjectDataFlag &&
            <Box style={webStyle.projectList}>
              <TextField variant="outlined" placeholder="Search in projects..." data-testId='projectSearch' onChange={this.handleSearch} value={this.state.searchQueryProject} fullWidth InputProps={{ startAdornment: (<SearchIcon style={{ marginRight: 8 }} />) }} />
              {this.state.filteredDataProject.map((item: any, index: any) => (
                <div key={index}
                  style={{ ...webStyle.projectAndReportOptions, borderBottom: index === this.state.filteredDataProject.length - 1 ? "none" : "1px solid #8080803b",cursor:"pointer" }}
                   data-testId='projectOption' onClick={() => this.setState({ projectValue: item.value, ProjectDataFlag: false, projectId: item.id })}
                >
                  {item.value}
                </div>
              ))}
            </Box>
          }
        </DialogContent>

        <DialogContent>
          <Typography style={webStyle.reportHeading}>{configJSON.popupReport}</Typography>
          <Box data-testId='reportSelect' onClick={ this.reportSortedData } style={{
            ...webStyle.popupReportPlaceholder,
            border: `1px solid ${!this.state.isReportValueEmpty ? '#000000' : '#FF0000'}`
          }}>{this.state.ReportValue.length > 0 ? this.state.ReportValue : 'Select report'} <ExpandMoreIcon /></Box>

          {this.state.ReportDataFlag &&
            <Box style={webStyle.reportList}>
              <TextField variant="outlined" data-testId='reportSearch' placeholder="Search in reports..." onChange={this.handleReport} value={this.state.searchQueryReport} fullWidth InputProps={{ startAdornment: (<SearchIcon style={{ marginRight: 8 }} />) }} />
              {this.state.filteredDataReport.map((item: any, index: any) => (
                <div key={index}
                  style={{ ...webStyle.projectAndReportOptions, borderBottom: index === this.state.filteredDataReport.length - 1 ? "none" : "1px solid #8080803b",cursor:"pointer" }}
                   data-testId='reportOption'
                  onClick={() => this.setState({ ReportValue: item.value, ReportDataFlag: false })}
                >
                  {item.value}
                </div>
              ))}
            </Box>
          }
        </DialogContent>
        <Grid style={webStyle.popupBothButton}>
          <Button style={webStyle.popupCancleButton} variant="outlined" data-testId = "closeButton" onClick={this.handleClosePopup} > {configJSON.popupCancleButton} </Button>
          <NavLink style={webStyle.buttonLink} to={{ pathname: "/report/create-report", state: { projectValue: this.state.projectId, reportValue: this.state.ReportValue, value: 2,continueBtnClicked:true } }} onClick={(e) => this.emptyValue(e)}>
            <Button variant="outlined" style={webStyle.popupCountinueButton}>{configJSON.popupCountinueButton} </Button>
          </NavLink>
        </Grid>
      </Dialog>

    )
  }
  render(): ReactNode {
    return (
      <>
        <AddProjectNameDialog addNameErrMsg={this.state.addNameErrMsg} addProject={this.handleAddProject} inputName={this.state.inputName} handleInputChange={this.handleInputChange} title={`Add a new project`} type="project" handleClose={this.handleClose} handleOpen={this.state.openDialog} />
        <Header
          data-testid="headerId"
          handleSaveData={this.handleSaveData}
          navigation={this.props.navigation}
          history={this.props.history}
        />
        <Grid container direction="row" spacing={6} style={webStyle.fontColor}>
          <Grid item xs={12} md={4} lg={4}>
            <DashboardNavCard
              className="a"
              data-testid="toggleDataId"
              img={create_new_project}
              imageStyle={webStyle.uploadImage}
              textStyle={webStyle.compareTextStyle}
              handleOnClick={this.handleOpenDialog}
              actionCardText={configJSON.addNewProject}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
              <NavLink activeStyle={{ textDecoration: 'none' }} style={webStyle.linkStyle} to="/report/create-report">
                <DashboardNavCard
                  className="a"
                  img={create_new_invoice}
                  imageStyle={webStyle.generateImgStyle}
                  textStyle={webStyle.generateTextStyle}
                  actionCardText={configJSON.createNewReport}
                />
              </NavLink>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <DashboardNavCard
              datatestid='openModal'
              className="a"
              img={upload_invoice}
              imageStyle={webStyle.uploadImage}
              textStyle={webStyle.compareTextStyle}
              handleOnClick={() => this.handleOpenPopup()}
              actionCardText={configJSON.uploadInvoice}
            />

            {this.state.openModel && (
              this.uploadInvoice()
            )}

          </Grid>
          {this.state.openVendorChart ? 
          <Grid item xs={12} md={7} lg={7}>
            <Box style={webStyle.firstGridStyle}>
              <Box style={webStyle.totalDiscrepancyBoxStyle}>
                <Box style={this.state.isNoDataAvailable ? webStyle.noDataBoxStyle : webStyle.dataBoxStyle}>
                  <Typography style={webStyle.titleFontStyle}>{configJSON.totalDiscrepancy}</Typography>
                  {this.state.isChartDataLoading ? null : this.state.isNoDataAvailable ?
                    <Box style={webStyle.firstBoxStyle}>
                      <img src={no_data} width={300} height="auto" />
                      <Typography style={webStyle.noDataTextBoldStyle}>{configJSON.noDataAvailable}</Typography>
                      <Typography style={webStyle.noDataTextStyle}>
                        {configJSON.createReportAndQuote}
                      </Typography>
                    </Box>
                    :
                    <Chart
                      options={{
                        colors: ["#f7728a", "#ffcb00"],
                        labels: ["Overcharged", "Undercharged"],
                        legend: {
                          show: false,
                          showForSingleSeries: false,
                          showForNullSeries: true,
                          showForZeroSeries: true,
                          position: "right",
                          horizontalAlign: "center",
                          floating: false,
                          fontSize: "14px",
                          fontFamily: "Helvetica, Arial",
                          width: undefined,
                          height: undefined,
                          formatter: undefined,
                          offsetX: 0,
                          offsetY: 50,
                          labels: {
                            colors: undefined,
                            useSeriesColors: false,
                          },
                          markers: {
                            strokeWidth: 0,
                            customHTML: undefined,
                            onClick: undefined,
                            offsetX: 0,
                            offsetY: 0,
                          },
                          itemMargin: {
                            horizontal: 20,
                            vertical: 5,
                          },
                          onItemClick: {
                            toggleDataSeries: true,
                          },
                          onItemHover: {
                            highlightDataSeries: true,
                          },
                        },
                        plotOptions: {
                          radialBar: {
                            inverseOrder: false,
                            startAngle: 0,
                            endAngle: 360,
                            offsetX: -50,
                            offsetY: 50,
                            hollow: {
                              margin: 5,
                              size: "50%",
                              background: "transparent",
                              image: undefined,
                              imageWidth: 150,
                              imageHeight: 150,
                              imageOffsetX: 0,
                              imageOffsetY: 0,
                              imageClipped: true,
                              position: "front",
                              dropShadow: {
                                enabled: false,
                                top: 0,
                                left: 0,
                                blur: 3,
                                opacity: 0.5,
                              },
                            },
                            track: {
                              show: true,
                              startAngle: undefined,
                              endAngle: undefined,
                              background: ["#16164d", "#bbb2b2"],
                              strokeWidth: "97%",
                              opacity: 1,
                              margin: 5,
                              dropShadow: {
                                enabled: false,
                                top: 0,
                                left: 0,
                                blur: 3,
                                opacity: 0.5,
                              },
                            },
                            dataLabels: {
                              show: true,
                              name: {
                                show: true,
                                fontSize: "22px",
                                fontFamily: undefined,
                                color: undefined,
                                offsetY: 0,
                              },
                              value: {
                                show: true,
                                fontSize: "18px",
                                fontFamily: undefined,
                                color: "#000",
                                offsetY: 16,
                              },
                              total: {
                                show: true,
                                label: "Total Discrepancy",
                                color: "#000",
                                formatter: this.formatterFn,
                              },
                            },
                          },
                        },
                      }}
                      series={this.state.series}
                      type="radialBar"
                      width="400"
                    />
                  }
                </Box>
                {!this.state.isNoDataAvailable &&
                  <Box style={{ width: "50%", display: 'flex', flexDirection: 'column', alignItems: 'flex-end'  }}>
                    <div style={webStyle.TotaldiscrepancyBox}>
                    <Typography style={webStyle.discrepancyboxTitle}>Compare discrepancy by vendors</Typography>
                    <div><img src={vendorsNext} data-testid="vendornext" onClick={this.handleBlankBox}  /></div>
                    </div>
                  <Box>
                    <Box>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Select Vendor
                      </InputLabel>
                      <Select
                        style={{ minWidth: "100%", marginTop: '4%', maxWidth: '185px', borderRadius: '10px', height: '40px' }}
                        labelId="demo-simple-select-disabled-label"
                        id="demo-simple-select-disabled"
                        IconComponent={ExpandMoreIcon}
                        defaultValue={0}
                        input={<OutlinedInput />}
                        displayEmpty
                        placeholder="All"
                        value={this.state.vendorValue}
                        onChange={this.handleVendorChange}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                        data-testid={"projectTestID"}
                      >
                        <MenuItem style={{ background: 'white', color: 'gray' }} value={0}>
                          <em style={{ background: 'white', color: 'gray', fontStyle: 'initial' }}>All</em>
                        </MenuItem>
                        {this.state.vendorItems?.map((item: any, index: any) => {
                          return (
                            <MenuItem key={index} style={{ background: 'white', color: 'black', maxWidth: '180px' }} data-testid="menuQuotesPlaceholderId" value={item.id}>
                              <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</Typography>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </Box>
                    <Box style={webStyle.cardMainBoxStyle}>
                    <Card style={webStyle.cardStyle}>
                        <CardContent>
                          <Box style={webStyle.contentStyle}>
                            <StopRoundedIcon />
                            <Box>
                              <Typography style={webStyle.cardTextStyle}>{configJSON.totalQuoteAmount}</Typography>
                              <Typography style={webStyle.cardTextStyle}>$ {this.state.vendorChangeReportData?.total_quotes_amount}</Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card style={webStyle.cardStyle}>
                        <CardContent>
                          <Box style={webStyle.contentStyle}>
                            <StopRoundedIcon style={{ color: 'gray' }} />
                            <Box>
                              <Typography style={webStyle.cardTextStyle}>{configJSON.totalInvoiceAmount}</Typography>
                              <Typography style={webStyle.cardTextStyle}>$ {this.state.vendorChangeReportData?.total_invoice_amount}</Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                    </Box>
                    
                  </Box>
                }
              </Box>
              {this.state.isChartDataLoading ? <Box style={webStyle.circularLoader}><CircularProgress /></Box> : null}
            </Box>
          </Grid>:
          <Grid item xs={12} md={7} lg={7}>
            <Box style={webStyle.firstGridStyle}>
              <Box style={webStyle.DiscrepancyVendorsTittleDiv}>
                <div>
                  <Typography style={webStyle.titleFontStyle}>Discrepancy by vendors</Typography>
                </div>
                  <div style={webStyle.TotaldiscrepancyBox}>
                    <Typography style={webStyle.discrepancyboxTitle}>Total discrepancy</Typography>
                    <div><img src={vendorsNext} onClick={this.handleBlankBox}  /></div>
                  </div>
              </Box>
              {this.state.isChartDataLoading && <Box style={webStyle.circularLoader}><CircularProgress /></Box>}
              <Box>
              {!this.state.isChartDataLoading &&
              <div style={{height:'400px',overflow:'auto'}}>
              <Chart data-testid="chart-component" options={this.state.optionsbar} series={this.state.seriesbar} height={this.state.chartHeight} type="bar" />
               </div>
              }
              </Box>
            </Box>
          </Grid>}
          <Grid item xs={12} md={5} lg={5}>
            <Box>
              <DashboardPanelBlock>
                <AppBar position="static" color="default">
                  <Tabs
                    data-testid="TabsId"
                    value={this.state.tabValue}
                    onChange={this.handleValueChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="off"
                    style={{
                      fontSize: "18px", fontWeight: 550
                    }}
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab value={0} label={<DashboardTabTitle title="This week" />} />
                    <Tab value={1} label={<DashboardTabTitle title="This month" />} />
                    <Tab value={2} label={<DashboardTabTitle title="This year" />} />
                    <Tab value={3} label={<DashboardTabTitle title="All time" />} />
                  </Tabs>
                </AppBar>
                <ReportDataCards projectData={this.state.projectData} type={this.state.type} />
              </DashboardPanelBlock>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography style={webStyle.titleFontStyle}>{configJSON.reportWithoutInvoice}</Typography>
              <TableContainer style={webStyle.tableContainerStyle}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {this.state.columns.map((column: any, index: any) => (
                        <TableCell
                          key={index}
                          style={webStyle.tableCellStyle}
                          align="center"
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody data-testid="tableBodyId">
                    {this.state.isLoading ?
                      <CircularProgress style={{ position: 'absolute', left: '55%', marginTop: '2%' }} />
                      : <>
                        {this.state.reportWithoutInvoiceData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                          .map((item: any, index: any) => {
                            return (
                              <StyledTableRowItems
                                hover
                                // data-testid={`rowItemClickId${index}`}
                                // onClick={(event: any) => handleRowItemClick(event, row)}
                                role="checkbox"
                                tabIndex={-1}
                              // key={index}
                              >
                                <TableCell
                                  scope="row"
                                  align="center"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell style={{ maxWidth: '200px', textOverflow: 'ellipsis', overflow: 'hidden' }} scope="row" align="center">{item.report_id}</TableCell>
                                <TableCell align="center">{moment(item.created_date).format('MM-DD-YYYY, hh:mm A')}</TableCell>
                                <TableCell align="center">{item.vendor_name}</TableCell>
                                <TableCell align="center">
                                  {item.project_name}
                                </TableCell>
                                <TableCell align="center">
                                  <CheckIcon style={{ color: 'green' }} />
                                </TableCell>
                                <TableCell align="center"> <ClearIcon style={{ color: 'red' }} /></TableCell>
                                <TableCell align="center">
                                  <NavLink activeStyle={{ textDecoration: 'none' }} style={webStyle.navLinkStyle} to={{ pathname: "/report/create-report", state: { projectValue: item.project_id, reportValue: item.project_name, value: 2, vendorValue: item.vendor_id,report_ID: item.report_id,reportsName: item.name_report } }}>
                                    <StyledOutlinedAddButton data-testid="cancelInvoiceButtonId" variant='outlined'>Add Invoice</StyledOutlinedAddButton>
                                  </NavLink>
                                </TableCell>
                              </StyledTableRowItems>
                            )
                          })}
                      </>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TableFooter reportProjects={this.state.reportWithoutInvoiceData.length} page={this.state.page} handleClickChangePage={this.handleClickChangePage} rowsPerPage={this.state.rowsPerPage} handleChangePerPage={this.handleChangePerPage} isLoading={this.state.isLoading} />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    iconImgWrapper: {
      borderRadius: "13px",
      marginLeft: "20px"
    },
    iconImg: {

    },
    actionTxt: {
      fontFamily: "Poppins",
      fontSize: "22px",
      fontWeight: 700,
      width: "100%",
      textAlign: "center"
    },
    helperText: {
      marginTop: '1%',
      '& p': {
        '&.MuiFormHelperText-contained': {
          marginLeft: '0px'
        }
      },
    },
    buttonBox: {
      marginTop: '13%', gap: '20px', width: '100%', display: 'flex', justifyContent: 'space-between', paddingBottom: '5%'
    },
  })
);


const webStyle = {
  DiscrepancyVendorsTittleDiv:{display:'flex',alignItems: 'baseline',justifyContent: 'space-between'},
  TotaldiscrepancyBox:{display:'flex',alignItems:'center',marginBottom:'30px'},
  discrepancyboxTitle:{
    marginRight:'22px',
    color:'#05052B',
    fontSize:'16px',
    fontWeight:400,
    pointer:'cursor'
  },
  fontColor: {
    color: 'black'
  },
  navLinkStyle: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none'
    }
  },
  boldFontStyle: {
    fontSize: '20px',
    fontWeight: 550
  },
  titleFontStyle: {
    fontSize: '20px',
    fontWeight: 500
  },
  tableCellStyle: {
    fontSize: '18px',
    fontWeight: 550
  },
  noDataBoxStyle: {
    width: '100%',
  },
  dataBoxStyle: {
    width: '50%'
  },
  noTableDataStyle: {
    margin: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '5%',
    fontSize: '18px',
    fontWeight: 550
  },
  firstGridStyle: {
    width: '100%', padding: '3%', background: '#fff', borderRadius: '2%', height: '100%', border: '1px solid rgb(223, 219, 219)'
  },
  totalDiscrepancyBoxStyle: {
    display: 'flex', justifyContent: 'space-between'
  },
  firstBoxStyle: { textAlign: 'center' as 'center', marginTop: '3%' },
  cardTextStyle: {
    fontSize: '18px',
    fontWeight: 550
  },
  percentStyle: {
    color: '#008000d4',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
  },
  negativePercentStyle: {
    color: '#ff0000b0',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
  },
  noDataTextStyle: {
    marginTop: '3%'
  },
  contentStyle: {
    display: 'flex'
  },
  noDataTextBoldStyle: {
    marginTop: '3%',
    fontSize: '18px',
    fontWeight: 550
  },
  cardBoxStyle: {
    display: 'flex', gap: '10px', paddingTop: '2%', alignItems: 'center'
  },
  tableContainerStyle: {
    marginTop: '2%'
  },
  cardMainBoxStyle: {
    display: 'flex', flexDirection: 'column' as 'column', gap: '30px', marginTop: '22%'
  },
  cardStyle: {
    minWidth: '50%', borderRadius: '10px'
  },
  cardOneStyle: {
    padding: '1%',
    borderRadius: '10px'
  },
  cardTwoStyle: {
    marginTop: '5%', padding: '1%',
    borderRadius: '10px'
  },
  actionCardStyle: {
    height: "100px",
    borderRadius: "16px",
    backgroundColor: "#ffffff",
    border: "0px solid #39b68e",
    width: "100%",
    display: "flex",
    alignItems: "center",
    cursor: 'pointer'
  },
  uploadImage: {
    opacity: "0.655",
    backgroundImage:
      "linear-gradient(180deg, rgba(198,255,230,0.55) 0%, #96e0c8 100%)"
  },
  compareTextStyle: {
    color: " #4670b6"
  },
  generateImgStyle: {
    backgroundImage:
      "linear-gradient(180deg, #fefadb 0%, #ffeba8 78%, #ffe79a 100%)"
  },
  generateTextStyle: {
    color: " #df802d"
  },
  linkStyle: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none'
    }
  },
  circularLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  popupHeading: {
    textAlign: "center" as 'center',
    padding: "10px 0",
    color: "#141423",
    fontWeight: 600,
    fontSize: '21px'
  },
  popupProject: {
    color: "#141423",
    fontSize: "19px",
    fontWeight: 400
  },
  popupProjectPlaceholder: {
    position: 'relative' as 'relative',
    borderRadius: '5px',
    padding: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    fontSize: '17px',
    color: '#424040',
    cursor: "pointer",
  },
  projectList: {
    position: 'absolute' as 'absolute',
    zIndex: 1,
    background: 'white',
    border: '1px solid gray',
    width: '89%',
    padding: '12px',
    borderRadius: '5px',
    overflowY: 'scroll' as "scroll",
    height: "40%",
  },
  projectAndReportOptions: {
    fontSize: "18px",
    padding: "12px 0px 12px 6px",
    cursor: "pointer"
  },
  reportHeading: {
    color: "#141423",
    fontSize: "19px",
    fontWeight: 400,
    marginTop: '10px',
  },
  popupReportPlaceholder: {
    position: 'relative' as 'relative',
    borderRadius: '5px',
    padding: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    fontSize: '17px',
    color: '#424040',
    cursor: "pointer"
  },
  reportList: {
    position: 'absolute' as 'absolute',
    zIndex: 1,
    background: 'white',
    border: '1px solid gray',
    width: '89%',
    padding: '12px',
    borderRadius: '5px',
    overflowY: 'scroll' as "scroll",
    height: "40%",
    '@media (max-width: 1500px)': {
      width: '89%'
    }
  },
  popupBothButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0px 30px 0px",
    gap: "15px",
    marginTop: '15px',
  },
  popupCancleButton: {
    height: "50px",
    width: "30%",
    border: "1px solid #05052b",
    textTransform: "none" as "none",
    fontSize: '16px'
  },
  buttonLink: {
    width: "30%"
  },
  popupCountinueButton: {
    height: "50px",
    width: "100%",
    backgroundColor: "#05052b",
    color: "#ffff",
    textTransform: "none" as "none",
    fontSize: '16px'
  }
};

const AddProjectDialog = styled(Dialog)({
  backdropFilter: "blur(8px)",
  "& .MuiDialog-paper": {
    width: "100%",
    maxWidth: "392px",
    borderRadius: 10,
    padding: "15px 33px"
  },
  "& .MuiDialogContent-root": {
    padding: 0
  },
  "& .createReportDahsboardTitleStyle": {
    fontWeight: 550,
    textAlign: 'center'
  },
  "& .venderNameDashboardLabel": {
    color: 'black',
    fontSize: '16px',
    textTransform: 'capitalize',
    marginBottom: 5
  },
  "& .vendorTypeDashboardContainer": {
    width: '100%',
    marginTop: "10px",
    marginLeft: "0px"
  },
});

// Customizable Area End