Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.projectsAPiMethod = "GET";
exports.projectsAPiEndPoint = "bx_block_projects/projects";
exports.reportsAPiEndPoint = "bx_block_reports/reports";
exports.archivesAPiEndPoint = "bx_block_archives/archives";
exports.allDocumentsApiEndPoint = "bx_block_filter_items/invoice_libraries/library_document_list";
exports.rejectDocumentApiEndPoint = "bx_block_filter_items/invoice_libraries/library_document_list_rejected";
exports.toBePaidDocumentApiEndPoint = "bx_block_filter_items/invoice_libraries/library_document_list_paid";
exports.allDocumentSearchApiEndPoint = "bx_block_filter_items/search/search_for_approval";
exports.rejectSearchApiEndPoint = "bx_block_filter_items/search/search_for_approval_rejected";
exports.toBePaidSearchApiEndPoint = "bx_block_filter_items/search/search_for_approval_paid";
exports.vendorApiEndPoint = "bx_block_filter_items/search/vendors_approval";
exports.projectApiEndPoint = "bx_block_filter_items/search/projects_approval";
exports.deleteApiEndPoint = "bx_block_reports/reports";
exports.addNewItemAPI = "bx_block_reports/reports/add_new_item";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.projectsApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MatchAlgorithm";
exports.labelBodyText = "MatchAlgorithm Body";
exports.filterItemButton = "Filter Items";
exports.placeholdervendorTitle = "Choose Vendor";
exports.placeholderprojectTitle = "Choose Project";
exports.vendorTitle = "Vendor";
exports.projectTitle = "Project";
exports.dateRangeTitle = "Date Range";
exports.cancelButton = "Cancel";
exports.applyButton = "Apply";

exports.ItemDetailsTitle = "Item details";

exports.dayTitle = "A day";
exports.weekTitle = "A week";
exports.monthTitle = "A month";
exports.NewSegment = "Add New Segment";
exports.subTotalText="Subtotal";
exports.subTotalOrGrossAmountText = "Subtotal/Gross Amount";
exports.sAndHChargesText = "S&H Charges";
exports.otherChargesText ="Other Charges";
exports.discountText = "Discount";
exports.taxText = "Tax";
exports.totalText = "Total";
exports.invoiceCancel = "Cancel";
exports.invoiceSave = "Save";
exports.editQuote = "Edit quote";
exports.goBack = "Go Back";
exports.addNewItem = "Add New Item";
exports.itemWiseTax = "This Quote has item-wise tax";
exports.quotedetailsContent = "Quote details";
exports.quoteNumberContent = "Quote Number";
exports.quoteDateContent = "Quote Date";
exports.expiryquoteTitle = "Quote Expiry Date";
exports.supplierdetailsContent = "Supplier details";
exports.supplierNameContent = "Supplier Name";
exports.supplierAddressContent = "Supplier Address";
exports.salesPersonTitle = "Sales Person";
exports.supplierContactContent = "Supplier Contact";
exports.projectdetailsContent = "Ship to (Project Details)";
exports.siteNameContent = "Site Name";
exports.siteAddressContent = "Site Address";
exports.contactPersonTitle = "Contact Person";
exports.siteContactContent = "Site Contact";
exports.RateSheetBoxText ="No items in this Segment/Category";
exports.RateSheetBoxTextBold ="Drag & Drop Selected items to move here";
exports.RateSheetEnterCategory = "Enter name of category";
exports.AddNewItem = "Add New Item";
exports.SegmentContent = "Segment/Category";
exports.SegmentTotalContent = "Segment/Category Total"
// Customizable Area End