Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";
exports.uploadTitle="Invoices"

exports.invoiceFileUploadApiEndPoint = "bx_block_filter_items/invoice_libraries/invoice_upload_against_quote";
exports.fileUploadApiEndPoint = "bx_block_filter_items/quotes_libraries/report_quote_filter";
exports.quoteResultApiEndPoint="bx_block_filter_items/quotes_libraries/upload_quote_on_result";
exports.invoiceUploadApiEndPoint = "bx_block_filter_items/invoice_libraries/report_invoice_filter";
exports.fileUploadApiMethod = "POST";
exports.getUploadApiMethod = "GET";
exports.getQuoteApiEndPoint = "bx_block_filter_items/invoice_libraries"
exports.getProjectsApiEndPoint = "bx_block_filter_items/invoice_libraries/quotes_project_vendor"
exports.fileUploadApiContentType = "application/json";
exports.fileGetApiMethod="Get";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";
exports.invoiceText = "Invoices";

exports.compareTitle = "Quote to Compare?"
exports.noBtnTitle = 'No';
exports.yesBtnTitle = 'Yes';
exports.invoiceTitle = "Result of Invoice";
exports.viewButton = "View";
exports.downloadButton = "Download";
exports.editButton = "Edit";
exports.uploadButton = "Upload";
exports.downloadButton = "Download";
exports.compareApiContentType = "application/json";
exports.compareApiMethodType = "POST";
exports.compareApiEndPoint = "bx_block_filter_items/invoice_libraries/invoice_upload_against";
exports.invoicePdfOpenEndPoint= "bx_block_filter_items/invoice_libraries/invoice_pdf_open?"
exports.deleteApiMethod = "DELETE"
exports.getCommentAPiEndPoint = "bx_block_comments/comments"
exports.addCommentApiEndPoint = "bx_block_comments/comments"
exports.updateCommentApiEndPoint = "bx_block_comments/comments/:id?user_id="
exports.deleteCommentAPiEndPoint = "bx_block_comments/comments/:id?user_id="
exports.addReplyApiEndPoint = "bx_block_comments/replies?id="
exports.updateReplyApiEndPoint = "bx_block_comments/replies/:id"
exports.deleteReplyApiEndPoint = "bx_block_comments/replies/:id"
exports.noCompareApiEndPoint = "bx_block_filter_items/invoice_libraries";
exports.noinvoiceApprovalApiEndPoint = "bx_block_filter_items/invoice_libraries/invoice_pdf_open";
exports.noApprovalApiContentType = "application/pdf";
exports.rejectAndApproveToPayApiEndPoint = "bx_block_filter_items/invoice_libraries/approval_status";
exports.deleteQuoteEndpoint="bx_block_filter_items/quotes_libraries/delete_quote_report";
exports.deleteInvoiceEndpoint="bx_block_filter_items/invoice_libraries/delete_invoice_report";
exports.createNotificationEndpoint="bx_block_notifications/overcharged_undercharged_notification"
exports.reportInvoiceApiEndPoint = "bx_block_notifications/invoices_report";
exports.editInvoiceButton = "Edit invoice"
exports.viewInvoiceText = "View Invoice"
exports.invoiceDetailsText = "Invoice details"
exports.invoiceNumberText = "Invoice Number"
exports.invoiceDateText ="Ship Date"
exports.invoiceExpiaryDateText = "Invoice Date"
exports.invoiceTerms = "Terms"
exports.supplierDetailsText = "Supplier details"
exports.supplierNameText = "Supplier Name"
exports.supplierAddressText = "Supplier Address"
exports.salesPersonText= "Sales Person" 
exports.supplierContactText = "Supplier Contact"
exports.shipToText = "Ship to (Project Details)"
exports.siteNameText ="Site Name"
exports.siteAddressText="Site Address"
exports.contactPersonText = "Contact Person"
exports.siteContactText ="Site Contact"
exports.itemDetailsText ="Item details"
exports.subTotalText="Subtotal"
exports.subTotalOrGrossAmountText = "Subtotal/Gross Amount"
exports.sAndHChargesText = "S&H Charges"
exports.otherChargesText ="Other Charges"
exports.discountText = "- Discount"
exports.taxText = "Tax"
exports.totalText = "Total"
exports.goBackButtonText = "Go back"
exports.continuebuttonText = "Continue"
exports.editInvoiceText = "Edit Invoice"
exports.saveButtonText = "Save"
exports.rentalDetailsText ="Rental item details"
exports.salesItemDetailsText ="Sales item details"
exports.commonTaxColumns = [
  { id: 'Sr.no', label: 'Sr. No' },
  { id: 'description', label: 'Description' },
  { id: 'OrderQty.', label: 'Order Qty.' },
  { id: 'unit', label: 'Unit' },
  { id: 'unitPrice', label: 'Unit Price' },
  { id: 'tax', label: 'Tax (%)' },
  { id: 'Ext. Price', label: 'Ext. Price' }
];



exports.addInputNameMethodType = "POST";
exports.getVendorsEndPoint="account_block/vendors"
exports.getProjectsEndPoint="bx_block_projects/projects"
exports.viewquoteTitle = "View Quote";
exports.quotedetailsContent = "Quote details";
exports.quoteNumberContent = "Quote Number";
exports.quoteDateContent = "Quote Date";
exports.expiryquoteTitle = "Quote Expiry Date";
exports.supplierdetailsContent = "Supplier details";
exports.supplierNameContent = "Supplier Name";
exports.supplierAddressContent = "Supplier Address";
exports.salesPersonTitle = "Sales Person";
exports.supplierContactContent = "Supplier Contact";
exports.projectdetailsContent = "Ship to (Project Details)";
exports.siteNameContent = "Site Name";
exports.siteAddressContent = "Site Address";
exports.contactPersonTitle = "Contact Person";
exports.siteContactContent = "Site Contact"
exports.itemdetails = "Item details";
exports.segment = "Subtotal";
exports.subTotal = "Subtotal/Gross Amount";
exports.charges = "S&H Charges";
exports.othercharges = "Other Charges";
exports.discount = "- Discount";
exports.tax = "Tax";
exports.total = "Total";
exports.invoiceDetailsContent = "Invoice details";
exports.invoiceNumberContent = "Invoice Number";
exports.invoiceDateContent = "Invoice Date";
exports.invoiceExpiryDateContent = "Invoice Expiry Date";
exports.totaldiscrencyText = "Total discrepancy";
exports.totalQuoteText = "Total quote amount";
exports.totalInvoiceText = "Total invoice amount";
exports.quoteText = "Quote";
exports.invoiceText= "Invoice";
exports.dashboardText = "Dashboard";
exports.createNewReportText = "Create a new report"
exports.deleteReportEndPoint ="bx_block_reports/reports/"


exports.idTitle = 'Id',
exports.descriptionTitle = 'description',
exports.ord_quantityTitle = 'ord_quantity',
exports.unitTitle = 'Idunit',
exports.priceTitle = 'price',
exports.amountTitle = 'amount',
exports.report_quote_idTitle = 'report_quote_id',
exports.created_atTitle = 'Created Date',
exports.updated_atTitle = 'Updated Date',
exports.part_numberTitle = 'Part Number',
exports.taxTitle = 'Tax',
exports.category_nameTitle = 'category name',
exports.categorySubtotalTitle = "Category Subtotal",
exports.Data_type = "Data Type",
exports.Quote_Id = "Quote Id",
exports.ReportId = "Report Id",
exports.DateTitle = "Date",
exports.Vendor = "Vendor",
exports.Project = "Project",
exports.Status = "Status",
exports.TotalPrice = "Total Price",
exports.Supplier_Address = "Supplier Address",
exports.SupplierContact = "Supplier Contact",
exports.SupplierSalesperson = "Supplier Salesperson",
exports.SiteAddress = "Site Address",
exports.SiteContactperson = "Site Contactperson",
exports.SiteContact = "Site Contact",
exports.Subtotal = "Subtotal",
exports.SandHCharges = "S and H Charges",
exports.Tax = "Tax",
exports.OtherCharges = "Other Charges",
exports.Taxamount = "Tax amount",
exports.Discount = "Discount",
exports.GrossSubtotal = "Gross Subtotal",
exports.ExpirationDate = "Expiration Date",
exports.SegmentContent = "Segment/Category",
exports.uploadQuoteTitle = "Upload Quote",
exports.DragAndDropTitle = "Drag & drop PDF",
exports.orTitle = "OR",
exports.browseTitle = "Browse"
// Customizable Area End
