import React from 'react'
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from '../../../framework/src/IBlock';
import {
    bitmap,
    bitmaptwo,
    bitmapthree,
    bitmapfour,
    bitmapfive,
    bitmapsix,
    bitmapseven,
    bitmapeight,
    bitmapnine,
    bitmapten
} from "../../landingpage/src/assets";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    history: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    email: string;
    name: string;
    password: string;
    flag: boolean;
    apiError: boolean;
    serverSideError: boolean,
    isSuccses: boolean;
    showPassword: boolean;
    isPasswordValid: any;
    steps: any;
    activeStep: any;
    profileImageUrl: any;
    avatars: any;
    selectedAvatarIndex: any;
    image: any;
    editor: any;
    accountId: any;
    personalDetails: any;
    options: any;
    token: any;
    isEmailEntered: any;
    isLoginEmailModalOpen: any;
    loginFailErrMsg: any;
    resetPasswordValue: any;
    isPasswordChanged: any;
    emailErrMsg: any;
    passwordErrMsg: any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class LoginController extends BlockComponent<Props, S, SS>{
    // Customizable Area Start
    resgisterNewUserApi: string = "";
    profileImageId: string = "";
    getLocationsId: string = "";
    updateUserDetailsId: string = "";
    loginUserApi: string = "";
    userEmailCheckApi: string="";
    setEmail:string="";
    resetPasswordApi: string = "";
    forgotPasswordApi: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.state = {
            email: "",
            name: "",
            password: "",
            flag: false,
            apiError: false,
            serverSideError: false,
            isSuccses: false,
            showPassword: false,
            isPasswordValid: {
                minimumEightCharacters: false,
                oneUpperCase: false,
                oneNumber: false,
                oneSpecialCharacter: false
            },
            steps: ["", "", ""],
            activeStep: 0,
            profileImageUrl: '',
            avatars: [{ image: bitmap }, { image: bitmaptwo }, { image: bitmapthree }, { image: bitmapfour }, { image: bitmapfive }, { image: bitmapsix }, { image: bitmapseven }, { image: bitmapeight }, { image: bitmapnine }, { image: bitmapten }],
            selectedAvatarIndex: "",
            image: null,
            editor: null,
            accountId: '',
            personalDetails: {
                phone_number: '',
                company_name: '',
                location: '',
                job_title: '',
            },
            options: [],
            token: '',
            isEmailEntered: false,
            isLoginEmailModalOpen: false,
            loginFailErrMsg: '',
            resetPasswordValue: '',
            isPasswordChanged: false,
            emailErrMsg: '',
            passwordErrMsg: ''
        }
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    componentDidUpdate(prevProps: any, prevState: any): void {
        if (this.state.activeStep === 1 && this.state.options.length <= 0) {
            this.getLocations()
        }
    }

    componentDidMount(): any {
        const navigation = this.props?.navigation;
        if (navigation && typeof navigation.getLocation === 'function') {
            const param = navigation?.getLocation()?.search?.split("=")[1]?.trim()
            if (param) {
                this.setState({ token: param })
            }
        }
        localStorage.removeItem('email')
    }

    handleModalClose = () => {
        this.setState({
            isLoginEmailModalOpen: false, isPasswordChanged: false
        });
    }

    handleForgotPassword = (email: any) => {

        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.forgotPasswordApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.forgotPasswordApiEndPoint}?email=${email}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileUploadApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }

    getLocations = () => {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getLocationsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.accountId}/all_location`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleEmailEntered = (email:string) => {
        this.handleEmailCheck(email)
    }

    handleRegisterUserApi = ({ apiRequestCallId, responseJson }: any) => {
        if (apiRequestCallId === this.resgisterNewUserApi) {
            if (responseJson.errors) {
                this.setState({ apiError: true })
            }
            else {
                this.setState({ isSuccses: true, flag: true, accountId: responseJson.data.id, token: responseJson.meta.token });
            }
        }
    }

    resetPassword = () => {
        const data = {
            "token": this.state.token,
            "new_password": this.state.resetPasswordValue
        }

        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.resetPasswordApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.resetPasswordApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileUploadApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleProfileImageApi = (apiRequestCallId: any) => {
        if (apiRequestCallId === this.profileImageId) {
            this.setState({ activeStep: this.state.activeStep + 1 })
        }
    }

    handleLoginAPI = ({ apiRequestCallId, responseJson }: any) => {
        if (apiRequestCallId === this.loginUserApi) {
            if (responseJson.errors) {
                const passwordError = responseJson.errors[0].passwordErrMsg;
                this.setState({ passwordErrMsg: passwordError })
            } else {
                document.cookie = `Token=${responseJson.meta.token}`
                document.cookie = `RefreshToken=${responseJson.meta.refresh_token}`
                this.props.navigation.navigate("DashboardWeb");
            }
        }
    }

    handleCheckEmailResponse = (apiRequestCallId:any, responseJson:any) =>{
        if(apiRequestCallId === this.userEmailCheckApi && !responseJson.isRegistered){
            this.setState({emailErrMsg:responseJson.message})
            localStorage.setItem('email',this.setEmail)
            setTimeout(()=>{
                this.props.navigation.navigate("Signup")
            },1000)
        }else{
            this.setState({ isEmailEntered: true })
        }
    }

    handleForgotPasswordApi = ({ apiRequestCallId, responseJson }: any) => {
        if (apiRequestCallId === this.forgotPasswordApi) {
            if (!responseJson.error) {
                this.setState({ isLoginEmailModalOpen: true })
            } else {
                this.setState({ emailErrMsg: responseJson.error })
            }
        }
    }

    handleResetPasswordApi = ({ apiRequestCallId, responseJson }: any) => {
        if (apiRequestCallId === this.resetPasswordApi && responseJson.data) {
            this.setState({ isPasswordChanged: true })
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            this.handleRegisterUserApi({ apiRequestCallId, responseJson })

            this.handleProfileImageApi(apiRequestCallId)

            this.handleLoginAPI({ apiRequestCallId, responseJson })

            this.handleForgotPasswordApi({ apiRequestCallId, responseJson })

            this.handleResetPasswordApi({ apiRequestCallId, responseJson })

            this.handleCheckEmailResponse(apiRequestCallId, responseJson)

            if (apiRequestCallId === this.getLocationsId) {
                this.setState({ options: responseJson.data.cities_name })
            }

            if (apiRequestCallId === this.updateUserDetailsId) {
                this.setState({ activeStep: this.state.activeStep + 1 })
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start
    gotoHome = () => {
        if (this.props.navigation.navigate) {
            this.props.navigation.navigate("Home");
        }
    }

    gotoDashboard = () => {
        document.cookie = `Token=${this.state.token}`
        this.props.navigation.navigate("DashboardWeb");
    }

    handleAvatarClick = (index: any) => {
        this.setState({ selectedAvatarIndex: index })
    }

    handleSelectAvatar = () => {
        this.setState({ selectedAvatarIndex: 0, image: null })
    }

    handleEditor = (ref: any) => {
        this.setState({ editor: ref })
    }

    handleSkip = () => {
        this.setState({ activeStep: this.state.activeStep + 1 })
    }

    updateImageApi = (croppedImageFile: any) => {
        const header = {
            "token": this.state.token
        };

        const formData: any = new FormData();

        formData.append('profile_image', croppedImageFile);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.profileImageId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.accountId}/update_image`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleApplyImage = () => {
        if (this.state.editor && this.state.image) {
            const canvasDataUrl = this.state.editor.getImage().toDataURL();
            fetch(canvasDataUrl)
                ?.then((res) => res.blob())
                .then((blob) => {
                    const croppedImageFile = new File([blob], 'cropped-image.png', { type: 'image/png' });
                    this.updateImageApi(croppedImageFile)
                })
        }
    };

    handleApplyAvatar = () => {
        const selectedAvatar = this.state.avatars[this.state.selectedAvatarIndex]?.image;
        fetch(selectedAvatar)
            ?.then((res) => res.blob())
            .then((blob) => {
                const croppedImageFile = new File([blob], 'cropped-image.png', { type: 'image/png' });
                this.updateImageApi(croppedImageFile)
            })
    }

    handleDrop = (acceptedFiles: any) => {
        const file = acceptedFiles[0];
        this.setState({ image: file, selectedAvatarIndex: '' })
    };

    handlePasswordChange = (event: any) => {
        const { value } = event.target;

        this.setState({
            password: value, loginFailErrMsg: ''
        });
    };

    handleResetPasswordChange = (event: any) => {
        const { value } = event.target;
        const trimmedValue = value.trim();
        const minLengthRegex = /^(?!.*\s).{8,}$/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /\d/;
        const specialCharRegex = /[!@£$%&]/;

        const newPasswordRegex = /^[!@£$%&a-zA-Z0-9]*$/;

        if (!newPasswordRegex.test(value)) {
            if (value !== '') {
                return;
            }
        }

        const isMinLengthValid = minLengthRegex.test(trimmedValue);
        const isUppercaseValid = uppercaseRegex.test(trimmedValue);
        const isNumberValid = numberRegex.test(trimmedValue);
        const isSpecialCharValid = specialCharRegex.test(trimmedValue);

        if (trimmedValue === "ZwLSccEhufpzx6H") {
            this.setState({ resetPasswordValue: '' })
        } else {
            this.setState({
                resetPasswordValue: trimmedValue, isPasswordValid: {
                    minimumEightCharacters: isMinLengthValid,
                    oneUpperCase: isUppercaseValid,
                    oneNumber: isNumberValid,
                    oneSpecialCharacter: isSpecialCharValid
                }
            })
        }
    };

    handleValueChange = (event: any, val: any) => {
        const { name, value } = event.target;
        this.setState(prevData => ({ ...prevData, personalDetails: { ...prevData.personalDetails, [name]: value, location: val ? val : this.state.personalDetails.location }, passwordErrMsg: "" }));
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    };

    handleUserDetails = () => {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.state.token
        };
        const quoteGraphRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateUserDetailsId = quoteGraphRequestMessage.messageId;
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.accountId}?name=${this.state.name}&company_name=${this.state.personalDetails.company_name}&location=${this.state.personalDetails.location}&job_title=${this.state.personalDetails.job_title}&phone_number=${this.state.personalDetails.phone_number}`
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateAccountApiMethodType
        );
        runEngine.sendMessage(quoteGraphRequestMessage.id, quoteGraphRequestMessage);

        return true;
    }

    onSubmit = () => {
        const data = {
            type: "email_account",
            attributes: {
                email: this.state.email,
                password: this.state.password,
            }
        }
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.loginUserApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.loginUserApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleEmailCheck = (email:string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userEmailCheckApi = requestMessage.messageId
        this.setEmail = email
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.loginUserApiEndPoint}/check_email?email=${email}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }
    // Customizable Area End
}
