// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface QuoteDescription {
    id: number;
    description: string;
    ord_quantity: number;
    unit: string;
    price: string;
    amount: string;
    report_quote_id: number;
    created_at: string;
    updated_at: string;
    part_number: string;
    tax: null | string;
    category_name: null | string;
    category_subtotal: null | string;
    rate_sheet_cat: string;
    rate_sheet_min: string;
    rate_sheet_daily: string;
    rate_sheet_weekly: string;
    rate_sheet_monthly: string;
    cat_class: string;
}

export interface TableHeadProps {
    label: string;
    id: string
}

interface QuoteDetail {
    data: {
        id: string,
        type: string,
        attributes: {
            id: number,
            quote_id: string,
            report_id: number,
            date: null,
            vendor: string,
            project: string,
            status: string,
            total_price: null,
            supplier_address: string,
            supplier_salesperson: string,
            supplier_contact: string,
            site_name: string,
            site_address: string,
            site_contactperson: string,
            site_contact: string,
            subtotal: null,
            s_and_h_charges: null,
            tax: null,
            other_charges: null,
            tax_rate_amount: null,
            discount: null,
            expiration_date: null,
            gross_subtotal: null,
            created_at: string,
            updated_at: string
        }
    }
}

export interface Props {
    // Customizable Area Start
    navigation: undefined
    id: number
    classes: any
    history: {
        push: () => void
        goBack: () => void
        location: {
            state: {
                navigateId: number
            }
        }
    }
    location: {
        pathname: string
        search: string
        hash: string
        state: {
            navigateId: number
        }
    }
    quoteData: {
        quoteDetails: QuoteDetail[]
        quoteDescription: QuoteDescription[]
    }
    handleSaveData: () => {}
    onContinueButtonClick: () => void
    goBackButtonNavigation: () => void
    segmentName1: string
    segmentTotal1: number
    segmentName2: string
    segmentTotal2: number
    quoteCategoryDescription: QuoteCategoryDescriptionInterface[]
    // Customizable Area End
}
export interface QuoteCategoryDescriptionInterface {
    id: number,
    addItemBox: boolean,
    addNewSegmentState: boolean,
    categoryBox: boolean,
    categoryErrorMessage: string,
    category_name: string,
    content: string,
    data: QuoteDescription[],
    pagination: {
        id: number,
        page: number,
        rowsPerPage: number
    },
    selected: number[],
    subtotalValue: null,
    warningTableBox: boolean
}
export interface TableDataProps {
    classes: any,
    quotestate: (QuoteDescription | QuoteCategoryDescriptionInterface)[],
    segmentName: string,
    RateQuoteRowsPerPage: number,
    RateQuotePage: number,
    selectedRadioValue: string,
    handleRateSheetChangePage: (event: unknown, newPage: number) => void,
    isreportPage: boolean
}
export interface TableSegmentDataProps {
    classes: any,
    quotestateDescription: QuoteDescription[],
    handleRateSheetCategoryDataPageChange: (event: React.ChangeEvent<unknown>, page: number, columnId: number) => void,
    segmentName: string,
    isreportPageData: boolean,
    quotestate: QuoteDescription[],
    selectedRadioValue: string,
    isreportPage: boolean
}

export interface TableSegmentRateDataProps {
    classes: any,
    quotestate: QuoteCategoryDescriptionInterface[],
    handleRateSheetCategoryDataPageChange: (event: React.ChangeEvent<unknown>, page: number, columnId: number) => void,
    segmentName: string,
    isreportPage: boolean,
    selectedRadioValue: string,
    RateSheetHeadColumn: TableHeadProps[]
}

export interface QuoteInterface {
    id: number;
    cat_class: string;
    description: string;
    rate_sheet_cat: string;
    rate_sheet_min: string;
    rate_sheet_daily?: string;
    rate_sheet_weekly?: string;
    rate_sheet_monthly?: string;
    amount: string;
}

export interface RateSheetTableHeadProps {
    RateSheetHeadColumn: TableHeadProps[],
    selectedRadioValue: string
}

interface S {
    // Customizable Area Start
    value: number,
    quotestate: QuoteDescription[] | QuoteCategoryDescriptionInterface[],
    quotedetail: QuoteDetail[],
    RateQuotePage: number,
    RateQuoteRowsPerPage: number,
    quoteDragDataPage: number,
    selectedRadioValue: string,
    RateSheetHeadColumn: TableHeadProps[]
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class RateSheetDetailController extends BlockComponent<
    Props,
    S,
    SS
>{
    constructor(props: Props) {
        
        super(props)
        this.state = {
            // Customizable Area Start


            RateQuotePage: 1,
            RateQuoteRowsPerPage: 10,
            quoteDragDataPage: 1,
            value: 1,
            quotestate: this.props.quoteData.quoteDescription,
            quotedetail: this.props.quoteData.quoteDetails,
            selectedRadioValue: configJSON.dayTitle,
            RateSheetHeadColumn: [
                {
                    id: 'sr_no',
                    label: 'Sr. No',
                },
                {
                    id: 'Cat-class',
                    label: 'Cat-class',
                },
                {
                    id: 'Description',
                    label: 'Description',
                },
                {
                    id: 'Category',
                    label: 'Category',
                },
                {
                    id: 'Min',
                    label: 'Min.',
                },
                
                {
                    id: 'Daily',
                    label: 'Daily',
                },
                {
                    id: 'Ext. Rates',
                    label: 'Ext. Rates',
                },
                {
                    id: 'Weekly',
                    label: 'Weekly',
                },
                {
                    id: 'Monthly',
                    label: 'Monthly',
                }
            ]
            // Customizable Area End

        }

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    handleRateSheetChangePage = (event: unknown, newPage: number) => {
        this.setState({ RateQuotePage: newPage })
    };

    handleRateSheetCategoryDataPageChange = (event: unknown, page: number, columnId: number) => {
        const updatedTablesData: QuoteCategoryDescriptionInterface[] = [...this.props.quoteCategoryDescription];
        
        if (updatedTablesData[columnId]?.pagination) {
            updatedTablesData[columnId].pagination.page = page;
        }
    
        this.setState({ quotestate: updatedTablesData });
    };

    handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedRadioValue: event.target.value });
    };


}
// Customizable Area End
