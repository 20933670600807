// Customizable Area Start
import React from "react";
import {
    Box,
    Grid, Button,
    Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,Breadcrumbs,Tabs,Tab
} from "@material-ui/core";
import { editSvg } from "../src/assets";
import Pagination from '@material-ui/lab/Pagination';
import { createTheme, withStyles } from "@material-ui/core/styles";
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Header from "../../landingpage/src/Header.web";
import moment from "moment";
import { CreateCSSProperties } from '@material-ui/styles';
import InvoiceScanResultController, { Props, configJSON } from "./InvoiceScanResultController.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End
class InvoiceScanResult extends InvoiceScanResultController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, navigation, handleSaveData } = this.props
        const { invoiceDetailData, invoiceColumn, invoiceComparisonData, quoteRowsPerPage, quotePage } = this.state

        const begin = (quotePage - 1) * quoteRowsPerPage;
        const end = begin + quoteRowsPerPage;
        const countVal = Math.ceil((invoiceComparisonData && invoiceComparisonData.length) / quoteRowsPerPage)
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />         
                <Box className={classes.invoiceHeaderName}>
                    <Box style={{display:'flex'}}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Typography className={classes.linkButtons} data-testid="reportnavigation" onClick={this.getNavigationReport}>{configJSON.ViewInvoiceProjects}</Typography>
                            <Typography className={classes.linkButtons} onClick={this.getNavigationViewreport}>{configJSON.reportOf}'{this.state?.reportof}'</Typography>
                            <Typography className={classes.linkButton}  data-testid="ViewreportBack" onClick={this.goBackInvoicehandler}>{this.state?.lastReportname}</Typography>
                            <Typography className={classes.headerLinkColor}>{configJSON.ViewInvoiceScanResults}</Typography>
                        </Breadcrumbs>
                        <Button id='editinvoiceButton' className={classes.editinvoiceButton}>
                            <img src={editSvg} />
                            {configJSON.editInvoice}
                        </Button>
                    </Box>
                   
                </Box>
               
                <Tabs
                    className={classes.invoiceInnerdataBox2}
                    onChange={this.handleViewinvoceTabChange}
                    value={this.state.invoiceViewTabIndex}
                    data-testid="tabinvoiceChangeid"
                >
                    {

this.state.invoiceSingleDetailData && this.state.invoiceSingleDetailData.slice(0, 8).map((data:{invoice_site_name:string,project:string},index:number) => {
                            return (
                                <Tab
                                    label={
                                        <>
                                            <Box className={classes.invoicecurvedataBox}>
                                                <Box className={classes.invoicecurveDataInnerBox}></Box>
                                            </Box>
                                            <Typography variant="subtitle2" style={{color:'black', whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis", maxWidth: "8ch", textTransform: "none"}}>{data.project}</Typography>
                                            <Box className={classes.invoicedatacurveRightBox}>
                                                <Box className={classes.curvedataRightinvoiceInnerBox}></Box>
                                            </Box>
                                        </>
                                    }
                                    title={data.invoice_site_name}
                                    className={classes.invoicedataTitleBox}
                                />
                            )
                        })
                    }

                </Tabs>
                <Box className={classes.invoiceScanBox}>
                    <Typography className={classes.viewinvoiceTitle} variant="h5">{configJSON.viewInvoiceTitle}</Typography>
                    <Box>
                        <Grid container spacing={2}>
                            {
                                invoiceDetailData && invoiceDetailData.map((invoiceItem, index) => {
                                    const expiryDateVal = moment(invoiceItem.date).format("MM-DD-YYYY")
                                    const shippedDateVal = moment(invoiceItem.invoice_shipped_date).format("MM-DD-YYYY")
                                    return (
                                      <>
                                            <Grid item sm={4} key={index}>
                                                <Box>
                                                    <Typography variant="h6" className={classes.invoicedetailsContent}>{configJSON.invoicedetailsContent}</Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.invoiceNumberContent}</Typography>
                                                    <Typography className={classes.invoiceContentBox}>{invoiceItem.quote_id}</Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.invoiceShipDateContent}</Typography>
                                                    <Typography className={classes.invoiceContentDateBox}>{shippedDateVal} <CalendarTodayOutlinedIcon/></Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.invoiceDateContent}</Typography>
                                                    <Typography className={classes.invoiceContentDateBox}>{expiryDateVal} <CalendarTodayOutlinedIcon/></Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.termsinvoiceTitle}</Typography>
                                                    <Typography className={classes.invoiceContentDateBox}>{invoiceItem.invoice_terms}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Box>
                                                    <Typography variant="h6" className={classes.invoicedetailsContent}>{configJSON.supplierdetailsContent}</Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.supplierNameContent}</Typography>
                                                    <Typography className={classes.invoiceContentBox}>{invoiceItem.project}</Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.supplierAddressContent}</Typography>
                                                    <Typography className={classes.invoiceContentBox}>{invoiceItem.invoice_supplier_address}</Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.salesPersonTitle}</Typography>
                                                    <Typography className={classes.invoiceContentBox}>{invoiceItem.invoice_salesperson_name}</Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.supplierContactContent}</Typography>
                                                    <Typography className={classes.invoiceContentBox}>{invoiceItem.invoice_supplier_contact}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Box>
                                                    <Typography variant="h6" className={classes.invoicedetailsContent}>{configJSON.projectdetailsContent}</Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.siteNameContent}</Typography>
                                                    <Typography className={classes.invoiceContentBox}>{invoiceItem.invoice_site_name}</Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.siteAddressContent}</Typography>
                                                    <Typography className={classes.invoiceContentBox}>{invoiceItem.invoice_site_address}</Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.contactPersonTitle}</Typography>
                                                    <Typography className={classes.invoiceContentBox}>{invoiceItem.invoice_site_person}</Typography>
                                                    <Typography className={classes.invoiceDetailTitle}>{configJSON.siteContactContent}</Typography>
                                                    {
                                                       invoiceItem.invoice_site_contact === null ? <Typography className={classes.invoiceContentBox}>{"Unknown"}</Typography> : <Typography className={classes.invoiceContentBox}>{invoiceItem.invoice_site_contact}</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                            </>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                    <Box className={classes.itemInvoicetableBox}>
                        <Typography variant="h6" className={classes.invoicedetailsContent}>{configJSON.itemdetailsContent}</Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    {invoiceColumn.map((column:any) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableHead>
                                <TableBody className={classes.tableBodyBox}>

                                    {invoiceComparisonData&&invoiceComparisonData.slice(begin, end).sort((startid, endid) => startid.id - endid.id).map((invoiceData, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                <TableCell>{(invoiceData && invoiceData.id)}</TableCell>
                                                <TableCell>{(invoiceData && invoiceData.attributes.description)}</TableCell>
                                                <TableCell>{(invoiceData && invoiceData.attributes.ord_quantity)}</TableCell>
                                                <TableCell className={classes.invoiceUnitContent}>{(invoiceData && invoiceData.attributes.order_unit)}</TableCell>
                                                <TableCell>$ {(invoiceData && invoiceData.attributes.amount)}</TableCell>
                                                <TableCell>$ {(invoiceData && invoiceData.attributes.price)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Pagination
                            count={countVal}
                            variant="outlined"
                            shape="rounded"
                            onChange={this.handleChangeinvoicePage}
                            page={quotePage}
                            data-testid="paginationTestId"
                        />
                        <Box className={classes.subTotalInvoiceBox}>
                            {invoiceDetailData && invoiceDetailData.map((invoicetotalVal, index) => {
                                return (
                                    <Box style={{display:"flex"}}key={index}>
                                        <Typography>{configJSON.segmentContent}</Typography>
                                        <Typography className={classes.invoiceContentBox} style={{width:"100%"}}>$ {invoicetotalVal.invoice_subtotal}</Typography>
                                    </Box>
                                )
                            })}

                        </Box>

                    </Box>
                    <Box className={classes.invoiceTotalAmountBox}>
                        {
                            invoiceDetailData.map((invoiceAllAmount: any) => {
                                return (
                                    <Box>
                                        <Box className={classes.subTotalInvoiceContentBox}>
                                            <Typography className={classes.invoiceDetailTitle}>{configJSON.subTotalContent}</Typography>
                                            <Typography>$ {invoiceAllAmount.invoice_subtotal}</Typography>                                        </Box>
                                        <Box className={classes.subTotalInvoiceContentBox}>
                                            <Typography className={classes.invoiceDetailTitle}>{configJSON.chargesContent}</Typography>
                                            <Typography>$ {invoiceAllAmount.invoice_s_and_h_charges}</Typography>
                                        </Box>
                                        <Box className={classes.subTotalInvoiceContentBox}>
                                            <Typography className={classes.invoiceDetailTitle}>{configJSON.otherchargesContent}</Typography>
                                            <Typography>$ {invoiceAllAmount.invoice_other_charges}</Typography>
                                        </Box>

                                        <Box className={classes.subTotalInvoiceContentBox}>
                                            <Typography className={classes.invoiceDetailTitle}>{configJSON.discountContent}</Typography>
                                            <Typography>$ {invoiceAllAmount.discount}</Typography>
                                        </Box>
                                        <Box className={classes.taxinvoiceContent}>
                                                <Typography className={classes.invoiceDetailTitle}>{configJSON.taxContent}</Typography>
                                            <Box className={classes.taxinvoiceInputBox}>
                                                <Typography>{invoiceAllAmount.invoice_tax_percent}%</Typography>
                                                <Typography>$ {invoiceAllAmount.invoice_tax}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className={classes.subTotalInvoiceContentBox}>
                                            <Typography className={classes.totalinvoicePrice}>{configJSON.totalContent}</Typography>
                                            <Typography>$ {invoiceAllAmount.total_price}</Typography>
                                        </Box>
                                    </Box>
                                )
                            })
                        }

                    </Box>
                </Box>
                <Button className={classes.goBackInvoiceButton} onClick={this.goBackInvoicehandler} data-testid="goBackTestid">
                    {configJSON.goBack}
                </Button>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const InvoiceScanResultStyle = () => ({
    invoiceScanBox: {
        background: '#fff',
        borderRadius: '10px',
        padding: '50px 25px 50px 50px',
        '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '8px'
        },
        '& .MuiTableContainer-root': {
            overflowX: 'unset',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        },
        '& .MuiTableCell-head': {
            paddingLeft: 0,
            fontWeight: 600
        }

    },
    headerLinkColor:{
        color: "orange",
        fontWeight: 500
    },
    linkButtons:{
        textDecoration: "underline",
        fontWeight: 500,
        color: '#87888F',
        cursor:'pointer',
    },
    linkButton: {
        textDecoration: "underline",
        fontWeight: 500,
        color: '#87888F',
        cursor: 'pointer',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "150px"
    } as CreateCSSProperties,
    invoiceInnerdataBox2: {
        top: 0,
        width: '100%',
        overflow: 'visible',
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },
        '& span': {
            textTransform: 'none',
        },
        '& span.MuiTabs-indicator': {
            display: 'none'
        },

        '& MuiBox-root-67': {
            padding: 0
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
    },
    invoicecurvedataBox: {
        width: '31px',
        height: '30px',
        position: 'absolute' as 'absolute',
        background: '#fcf7f2',
        left: '-31px',
        bottom: 0,
    },
    invoicecurveDataInnerBox: {
        height: '30px',
        border: '1px solid #e0802e',
        borderBottomRightRadius: '15px',
        width: '31px',
        borderLeft: 'none',
        borderTop: 'none',
    },
    invoicedatacurveRightBox: {
        zIndex: 99,
        height: '33px',
        background: '#fcf7f2',
        width: '30px',
        position: 'absolute' as 'absolute',
        right: '-30px',
        bottom: 0,
    },
    curvedataRightinvoiceInnerBox: {
        borderRight: 'none',
        border: '1px solid #e0802e',
        width: '30px',
        height: '32px',
        borderTop: 'none',
        borderBottomLeftRadius: '15px',
    },
    invoicedataTitleBox: {
        textAlign: 'center' as 'center',
        minWidth: '115px',
        border: '0.5px solid #e4e3e3',
        padding: '13px 0px',
        borderTopRightRadius: '12px',
        borderTopLeftRadius: '12px',
        backgroundColor: "white",
        '&.Mui-selected': {
            border: '0.5px solid white',
            borderTopLeftRadius: '12px',
            background: '#ffffff',
            borderBottom: 'none',
            borderTopRightRadius: '12px',
        },
    },
    viewinvoiceTitle: {
        color: '#000',
        fontWeight: 600
    },
    invoiceDetailTitle:{
        color: '#0a0019',
        fontWeight: 500,
        margin: '15px 0 5px',
    },
    invoiceContentBox:{
        color: '#aeaab2',
        fontWeight: 500,
        padding: '20px',
        border: '1px solid #d5d5d5',
        borderRadius: '5px',
        marginBottom: '30px',
        marginRight: '25px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as 'nowrap'
    },
    invoiceContentDateBox:{
        color: '#aeaab2',
        fontWeight: 500,
        padding: '20px',
        border: '1px solid #d5d5d5',
        borderRadius: '5px',
        marginBottom: '30px',
        marginRight: '25px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    invoiceDeatilBox: {
        paddingBottom: '20px',
    },
    invoicedetailsContent: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        marginTop: '50px',
        marginBottom: '20px'
    },
    tableBodyBox: {
        "& td": {
            border: '1px solid #e6e6e6 !important',
            borderRadius: '5px',
            color: '#aeaab2',
            fontWeight: 500,
            '&:first-child':{
                textAlign: 'center',
                width: '75px',
                padding: 0
            }
        }
    },
    subTotalInvoiceBox: {
        display: 'flex',
        justifyContent: 'end',
        margin: '5px -3px 50px 0',
        '& p:first-child':{
            color: '#000',
            marginTop: '20px'
        },
        '& p:last-child': {
            marginLeft: '20px',
            width: '14%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '15px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
            marginBottom: '30px',
            marginRight: '-3px',
        }
    },
    invoiceTotalAmountBox: {
        border: '2px solid #eee',
        borderRadius: '8px',
        padding: '20px',
        width: '32%',
        margin: '0 20px 0 auto'
    },
    subTotalInvoiceContentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
        '& p:last-child': {
            width: '35%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '12px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
        }
    },
    totalinvoicePrice:{
        fontWeight: 600,
        color: '#0a0019',
        margin: '15px 0 5px',
    },
    taxinvoiceContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    taxinvoiceInputBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        '& p:last-child': {
            width: '38%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '12px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
        },
        '& p:first-child': {
            width: '32%',
            marginRight: '20px',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '12px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
        },
    },
    itemInvoicetableBox: {
        marginRight: '29px',
        '& table': {
            position: 'relative'
        },
        '& table::after': {
            content: '""',
            border: '1px solid #bebebe',
            borderRadius: '10px',
            position: 'absolute',
            top: '56px',
            left: '-7px',
            width: '101%',
            height: '92%',
            zIndex: 9
        },
        '& .MuiPagination-root': {
            marginTop: '5%',
            background: '#fff',
            boxShadow: '0 0 7px #e3d3d3',
            padding: '15px 20px',
            display: 'inline-block',
            marginLeft: '-3px'
        }
    },
    editinvoiceButton: {
        background: '#05052b',
        color: '#fff',
        margin: '0 0 30px auto',
        display: 'block',
        padding: '15px',
        fontSize: '16px',
        textTransform: 'inherit' as 'inherit',
        "&:hover": {
            background: '#05052b',
            color: '#fff',
        },
        '& img': {
            filter: 'invert()',
            marginRight: '8px',
            width: '17px',
            height: '17px',
            fill: '#05052b',
        }
    },
    goBackInvoiceButton: {
        textTransform: 'inherit' as 'inherit',
        marginTop: '30px',
        border: '1px solid #000',
        display: 'block',
        background: 'transparent',
        color: '#000',
        padding: '15px 60px',
        fontSize: '16px',
        "&:hover": {
            background: 'transparent',
            color: '#000',
        },
    },
    invoiceUnitContent:{
        textTransform: 'capitalize' as 'capitalize'
    }
})

export { InvoiceScanResult }
export default withStyles(InvoiceScanResultStyle)(InvoiceScanResult)


// Customizable Area End
