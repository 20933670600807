// Customizable Area Start
import React from "react";
import VerifyReportController, { Props, TableSegmentDataProps, configJSON } from './VerifyReportController.web';
import {
    Box,
    Grid,
    Typography, TextField, TableContainer, Table, TableHead, TableRow, Tabs, Tab, TableCell, TableBody, Button
} from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import moment from "moment";
import { RateSheetDetail } from "../../MatchAlgorithm/src/RateSheetDetail.web";
// Customizable Area Start
export const theme = createTheme({});

export const TableData = (props: any) => {
    const { classes, quotestate, quoteRowsPerPage, quotePage, subTotalData, handleChangePage, segmentName, isreportPage,handleEditChangePage } = props;
    const begin = (quotePage - 1) * quoteRowsPerPage;
    const end = begin + quoteRowsPerPage;
    const totalData = Math.ceil(quotestate.length / quoteRowsPerPage)
    return (<>
    {quotestate[0]?.hasOwnProperty('data')? 
    <>
            {quotestate && quotestate.map((getcategory:{pagination:{page:number,rowsPerPage:number},category_name:number,subtotalValue:number,data:[{description:string,ord_quantity:string,unit:string,price:string,amount:string,taxSwitch: boolean; }]}, columnId:number) =>{
               return <>
               {!!getcategory.category_name &&<Box style={{ margin: "10px 0 10px 6px", display: "flex", alignItems: "baseline", gap: "10px" }}>
                        <Typography className={classes.quoteInvoiceDetailTitle}>
                            Segment/Category
                        </Typography>
                        <TextField variant="outlined" style={{ width: "20%", textOverflow: "ellipsis" }} className={classes.reportTextBox}
                         value={getcategory.category_name} InputProps={{ readOnly: true }} disabled />
                </Box>}

                <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Sr. No</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Order Qty.</TableCell>
                        <TableCell>Unit</TableCell>
                        <TableCell>Unit Price</TableCell>
                        {getcategory?.data?.every((item:{taxSwitch: boolean}) => item.taxSwitch) && <TableCell>Tax (%)</TableCell>}
                        <TableCell>Ext. Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={isreportPage ? classes.reportTableBodyBox : `${classes.quoteTableBodyBox}`}>
                    {
                        getcategory.data && getcategory.data.slice((getcategory?.pagination?.page - 1) * getcategory?.pagination?.rowsPerPage, ((getcategory?.pagination?.page - 1) * getcategory?.pagination?.rowsPerPage)+getcategory?.pagination?.rowsPerPage).map((quote:{description:string,ord_quantity:string,unit:string,price:string,amount:string,taxSwitch:boolean,tax?:number}, index: number) => {
                            
                            return (
                                <TableRow>
                                    <TableCell className={classes.indexBox} > {((getcategory?.pagination?.page - 1) * getcategory?.pagination?.rowsPerPage) + index + 1} </TableCell>
                                    <TableCell style={{fontSize:'auto'}} className={classes.editQuoteInput}>{quote.description}</TableCell>
                                    <TableCell  className={classes.editunitQuoteContent} > {quote.ord_quantity} </TableCell>
                                    <TableCell className={classes.editunitQuoteContent}>{quote.unit}</TableCell>
                                    <TableCell>$ {quote.price}</TableCell>
                                    {quote?.taxSwitch && <TableCell>{quote.tax === null ? 0 : quote.tax }%</TableCell>}
                                    <TableCell>$ {quote.amount}</TableCell>
                                </TableRow>
                            )})
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <Box style={{ marginTop: "5%" }}>
            <Pagination
                variant="outlined"
                count={Math.ceil(getcategory?.data.length / quoteRowsPerPage)}
                shape="rounded"
                data-testid="paginationTestId"
                page={quotestate[columnId].pagination.page}                
                onChange={(event, page) => handleEditChangePage(event, page, columnId)}
            
            />
        </Box>

        <Box className={classes.subTotalQuoteBox}>
            <Typography style={{fontSize:"14px"}} className={classes.titleStyle}>{segmentName ? "Segment/Category Total" : "Subtotal"}</Typography>&nbsp;&nbsp;
            <TextField variant="outlined" style={{ width: '20%' }} className={ classes.textField}
                value={`$ ${getcategory.subtotalValue.toFixed(2)}`} InputProps={{ readOnly: true }} disabled />
        </Box>

                </>
             })}

    </>
    :
    <>
        {segmentName && <Box style={{ margin: "10px 0 10px 6px", display: "flex", alignItems: "baseline", gap: "10px" }}>
            <Typography className={isreportPage ? classes.quoteInvoiceDetailTitle : ""}>
                Segment/Category
            </Typography>
            <TextField variant="outlined" style={{ width: "20%", textOverflow: "ellipsis" }} className={isreportPage ? classes.reportTextBox : classes.textField}
                value={segmentName} InputProps={{ readOnly: true }} disabled />
        </Box>}

        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Sr. No</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Order Qty.</TableCell>
                        <TableCell>Unit</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell>Ext. Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={isreportPage ? classes.reportTableBodyBox : `${classes.quoteTableBodyBox}`}>
                    {
                        quotestate.slice(begin, end).map((quote: any, index: number) => {
                            return (

                                <TableRow>
                                    <TableCell className={classes.indexBox}>{begin + index + 1}</TableCell>
                                    <TableCell className={classes.editQuoteInput}>{quote.description}</TableCell>
                                    <TableCell className={classes.editunitQuoteContent}>{quote.ord_quantity}</TableCell>
                                    <TableCell className={classes.editunitQuoteContent}>{quote.unit}</TableCell>
                                    <TableCell>$ {quote.price}</TableCell>
                                    <TableCell>$ {quote.amount}</TableCell>
                                </TableRow>
                            )
                        })
                    }

                </TableBody>
            </Table>
        </TableContainer>


        <Box style={{ marginTop: "5%" }}>
            <Pagination
                count={totalData}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
                page={quotePage}
                data-testid="paginationTestId"
            />
        </Box>

        <Box className={isreportPage ? classes.reportSubtotalBox : classes.subTotalQuoteBox}>
            <Typography className={classes.titleStyle}>{segmentName ? "Segment/Category Total" : "Subtotal"}</Typography>&nbsp;&nbsp;
            <TextField variant="outlined" style={{ width: '20%' }} className={isreportPage ? classes.reportTextBox : classes.textField}
                value={`$ ${subTotalData}`} InputProps={{ readOnly: true }} disabled />
        </Box>

    </>}    
    </>)
}

export const TableSegmentData = (props: TableSegmentDataProps) => {
    const { classes, quotestate, handleCategoryDataPageChange, segmentName, isreportPage } = props;

    return (<>

        {
            quotestate.map((categoryVal: any, columnId: number) => {
                const { page, rowsPerPage } = categoryVal.pagination;
                const begin = (page - 1) * rowsPerPage;
                const end = Math.min(begin + rowsPerPage, categoryVal.data.length);
                return (
                    <>
                        <TableContainer key={categoryVal}>
                            {categoryVal.category_name &&
                                <Box style={{ margin: "10px 0 10px 6px", display: "flex", alignItems: "baseline", gap: "10px" }}>
                                    <Typography className={isreportPage ? classes.quoteInvoiceDetailTitle : ""}>
                                        {configJSON.SegmentContent}
                                    </Typography>
                                    <TextField variant="outlined" style={{ width: "20%", textOverflow: "ellipsis" }} className={isreportPage ? classes.reportTextBox : classes.textField}
                                        value={categoryVal.category_name} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            }
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sr. No</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Order Qty.</TableCell>
                                        <TableCell>Unit</TableCell>
                                        <TableCell>Unit Price</TableCell>
                                        {categoryVal?.data?.every((item:{taxSwitch: boolean}) => item.taxSwitch) && <TableCell>Tax (%)</TableCell>}
                                        <TableCell>Ext. Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={isreportPage ? classes.reportTableBodyBox : `${classes.quoteTableBodyBox}`}>
                                    {
                                        categoryVal.data.slice(begin, end).map((quoteVal: any, index: number) => {
                                            return (
                                                <TableRow key={quoteVal.id}>
                                                    <TableCell className={classes.indexBox}>{begin + index + 1}</TableCell>
                                                    <TableCell className={classes.editQuoteInput}>{quoteVal.description}</TableCell>
                                                    <TableCell className={classes.editunitQuoteContent}>{quoteVal.ord_quantity}</TableCell>
                                                    <TableCell className={classes.editunitQuoteContent}>{quoteVal.unit}</TableCell>
                                                    <TableCell>$ {quoteVal.price}</TableCell>
                                                    {quoteVal?.taxSwitch && <TableCell>{quoteVal.tax === null ? 0 : quoteVal.tax }%</TableCell>}
                                                    <TableCell>$ {quoteVal.amount}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>
                        <Box style={{ marginTop: "5%" }}>
                            <Pagination
                                count={Math.ceil(categoryVal.data.length / categoryVal.pagination.rowsPerPage)}
                                variant="outlined"
                                shape="rounded"
                                page={categoryVal.pagination.page}
                                data-testid={`editpaginationTestId-${columnId}`}
                                onChange={(event, page) => handleCategoryDataPageChange(event, page, columnId)}
                            />
                        </Box>
                        <Box className={isreportPage ? classes.reportSubtotalBox : classes.subTotalQuoteBox}>
                            <Typography className={classes.titleStyle}>{(categoryVal.category_name || segmentName) ? "Segment/Category Total" : "Subtotal"}</Typography>&nbsp;&nbsp;
                            <TextField variant="outlined" style={{ width: '300px' }} className={isreportPage ? classes.reportTextBox : classes.textField}
                                value={`$ ${categoryVal.subtotalValue.toFixed(2)}`} InputProps={{ readOnly: true }} disabled />
                        </Box>
                    </>
                )

            })
        }

    </>)
}
// Customizable Area End

export class VerifyReport extends VerifyReportController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start

        // Customizable Area End
    }
    render() {
        const { classes, handleSaveData,editQuoteDataSaved,editQuoteRequiredData } = this.props
        const { quotedetail, quoteDragDataPage, quoteDragDataRowsPerPage } = this.state
        return (
            <>
                {/* <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} /> */}

                {/* <HorizontalTabMenu modalOpen={false} navigation={undefined} tabIndexValue={1} handleSaveData={this.props.handleSaveData} history={undefined} /> */}
                <Box className={classes.quoteBox}>

                    <Typography variant="h5" className={classes.viewquoteHeading}>View Quote</Typography>

                    <Box className={classes.quoteDetailsBox}>
                        <Grid container spacing={2}>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" className={classes.quotedetailsText}>Quote details</Typography>
                                    <Typography variant="body2" className={classes.titleStyle}>Quote Number</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.quote_id} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >Quote Date</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.date ? moment(quotedetail.date).format("MM-DD-YYYY") : "Unknown"} InputProps={{ readOnly: true, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle}>Quote Expiry Date</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.expiration_date ? moment(quotedetail.expiration_date).format("MM-DD-YYYY") : "Unknown"} InputProps={{ readOnly: true, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" className={classes.quotedetailsText}>Supplier details</Typography>
                                    <Typography variant="body2" className={classes.titleStyle}>Supplier Name</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.project} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle}>Supplier Address</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.supplier_address} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >Sales Person</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.supplier_salesperson} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >Supplier Contact</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.supplier_contact} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" className={classes.quotedetailsText}>Ship to (Project Details)</Typography>
                                    <Typography variant="body2" className={classes.titleStyle} >Site Name</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.site_name} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >Site Address</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.site_address} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >Contact Person</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.site_contactperson} InputProps={{ readOnly: true }} disabled />
                                    <Typography variant="body2" className={classes.titleStyle} >Site Contact</Typography>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={quotedetail.site_contact} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box style={{ position: 'relative' }}>
                        <Typography variant="h5" className={classes.itemDetailsText}>Item details</Typography>
                        {
                            this.props.checkedBox === true ?
                                <RateSheetDetail
                                    navigation={undefined}
                                    id={1}
                                    classes={classes}
                                    history={this.props.history}
                                    location={this.props.location}
                                    handleSaveData={handleSaveData}
                                    onContinueButtonClick={() => {}}
                                    goBackButtonNavigation={() => {}}
                                    segmentName1={'segmentName1'}
                                    segmentTotal1={0}
                                    segmentName2={'segmentTotal1'}
                                    segmentTotal2={0}
                                    quoteCategoryDescription={this.props.quoteCategoryDescription}
                                    quoteData={{ quoteDetails: this.state.quotedetail, quoteDescription: this.state.quotestate }}
                                /> :
                                <>
                                    {
                                        this.props.quoteCategoryDescription.length > 0 ?
                                            <TableSegmentData data-testid="tableDataTestId" handleCategoryDataPageChange={this.handleCategoryDataPageChange} isreportPage={false} classes={classes} quotestate={this.props.quoteCategoryDescription} segmentName={this.props.segmentName1} />
                                            : <TableData data-testid="tableDragDataTestId" isreportPage={false} handleChangePage={this.handleChangePage} classes={classes} segmentName={this.props.segmentName2} quotestate={this.state.quotestate} quoteRowsPerPage={quoteDragDataRowsPerPage} quotePage={quoteDragDataPage} subTotalData={this.state.quotedetail.subtotal} />
                                    }

                                </>
                        }

                    </Box>
                    <Box className={classes.quoteTotalAmountBox}>
                        <Box>
                            <Box className={classes.subTotalContentBox}>
                                <Typography className={classes.titleStyle}>Subtotal/Gross Amount</Typography>
                                {
                                    quotedetail.gross_subtotal == null ?
                                        <TextField variant="outlined" className={classes.textField}
                                            value={`$ ${quotedetail.subtotal}`} InputProps={{ readOnly: true }} disabled /> :
                                        <TextField variant="outlined" className={classes.textField}
                                            value={`$ ${quotedetail.gross_subtotal}`} InputProps={{ readOnly: true }} disabled />
                                }

                            </Box>
                            <Box className={classes.subTotalContentBox}>
                                <Typography className={classes.titleStyle}>S&H Charges</Typography>
                                <TextField variant="outlined" className={classes.textField}
                                    value={`$ ${quotedetail.s_and_h_charges}`} InputProps={{ readOnly: true }} disabled />
                            </Box>
                            <Box className={classes.subTotalContentBox}>
                                <Typography className={classes.titleStyle}>Other Charges</Typography>
                                <TextField variant="outlined" className={classes.textField}
                                    value={`$ ${quotedetail.other_charges}`} InputProps={{ readOnly: true }} disabled />
                            </Box>
                            <Box className={classes.subTotalContentBox}>
                                <Typography className={classes.titleStyle}>- Discount</Typography>
                                <TextField variant="outlined" className={classes.textField}
                                    value={`$ ${quotedetail.discount}`} InputProps={{ readOnly: true }} disabled />
                            </Box>
                            <Box className={classes.taxQuoteContent}>
                                <Box>
                                    <Typography className={classes.titleStyle}>Tax</Typography>
                                </Box>
                                <Box className={classes.taxQuoteInputBox}>
                                    <TextField variant="outlined" className={classes.textField}
                                        value={editQuoteDataSaved ? `${editQuoteRequiredData[0]?.tax_rate_amount}%`:`${quotedetail.tax_rate_amount}%`} InputProps={{ readOnly: true }} disabled />
                                    <TextField variant="outlined" className={classes.textField}
                                        value={editQuoteDataSaved ? `$ ${editQuoteRequiredData[0]?.tax}`:`$ ${quotedetail.tax}`} InputProps={{ readOnly: true }} disabled />
                                </Box>
                            </Box>
                            <Box className={classes.subTotalContentBox}>
                                <Typography className={classes.totalStyle}>Total</Typography>
                                <TextField variant="outlined" style={{ width: "100px!important" }} className={classes.textField}
                                    value={editQuoteDataSaved ? `$ ${editQuoteRequiredData[0]?.total_price}`:`$ ${quotedetail.total_price}`} InputProps={{ readOnly: true }} disabled />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.buttonBox}>
                    <Button variant="outlined" style={{ textTransform: "none" }} data-testid="goBackTestid" onClick={() => { this.props.goBackButtonNavigation("", 0) }} className={classes.goBackButton}>Go back</Button>
                    <Button variant="outlined" style={{ textTransform: "none" }} data-testid="continueTestid" className={classes.continueButton} onClick={() => { this.props.onContinueButtonClick("", 2) }}>Continue</Button>
                </Box>
            </>
        )
    }
}

export const VerifyQuoteStyle = () => ({

})

export default withStyles(VerifyQuoteStyle)(VerifyReport)
