// Customizable Area Start
import React, { useEffect } from "react";
import {
    MenuItem,
    Select,
    Box,
    Typography,
    OutlinedInput,
    Button,
    Tabs,
    Tab,
    TextField,
    InputLabel,
    DialogTitle,
    Dialog,
    DialogContent,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    CircularProgress
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { Props, TabPanelProps } from "./InvoiceUploadController.web";
import { createTheme, withStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import { uploadDocument, deleteIcon } from "./assets";
import Header from "../../landingpage/src/Header.web";
import CreateReportController, {
    AddNameDialogProps,
    ReportDetailsProps,
    RateSheetDropboxprops,
    GetRootProps,
    GetInputProps,
    InvoiceurlBoxProps,
    configJSON
} from "./CreateReportController.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactFullscreen from 'react-easyfullscreen';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { VerifyReport } from "./VerifyReport.web";
import { ReportBreadCrumb, ReportResults } from "./ReportResults.web";
import { VerifyInvoice } from "./VerifyInvoice.web";
import { RateSheetDetail } from "../../MatchAlgorithm/src/RateSheetDetail.web";
// Customizable Area End


// Customizable Area Start
export const theme = createTheme({});
// Customizable Area End

//Customizable Area Start

const StyledAddButton = styled(Button)({
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "#df802d",
    border: "1px solid #df802d",
    color: "white",
    '&:hover': {
        backgroundColor: '#df802d',
        color: '#white',
    },
});

const StyledBrowseButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "8px",
        border: "1px solid #df802d",
        color: "#df802d",
        textTransform: "uppercase",
        fontWeight: 500,
        fontSize: "18px",
        width: '50%'
    }
});

const StyledCancelButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #292946",
        color: "#292946",
        width: '50%',
        textTransform: 'capitalize',
        height: "42px"
    }
})

const StyledSaveButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#fff",
        background: "#292946",
        width: '50%',
        textTransform: 'capitalize',
        height: "42px"
    }
})

const StyledContinueButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#fff",
        background: "#292946",
        width: '240px',
        textTransform: 'capitalize',
        padding: '10px 0px'
    }
})

const StyledNoQuoteContinueButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#fff",
        background: "#292946",
        width: '160px',
        padding: '10px',
        textTransform: 'capitalize'
    }
})

const StyledNoQuoteCancelButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#292946",
        width: '160px',
        padding: '10px',
        textTransform: 'initial'
    }
})

const StyledOutlinedButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #292946",
        color: "#292946",
        textTransform: 'capitalize',
        width: '240px',
        padding: '10px 0px'
    }
})

const StyledNoDispencaryButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #292946",
        color: "#292946",
        textTransform: 'capitalize',
        width: '240px',
        display: 'block',
        padding: '10px 0px'
    }
})

const InvoiceUploadErrTextBox = styled(Box)({
    display:"flex",
    justifyContent:"flex-start !important",
    width:"340px"
})

const InvoiceUploadErrText = styled("span")({
fontSize:"1rem",
fontWeight:400,
lineHeight:1.5,
color:"red",
textAlign:"left",
flex:"0 0 auto !important",
marginTop:"6px"
})

export const ReportTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ padding: '24px 0px' }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const AddNameDialog = (props: AddNameDialogProps) => {
    const { classes, title, type, handleClose, handleOpen, handleInputChange, inputName, addVendor, addProject, addNameErrMsg,inputEmail,addEmailErrMsg} = props ;

    return (
        <>
            <CreateReportDialog data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle className="createReportTitleStyle">{title}</DialogTitle>
                <DialogContent>
                    <Box className="mainContainerStyle">
                        <InputLabel className="venderNameLabel">{`${type} name`}</InputLabel>
                        <TextField autoComplete="off" fullWidth error={addNameErrMsg != ""} helperText={addNameErrMsg} value={inputName} onChange={handleInputChange} className={classes?.helperTextStyle} id="outlined-basic" variant="outlined" placeholder={`Enter ${type} name`}  name='inputName'/>
                    </Box>
                    {type === "vendor" &&
                      <Box className="vendorTypeContainer">
                      <InputLabel className="vedorEmailLabel">{`${type} email`}</InputLabel>
                      <TextField autoComplete="off" fullWidth  value={inputEmail} error={addEmailErrMsg != ""} helperText={addEmailErrMsg} onChange={handleInputChange} className={classes?.helperTextStyle} style={{marginTop:"5px",marginLeft:"0px",padding:"0px"}} id="outlined-basic" variant="outlined" placeholder={`Enter ${type} email`} name="inputEmail"/>
                      </Box>
                    }
                    <Box className={classes?.buttonBoxStyle}>
                        <StyledCancelButton variant='outlined' onClick={handleClose}>Cancel</StyledCancelButton>
                        <StyledSaveButton variant='contained' onClick={type === 'vendor' ? addVendor : addProject}>Save</StyledSaveButton>
                    </Box>
                </DialogContent>
            </CreateReportDialog>
        </>
    )
}

export const QuoteDropbox = (props: any) => {
    const { classes, quoteUploadValidateMessage, acceptedFile, onDrop, uploadAcceptedFile, pdfUrl, handleDelete, tabValue, isQuoteUploadenableorDisable } = props;

    return (
        <Box className={tabValue === 4 ? classes.uploadInvoiceBoxStyle2 : classes.uploadInvoiceBoxStyle}>
            <Typography style={{ display: tabValue === 4 ? "none" : "block" }} className={classes?.boxTitle}>
                Upload Quote
            </Typography>
            <div className={tabValue === 4 ? classes.dialogWrapperStyle2 : classes.dialogWrapperStyle}>
                {uploadAcceptedFile?.length > 0 && pdfUrl !== '' ?
                    <>
                        {uploadAcceptedFile?.map((file: any, index: any) => (
                            <Box style={{ display: 'flex', alignItems: 'center', gap: '2%' }}>
                                <div key={index} className={classes.dropZoneUploadedFileStyle}>
                                    <Typography style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>Uploaded <span style={{ color: "orange" }}>{file.name}</span></Typography>
                                    <div><CheckIcon style={{ color: 'green' }} /></div>
                                </div>
                                <img style={{ cursor: 'pointer', width: '20px' }} onClick={handleDelete} src={deleteIcon} alt="" />
                            </Box>
                        ))
                        }
                    </>
                    :
                    <Dropzone maxFiles={1} disabled={isQuoteUploadenableorDisable} accept={acceptedFile} data-testid="dropzone" onDrop={onDrop}>
                        {({ getRootProps, getInputProps }: any) => (
                            <div className={classes.dropZoneReportStyle} {...getRootProps()}>
                                <img src={uploadDocument} alt="" />
                                <div className={classes.dialogReportDropZoneText}>
                                    <span>Drag & drop PDF</span>
                                    <br />
                                    <span>OR</span>
                                </div>
                                <input id="input" {...getInputProps()} />
                                <StyledBrowseButton disabled={isQuoteUploadenableorDisable} data-testid="browseButton">
                                    Browse
                                </StyledBrowseButton>
                                <FormHelperText style={{ marginTop: "10px" }} error={isQuoteUploadenableorDisable}>{quoteUploadValidateMessage}</FormHelperText>
                            </div>
                        )}
                    </Dropzone>
                }
            </div>
        </Box >
    )
}

export const InvoiceDropbox = (props: any) => {
    const { classes, acceptedFile, onDrop, uploadAcceptedFile, pdfUrl, handleDelete, tabValue,isInvoiceUploadenableorDisable,invoiceUploadValidateMessage } = props;
    
    return (
        <Box className={tabValue === 4 ? classes.uploadInvoiceBoxStyle2 : classes.uploadInvoiceBoxStyle}>
            <Typography style={{ display: tabValue === 4 ? "none" : "block" }} className={classes?.boxTitle}>
                Upload invoice
            </Typography>
            <div className={tabValue === 4 ? classes.dialogWrapperStyle2 : classes.dialogWrapperStyle}>
                {pdfUrl !== '' &&
                    <>
                        {uploadAcceptedFile?.slice(0,8)?.map((file: any, index: any) => (
                            <Box style={{ display: 'flex', alignItems: 'center', gap: '2%', marginTop: '4%' }}>
                                <div key={index} className={classes?.dropZoneUploadedFileStyle}>
                                    <Typography style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>Uploaded <span style={{ color: "orange" }}>{file.name}</span></Typography>
                                    <div><CheckIcon style={{ color: 'green' }} /></div>
                                </div>
                                <img data-testid={`deleteIconId${index}`} style={{ cursor: 'pointer', width: '20px' }} onClick={() => handleDelete(index)} src={deleteIcon} alt="" />
                            </Box>
                        ))
                        }
                    </>
                }
               {uploadAcceptedFile?.length<=7 &&  <Dropzone accept={acceptedFile} disabled={isInvoiceUploadenableorDisable} data-testid="dropzone" onDrop={onDrop}>
                    {({ getRootProps, getInputProps }: any) => (
                        <div className={classes?.dropZoneReportStyle} {...getRootProps()}>
                            <img src={uploadDocument} alt="" />
                            <div className={classes?.dialogReportDropZoneText}>
                                <span>Drag & drop PDF</span>
                                <br />
                                <span>OR</span>
                            </div>
                            <input id="input" {...getInputProps()} />
                            <StyledBrowseButton disabled={isInvoiceUploadenableorDisable} data-testid="browseButton">
                                Browse
                            </StyledBrowseButton>
                            <FormHelperText style={{ marginTop: "10px" }} error={isInvoiceUploadenableorDisable}>{invoiceUploadValidateMessage}</FormHelperText>
                        </div>
                    )}
                </Dropzone>}
                {uploadAcceptedFile?.length===8 && <InvoiceUploadErrTextBox><InvoiceUploadErrText>A max of 8 PDFs can be uploaded</InvoiceUploadErrText></InvoiceUploadErrTextBox>}
            </div>
        </Box >
    )
}



export const RateSheetDropbox = (props: RateSheetDropboxprops) => {
    const { classes, acceptedFile, onDrop, uploadAcceptedFile, pdfUrl, handleDelete, tabValue } = props;
    return (
        <Box className={tabValue === 4 ? classes.uploadInvoiceBoxStyle2 : classes.uploadInvoiceBoxStyle}>
            <Typography style={{ display: tabValue === 4 ? "none" : "block" }} className={classes?.boxTitle}>
                {configJSON.uploadQuoteTitle}
            </Typography>
            <Box className={tabValue === 4 ? classes.dialogWrapperStyle2 : classes.dialogWrapperStyle}>
                {uploadAcceptedFile?.length > 0 && pdfUrl !== '' ?
                    <>
                        {uploadAcceptedFile?.map((file, index) => (
                            <Box className={classes.uploadPdfBox} key={index}>
                                <Box className={classes.dropZoneUploadedFileStyle}>
                                    <Typography>Uploaded <span>{file.name}</span></Typography>
                                    <Box><CheckIcon style={{ color: 'green' }} /></Box>
                                </Box>
                                <img onClick={handleDelete} src={deleteIcon} alt="" />
                            </Box>
                        ))}
                    </>
                    :
                    <Dropzone maxFiles={1} accept={acceptedFile} data-testid="dropzonetestid_1" onDrop={onDrop}>
                        {({ getRootProps, getInputProps }: { getRootProps: () => GetRootProps, getInputProps: () => GetInputProps }) => (
                            <Box className={classes.dropZoneReportStyle} {...getRootProps()}>
                                <img src={uploadDocument} alt="" />
                                <Box className={classes.dialogReportDropZoneText}>
                                    <span>{configJSON.DragAndDropTitle}</span>
                                    <br />
                                    <span>{configJSON.orTitle}</span>
                                </Box>
                                <input id="input" {...getInputProps()} />
                                <StyledBrowseButton data-testid="browsePdfButton">
                                    {configJSON.browseTitle}
                                </StyledBrowseButton>
                            </Box>
                        )}
                    </Dropzone>
                }
            </Box>
        </Box >
    )
}

export const PreviewPdf = (props: any) => {
    const { classes, pdfUrl, isLoading } = props
    return (
        <Box className={classes?.previewBoxStyle}>
            <Typography className={classes?.boxTitleFontStyle}>Preview</Typography>
            {isLoading ? <CircularProgress style={{ position: 'absolute', left: '50%', marginTop: '2%', top: '50%' }} /> :
                <>
                    {pdfUrl === '' ?
                        <Typography className={classes?.noPreviewStyle}>No preview available</Typography>
                        : <ReactFullscreen data-testid='fullScreen'>
                            {({ ref, onRequest }: any) => (
                                <Box>
                                    <iframe src={pdfUrl} className={classes?.notifydocumentsPdfBoxStyle} ref={ref}></iframe>
                                </Box>
                            )}
                        </ReactFullscreen>
                    }
                </>
            }
        </Box>
    )
}

export const pdfProps = (tabIndex: any) => {
    return {
        id: `simple-tab-${tabIndex}`,
        'aria-controls': `simple-tabpanel-${tabIndex}`,
    };
}

export const InvoicePreviewPdf = (props: any) => {
    const { classes, pdfUrl, invoiceValue, handleTabChange, invoiceTabs, isLoading } = props
    return (
        <Box className={classes?.previewBoxStyle}>
            <Typography className={classes?.boxTitleFontStyle}>Preview</Typography>
            {isLoading ? <CircularProgress style={{ position: 'absolute', left: '50%', marginTop: '2%', top: '50%' }} /> :
                <>
                    {pdfUrl === '' ?
                        <Typography className={classes?.noPreviewStyle}>No preview available</Typography>
                        :
                        <Box style={{ marginTop: '5%' }}>
                            <Tabs
                                value={invoiceValue}
                                onChange={handleTabChange}
                                className={classes?.pdfInnerBox3}
                                data-testid="tabChangeid"
                                variant="scrollable"
                                scrollButtons="off"
                            >

                                {
                                    invoiceTabs.map((obj: any) => {
                                        return (
                                            <Tab
                                                label={
                                                    <>
                                                        <Box className={classes?.pdfcurveBox1}>
                                                            <Box className={classes?.pdfcurveInnerBox1}></Box>
                                                        </Box>
                                                        <Typography variant="subtitle2" style={{ textTransform: "none", overflow: "hidden", textOverflow: "ellipsis", width: '100%', padding: '3%' }}>{obj.name}</Typography>
                                                        <Box className={classes?.pdfcurveRightBox1}>
                                                            <Box className={classes?.curveRightPdfInnerBox1}></Box>
                                                        </Box>
                                                    </>
                                                }
                                                {...pdfProps(0)}
                                                className={classes?.pdfTitleBox2}
                                            />
                                        )
                                    })
                                }

                            </Tabs>
                            <Box className={classes?.previewInvoicePdfInnerBox}>
                                <iframe src={pdfUrl} className={classes?.pdfView}></iframe>
                            </Box>
                        </Box>
                    }
                </>}
        </Box>
    )
}

export const UploadLaterButtonBoxes = (props: any) => {
    const { handleBack,uploadAcceptedInvoiceFile,quoteUrl,handleChange,handleOpenUploadLaterModal } = props
    return (
        <Box style={{ display: 'flex', gap: '20px', width: '100%' }}>
                                <StyledOutlinedButton onClick={handleBack} data-testid="cancelInvoiceButtonId" variant='outlined'>Go back</StyledOutlinedButton>
                                {uploadAcceptedInvoiceFile?.length === 0 && <StyledNoDispencaryButton onClick={quoteUrl ? () => { handleChange("", 4) } : handleOpenUploadLaterModal} variant='outlined'>
                                    Upload later
                                </StyledNoDispencaryButton>}
                            </Box>
    )
}

export const NoQuoteUploadDialog = (props: any) => {
    const { classes, handleClose, handleOpen, handleContinueForInvoiceUpload } = props
    return (
        <>
            <Dialog style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black', textAlign: 'center' }}>
                    <ErrorOutlineOutlinedIcon style={{ margin: '-1% 3%' }} htmlColor="red" /> No quote uploaded
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography style={{ fontWeight: 550 }}>Are you sure you want to continue without a quote?</Typography>
                    <Typography style={{ color: 'gray', fontSize: '13px', fontWeight: 500, padding: '1% 0px' }}>The report will be considered as $0 discrepancy between the quote and invoice.</Typography>
                    <Box className={classes?.noQuoteButtonBoxStyle}>
                        <StyledNoQuoteContinueButton onClick={handleContinueForInvoiceUpload} variant='contained'>Continue</StyledNoQuoteContinueButton>
                        <StyledNoQuoteCancelButton variant='outlined' onClick={handleClose}>Upload a quote</StyledNoQuoteCancelButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const UploadLaterDialog = (props: any) => {
    const { classes, handleClose, handleOpen, handleUploadQuote } = props
    return (
        <>
            <Dialog style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black', textAlign: 'center' }}>
                    <ErrorOutlineOutlinedIcon style={{ margin: '-1% 3%' }} htmlColor="red" /> No documents uploaded
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography style={{ fontWeight: 550 }}>A report must contain one quote or one or more invoices.</Typography>
                    <Typography style={{ color: 'gray', fontSize: '13px', fontWeight: 500, padding: '1% 0px' }}>Please upload a document to create this report.</Typography>
                    <Box className={classes?.noQuoteButtonBoxStyle}>
                        <StyledNoQuoteContinueButton onClick={handleUploadQuote} variant='contained' style={{ textTransform: "initial" }}>Upload a quote</StyledNoQuoteContinueButton>
                        <StyledNoQuoteCancelButton variant='outlined' onClick={handleClose} style={{ textTransform: "initial" }}>Upload an invoice</StyledNoQuoteCancelButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const CancelDialog = (props: any) => {
    const { classes, handleClose, handleOpen, handleCancel } = props
    return (
        <>
            <Dialog style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black', textAlign: 'center' }}>
                    Are you sure you want to exit without saving?
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Box className={classes?.noQuoteButtonBoxStyle}>
                        <StyledNoQuoteContinueButton onClick={handleCancel} variant='contained'>Yes</StyledNoQuoteContinueButton>
                        <StyledNoQuoteCancelButton variant='outlined' onClick={handleClose}>No</StyledNoQuoteCancelButton>
                    </Box>

                </DialogContent>
            </Dialog>
        </>
    )
}

export const ReportDetails = (props: ReportDetailsProps) => {
    const { classes, reportNameErrMsg, checkedBox, handleCheckboxChange,handleVendorChange, vendorValue, vendorItems, handleProjectChange, projectValue, projectItems, handleReportValueChange, handleOpenVendorDialog, handleOpenProjectDialog, vendorErrMsg, projectErrMsg ,reportsName  } = props
    
    return (
        <Box className={classes?.reportDetailsBoxStyle}>
            <Typography className={classes?.boxTitleFontStyle} style={{ fontSize: '18px' }}>Report Details</Typography>
            <Box className={classes?.textFieldBoxStyle}>
                <Box style={{ width: '50%' }}>
                    <InputLabel className={classes?.inputLableStyle}>Report Name</InputLabel>
                    <TextField error={reportNameErrMsg.length > 0} helperText={reportNameErrMsg} className={classes?.helperTextStyle} onChange={handleReportValueChange} value={reportsName} style={{ marginTop: '2%', width: '80%' }} id="outlined-basic" variant="outlined" placeholder="Please enter name" autoComplete="off" />
                </Box>
                <Box style={{ width: '50%', paddingLeft: '4%' }}>
                    <InputLabel className={classes?.inputLableStyle} variant="standard" htmlFor="uncontrolled-native">
                        Vendor
                    </InputLabel>
                    <Select
                        error={vendorErrMsg?.length > 0}
                        style={{ width: '80%', marginTop: '2%' }}
                        labelId="demo-simple-select-disabled-label"
                        id="demo-simple-select-disabled"
                        input={<OutlinedInput />}
                        displayEmpty
                        onChange={handleVendorChange}
                        placeholder="Please select vendor"
                        value={vendorValue?vendorValue:""}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        IconComponent={ExpandMoreIcon}
                        data-testid={"projectTestID"}
                    >
                        <MenuItem style={{ background: 'white', color: 'gray' }} disabled value="">
                            <em style={{ background: 'white', color: 'gray', fontStyle: 'initial' }}>Please select vendor</em>
                        </MenuItem>
                        {vendorItems?.map((item: any, index: any) => {
                            return (
                                <MenuItem key={index} style={{ background: 'white', color: 'black', maxWidth: '300px' }} data-testid="menuQuotesPlaceholderId" value={item.id}>
                                    <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</Typography>
                                </MenuItem>
                            )
                        })}
                        <MenuItem style={{ background: 'transparent' }} onClick={handleOpenVendorDialog} data-testid="menuQuotesPlaceholderId">
                            <StyledAddButton data-testid="addVendorId">
                                Add a vendor
                            </StyledAddButton>
                        </MenuItem>
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{vendorErrMsg}</FormHelperText>
                </Box>
            </Box>
            <Box className={classes?.textFieldBoxStyle}>
                <Box style={{ width: '50%' }}>
                    <InputLabel className={classes?.inputLableStyle} variant="standard" htmlFor="uncontrolled-native">
                        Project
                    </InputLabel>
                    <Select
                        style={{ width: "80%", marginTop: '2%' }}
                        error={projectErrMsg?.length > 0}
                        labelId="demo-simple-select-disabled-label"
                        id="demo-simple-select-disabled"
                        input={<OutlinedInput />}
                        displayEmpty
                        onChange={handleProjectChange}
                        value={ projectValue }
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        IconComponent={ExpandMoreIcon}
                        data-testid={"projectTestID"}
                    >
                        <MenuItem style={{ background: 'white', color: 'gray' }} disabled value="">
                            <em style={{ background: 'white', color: 'gray', fontStyle: 'initial' }}>Please select project</em>
                        </MenuItem>
                        {projectItems?.map((item: any, index: any) => {
                            return (
                                <MenuItem key={index} style={{ background: 'white', color: 'black', maxWidth: '300px' }} data-testid="menuQuotesPlaceholderId" value={item.id}>
                                    <Typography style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.project}</Typography>
                                </MenuItem>
                            )
                        })}
                        <MenuItem onClick={handleOpenProjectDialog} style={{ background: 'transparent' }} data-testid="menuQuotesPlaceholderId">
                            <StyledAddButton data-testid="addProjectId" > Add a project</StyledAddButton>
                        </MenuItem>
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{projectErrMsg}</FormHelperText>
                </Box>
                <FormControlLabel
                    data-testid="handleCheckboxChangeId"
                    style={{ width: '50%', padding: '3% 4%' }}
                    control={<Checkbox data-testid="checkboxChangeId" checked={checkedBox} onChange={handleCheckboxChange} />}
                    label="Uploading a Rate Sheet"
                />
            </Box>
        </Box>
    )
}

export const InvoiceurlBox = (props: InvoiceurlBoxProps) => {
    const { invoiceUrl, handleChange,enableContinueButton } = props

    return (
        <>
            {invoiceUrl !== '' && enableContinueButton &&
                <StyledContinueButton variant='contained' data-testid="invoicecontinuebtnId" onClick={() => { handleChange("", 3) }}>
                    Continue
                </StyledContinueButton>
            }
        </>

    )
}


export const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
//Customizable Area End
class CreateReport extends CreateReportController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start       
        const { classes, handleSaveData, navigation,projectValue } = this.props;
        const { compareModal } = this.state;
        const uploadAcceptedFileNames: string[] = this.state.uploadAcceptedRateSheetFile;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <AddNameDialog addEmailErrMsg={this.state.addEmailErrMsg} addNameErrMsg={this.state.addNameErrMsg} classes={classes} addVendor={this.addVendors} addProject={this.addProjects} inputName={this.state.inputName} handleInputChange={this.handleInputChange} title={`Add a new ${this.state.type}`} type={this.state.type} handleClose={this.handleClose} handleOpen={this.state.openDialog} inputEmail={this.state.inputEmail}/>
                <NoQuoteUploadDialog handleContinueForInvoiceUpload={this.handleContinueForInvoiceUpload} classes={classes} handleClose={this.handleNoQuoteModalClose} handleOpen={this.state.isNoQuoteModalOpen} />
                <UploadLaterDialog handleOpen={this.state.isUploadLaterModalOpen} handleClose={this.handleCloseUploadLaterModal} handleUploadQuote={this.handleUploadQuote} classes={classes} />
                <CancelDialog classes={classes} handleClose={this.handleCancelModalClose} handleOpen={this.state.isCancelModalOpen} handleCancel={this.handleCancel} />
                <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
                <Box className={classes.editButtonBox}>
                    <ReportBreadCrumb classes={classes} />

                    {
                        this.state.value == 1 && <Button style={{ textTransform: "none" }} onClick={this.NavigateToEditQuote} data-testid="navigateToEditQuoteId" className={classes.editQuoteButton} >
                            <img src="https://icons.veryicon.com/png/o/miscellaneous/linear-small-icon/edit-246.png" className={classes.imgStyle1} height="20" width="20" alt="" />
                            Edit quote
                        </Button>
                    }
                    {
                        this.state.value == 3 && <Button style={{ textTransform: "none" }} onClick={this.NavigateToEditInvoice} data-testid="navigateEditInvoiceId" className={classes.editQuoteButton} >
                            <img src="https://icons.veryicon.com/png/o/miscellaneous/linear-small-icon/edit-246.png" className={classes.imgStyle1} height="20" width="20" alt="" />
                            Edit invoice
                        </Button>
                    }



                </Box>
                <Box className={classes.tabBoxStyle}>
                <Tabs
    orientation="horizontal"
    value={this.state.value}
    // onChange={this.handleChange}
    data-testid="handleTabChange"
    className={classes.tabsReportStyle}
>
    {
        this.state.reportTab.map((item: any) => {
            // Extract the ternary operation into a function
            const getParagraphStyle = (item: any) => {
                if (this.state.breadCrumbCreateReport && item.type === "Review Quote Details") {
                    return classes.visitedParagraphStyle;
                } else if (item.visited) {
                    return classes.visitedParagraphStyle;
                } else {
                    return classes.paragraphStyle;
                }
            };

            const paragraphStyle = getParagraphStyle(item);

            return (
                <Tab
                    label={
                        <Box className={classes.tabTextStyle}>
                            <Typography className={paragraphStyle}>
                                {("0" + item.id).slice(-2) + "."}
                            </Typography>
                            <Typography className={paragraphStyle}>
                                {item.type}
                            </Typography>
                        </Box>
                    }
                    {...a11yProps(0)}
                    className={classes.invoiceTabMenuBoxStyle}
                />
            )
        })
    }
</Tabs>

                </Box>
                <Box className={classes.reportTabBox}>
                    <ReportTabPanel value={this.state.value} index={0}>

                        <ReportDetails data-testid='ReportDetailsTestid' classes={classes} reportNameErrMsg={this.state.reportNameErrMsg} vendorErrMsg={this.state.vendorErrMsg} projectErrMsg={this.state.projectErrMsg} reportName={this.state.reportsName} handleVendorChange={this.handleVendorChange} vendorValue={this.state.vendorValue} vendorItems={this.state.vendorItems} handleOpenProjectDialog={this.handleOpenProjectDialog} handleOpenVendorDialog={this.handleOpenVendorDialog} handleProjectChange={this.handleProjectChange} projectValue={this.state.projectValue} projectItems={this.state.projectItems} handleReportValueChange={this.handleReportValueChange} checkedBox={this.state.checkedBox} handleCheckboxChange={this.handleCheckboxChange} reportsName={this.state.reportsName}/>
                        {
                            !this.state.checkedBox ? <Box className={classes.previewUploadBoxStyle}>
                                <QuoteDropbox quoteUploadValidateMessage={this.state.quoteUploadValidateMessage} tabValue={this.state.value} uploadAcceptedFile={this.state.uploadAcceptedFile} acceptedFile={this.state.acceptedFileType} classes={classes} onDrop={this.onQuoteReportDrop} data-testid="QuoteDropboxId" pdfUrl={this.state.quoteUrl} handleDelete={this.handleDelete} isQuoteUploadenableorDisable={this.state.isQuoteUploadenableorDisable} />
                                <PreviewPdf classes={classes} pdfUrl={this.state.quoteUrl} isLoading={this.state.isLoading} />
                            </Box> :

                                <Box className={classes.previewUploadBoxStyle}>
                                    <RateSheetDropbox tabValue={this.state.value} uploadAcceptedFile={uploadAcceptedFileNames} acceptedFile={this.state.acceptedFileType} classes={classes} onDrop={this.onRateSheetportDrop} data-testid="QuoteratesheetDropboxId" pdfUrl={this.state.quoteUrl} handleDelete={this.handleDelete} />
                                    <PreviewPdf classes={classes} pdfUrl={this.state.quoteUrl} isLoading={this.state.isLoading} />
                                </Box>
                        }

                        <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '5%' }}>
                            <Box style={{ display: 'flex', gap: '20px', width: '100%' }}>
                                <StyledOutlinedButton data-testid="cancelButtonId" onClick={(this.state.quoteUrl === '' && this.state.reportName === '' && this.state.projectValue === '' && this.state.vendorValue === '') ? this.handleCancel : this.handleOpenCancelModal} variant='outlined'>Cancel</StyledOutlinedButton>

                                {this.state.quoteUrl === "" &&
                                    <StyledNoDispencaryButton onClick={this.handleOpenNoQuoteModal} variant='outlined'>
                                        <Typography>No Quote</Typography>
                                        <Typography style={{ fontSize: '13px', textTransform: 'none' }}>No discrepancy will be recorded</Typography>
                                    </StyledNoDispencaryButton>
                                }
                            </Box>
                            {this.state.quoteUrl !== '' &&

                                <>
                                    <StyledContinueButton variant='contained' data-testid="continuebtnId" onClick={() => { this.handleChange("", 1) }}>
                                        Continue
                                    </StyledContinueButton>

                                    {/* <StyledContinueButton style={{ marginLeft: '20px' }} variant='contained' data-testid="continuebtnId" onClick={() => { this.handleAPIDataChange() }}>
                                        Add
                                    </StyledContinueButton> */}

                                </>
                            }

                        </Box>
                    </ReportTabPanel>

                    <ReportTabPanel value={this.state.value} index={1}>
                        <VerifyReport
                            segmentName1={this.state.segmentName1}
                            segmentTotal1={this.state.segmentTotal1}
                            segmentName2={this.state.segmentName2}
                            segmentTotal2={this.state.segmentTotal2}
                            quoteCategoryDescription={this.state.quoteCategoryDescription}
                            navigation={navigation}
                            id={""}
                            goBackButtonNavigation={this.goBackButtonNavigation}
                            onContinueButtonClick={(e: any, a: any) => { this.handleChange(e, a) }}
                            classes={classes}
                            history={history}
                            location={location}
                            quoteData={{ quoteDetails: this.state.quoteDetails, quoteDescription: this.state.quoteDescription }}
                            handleSaveData={handleSaveData}
                            checkedBox={this.state.checkedBox}
                            editQuoteDataSaved={this.state.editQuoteDataSaved}
                            editQuoteRequiredData={this.state.editQuoteRequiredData}
                        />
                    </ReportTabPanel>

                    <ReportTabPanel value={this.state.value} index={2}>
                        <ReportDetails classes={classes} data-testid={`ReportDetailsinvoiceTestid-${2}`} reportNameErrMsg={this.state.reportNameErrMsg} vendorErrMsg={this.state.vendorErrMsg} projectErrMsg={this.state.projectErrMsg} reportName={this.state.reportName} handleVendorChange={this.handleVendorChange} handleOpenProjectDialog={this.handleOpenProjectDialog} handleOpenVendorDialog={this.handleOpenVendorDialog} vendorValue={this.state.vendorValue} vendorItems={this.state.vendorItems} handleProjectChange={this.handleProjectChange} projectValue={this.state.projectValue} projectItems={this.state.projectItems} handleReportValueChange={this.handleReportValueChange} checkedBox={this.state.checkedBox} handleCheckboxChange={this.handleCheckboxChange} reportsName={this.state.reportsName} />
                        <Box className={classes.previewUploadBoxStyle}>
                            <InvoiceDropbox uploadAcceptedFile={this.state.uploadAcceptedInvoiceFile} acceptedFile={this.state.acceptedFileType} classes={classes} onDrop={this.onInvoiceReportDrop} data-testid="InvoiceDropboxId" pdfUrl={this.state.invoiceUrl} handleDelete={this.handleDeleteInvoice} isInvoiceUploadenableorDisable={this.state.isInvoiceUploadenableorDisable} invoiceUploadValidateMessage={this.state.invoiceUploadValidateMessage}/>
                            <InvoicePreviewPdf classes={classes} pdfUrl={this.state.invoiceUrl} invoiceValue={this.state.invoiceValue} handleTabChange={this.handleTabChange} invoiceTabs={this.state.uploadAcceptedInvoiceFile} isLoading={this.state.isLoading} />
                        </Box>
                        <Box className={classes.uploadLaterBox}>
    
                            <UploadLaterButtonBoxes data-test-id="uploadLaterButtonBoxes" handleBack={this.handleBack} uploadAcceptedInvoiceFile={this.state.uploadAcceptedInvoiceFile} quoteUrl={this.state.quoteUrl} handleChange={this.handleChange} handleOpenUploadLaterModal={this.handleOpenUploadLaterModal}/>
                            <InvoiceurlBox
                                invoiceUrl={this.state.invoiceUrl}
                                handleChange={this.handleChange}
                                enableContinueButton={this.state.enableContinueButton}
                            />
                        </Box>
                    </ReportTabPanel>

                    <ReportTabPanel value={this.state.value} index={3}>
                        <VerifyInvoice 
                            segmentName1={this.state.segmentName1} 
                            updated_table_data={this.state.updated_table_data}
                            segmentTotal1={this.state.segmentTotal1} 
                            segmentName2={this.state.segmentName2} 
                            navigation={undefined} 
                            id={""} 
                            invoiceTabs={this.props.location?.state?.invoiceTab || this.state.uploadAcceptedInvoiceFile} 
                            goBackButtonNavigation={this.goBackButtonNavigation} 
                            onContinueButtonClick={(e: any, a: any) => { this.handleChange(e, a) }} 
                            classes={classes} 
                            history={undefined} 
                            invoiceData={this.state.invoiceDetails} 
                            handleSaveData={handleSaveData}
                            saveBtnUpdatedResponse={this.state.saveBtnUpdatedResponse} 
                            newUpdatedDataArr={this.state.newUpdatedDataArr}
                            checkedBox={this.state.checkedBox}
                        />
                    </ReportTabPanel>
                    <ReportTabPanel value={this.state.value} index={4}>
                        <ReportResults segmentName1={this.state.segmentName1} segmentTotal1={this.state.segmentTotal1} segmentName2={this.state.segmentName2} segmentTotal2={this.state.segmentTotal2}
                            quoteCategoryDescription={this.state.quoteCategoryDescription} handleQuoteUpload={this.onQuoteResultDrop} uploadAcceptedFile={this.state.uploadAcceptedInvoiceFile} 
                            quoteName={this.state.uploadAcceptedFile} acceptedFile={this.state.acceptedFileType} classes={classes} pdfUrl={this.state.invoiceUrl} handleDelete={this.handleDeleteInvoice} navigation={undefined} handleInvoiceUpload={this.onInvoiceReportDrop} id={""} quotePdfpage={this.state.quotePdfCount} invoicePdfPage={this.state.invoicePdfCount} goBackButtonNavigation={this.goBackButtonNavigation} history={this.props.history} location={undefined} handleSaveData={handleSaveData} quoteUrl={this.state.quoteUrl}
                            invoiceUrl={this.state.invoiceUrl} quoteData={{ quotedetails: this.state.quoteDetails, quoteTableData: this.state.quoteDescription }} invoiceData={this.state.invoiceDetails} projectId={this.state.projectValue} reportId={this.state.reportId}
                            invoiceURLs={this.state.invoiceURLs}
                            projectName={this.state.projectValue}
                            reportName={this.state.reportsName}
                            vendorItems={this.state.projectItems}
                             />
                    </ReportTabPanel>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start




export const invoiceStyle = (theme: any) => ({

    buttonBoxStyle: {
        marginTop: '13%', gap: '20px', width: '100%', display: 'flex', justifyContent: 'space-between', paddingBottom: '5%'
    },
    noQuoteButtonBoxStyle: {
        gap: '20px', width: '100%', display: 'flex', justifyContent: 'center', padding: '5%'
    },
    helperTextStyle: {
        marginTop: '1%',
        '& p': {
            '&.MuiFormHelperText-contained': {
                marginLeft: '0px'
            }
        },
    },
    reportTabBox: {
        marginTop: '2%',
        width: '100%',
        color: 'black'
    },
    uploadInvoiceBoxStyle: {
        width: '50%',
        background: '#fff',
        padding: '2%',
        height: 'auto',
        minHeight: '420px'
    },
    uploadInvoiceBoxStyle2: {
        width: '100%',
        background: '#fff',
        padding: '2%',
        height: 'auto',
        minHeight: '420px',
    },
    boxTitle: {
        paddingBottom: '10px',
        fonSize: '18px',
        textTransform: 'none' as 'none'
    },
    dialogWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '4%',
        flexDirection: 'column' as 'column',
        alignItems: "center"
    },
    dialogWrapperStyle2: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10%',
        flexDirection: 'column' as 'column',
    },
    dropZoneReportStyle: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "5% 0",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        border: "1px dashed #80808061",
        width: '100%',
        marginTop: '4%'
    },
    dropZoneUploadedFileStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5% 3%",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        border: "1px dashed #80808061",
        width: '320px',
        gap: '1%',
        '& p': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            '& span': {
                color: "orange"
            }
        }
    },
    dialogReportDropZoneText: {
        width: '100%',
        textAlign: 'center' as 'center',
        padding: '20px'
    },



    previewUploadBoxStyle: {
        display: 'flex', justifyContent: 'space-between', gap: '4%', marginTop: '4%'
    },
    previewBoxStyle: {
        padding: '2%', background: '#fff', width: '50%', position: 'relative' as 'relative'
    },
    boxTitleFontStyle: {
        fontSize: '18px'
    },
    noPreviewStyle: {
        display: 'flex', justifyContent: "center", alignItems: 'center', height: "100%", color: '#939393', fontWeight: 550, marginTop:"-17px"
    },
    notifydocumentsPdfBoxStyle: {
        display: 'block',
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        border: '1px solid white'
    },
    menuItemButton: {
        padding: 0
    },
    menuItemStyle: {
        background: 'white', color: 'black', padding: 0
    },
    inputLableStyle: {
        color: 'black'
    },
    textFieldBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '4%',
        '& svg': {
            fill: '#000'
        }
    },
    reportDetailsBoxStyle: {
        padding: '2%', background: '#fff'
    }, imgStyle1: { filter: 'invert(1)', marginRight: '8px' },
    textField: {
        width: "100%",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',

            },
            '&:hover fieldset': {
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'gray',
                borderwidth: "1px"
            },
        },
    },
    editButtonBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px"
    },
    editQuoteButton: {
        height: "45px",
        width: "13%",
        background: "black",
        color: "white",
        // padding: "5px 10px",
        "&.MuiButtonBase-root:hover": {
            background: "black"
        }
    },
    titleStyle: {
        color: "#000000d1!important"
    },
    categoryNameBox:{
        display: 'flex', 
        alignItems: 'baseline',
        marginBottom: '30px'
    },
    paginationBox:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    totalStyle: {
        color: "black",
        fontWeight: 900

    },
    buttonBox: {
        display: "flex",
        flexDirection: 'row' as 'row',
        justifyContent: "space-between",
        margin: "20px 0"
    },
    goBackButton: {
        height: "50px",
        width: "19%",
        border: "2px solid gray"
    },
    continueButton: {
        height: "50px",
        width: "19%",
        background: "black",
        color: "white",
        "&.MuiButtonBase-root:hover": {
            background: "black"
        }
    },
    quoteBox: {
        background: '#fff',
        borderRadius: '10px',
        padding: '50px 30px 50px 50px',
        '& thead': {
            position: 'relative',
            top: '-19px'
        },
        '& MuiPagination-root': {
            marginTop: '5% !important'
        },
        '& p': {
            color: '#000',
            fontWeight: 500,
            margin: '20px 0 3px'
        },
        '& .MuiFilledInput-underline::before': {
            display: 'none'
        },
        '& .MuiFilledInput-underline::after': {
            display: 'none'
        },
        '& input': {
            padding: '17px',
            color: "gray"
        },
        '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '8px'
        },
        '& .MuiTableContainer-root': {
            overflowX: 'unset',
            padding: '0 15px'
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        },
        '& .MuiTableCell-body': {
            color: "gray"
        },
        '& table': {
            position: 'relative'
        },
        '& table::after': {
            content: '""',
            border: '2px solid #e8e8e8',
            borderRadius: '10px',
            position: 'absolute',
            top: '47px',
            left: '-10px',
            width: '101.8%',
            height: '95%',
        }

    },
    viewquoteHeading: {
        color: '#000',
        fontWeight: 600
    },
    quoteDetailsBox: {
        paddingBottom: '20px',
        marginBottom: '30px'
    },
    quotedetailsText: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        marginTop: '30px',
        // marginLeft:"7px!important"
    },
    ItemDetailsText: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        display: "inline-block",
        marginTop: '8px',
        marginRight: '30px',
    },
    ItemFormLabel: {
        color: '#000',
        fontWeight: 500,
        fontSize: '16px',
        display: "inline-block",
        marginRight: '30px',
    },
    ItemFormControl: {
        display: "inline-block"
    },
    ItemFormControlGroup: {
        display: "inline-block",
        "& .MuiRadio-colorSecondary.Mui-checked": {
            color: "#000",
        },
        "& input": {
            color: "#000",
        },
    },
    quoteTableBodyBox: {
        maxHeight: "500px",
        overflow: "auto!important",
        "& td": {
            border: '1px solid #80808099 !important',
            borderRadius: '5px',

        }
    },
    subTotalQuoteBox: {
        display: 'flex',
        justifyContent: 'end',
        margin: '40px 0',
        alignItems: 'baseline',
        '& .MuiFormControl-root': {
            marginLeft: '20px',
            marginRight: '1px',
        }
    },
    quoteTotalAmountBox: {
        border: '2px solid #eee',
        borderRadius: '8px',
        padding: '13px',
        width: '42%',
        marginLeft: '58%'
    },
    subTotalContentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        '& .MuiFormControl-root': {
            width: '300px'
        }
    },
    taxQuoteContent: {
        display: 'flex',
        marginBottom: '20px',
    },
    taxQuoteInputBox: {
        textAlign: 'right' as 'right',
        width:'100%',
        '& .MuiFormControl-root': {
            width: '300px',
        },
        '& .MuiFormControl-root:first-child': {
            width: '20%',
            marginRight: '20px',
        },
    },

    invoiceInnerBox2: {
        width: '100%',
        top: 0,
        overflow: 'visible',
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },

        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& span': {
            textTransform: 'none',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
        '& MuiBox-root-67': {
            padding: 0
        }
    },
    navLinkStyle: {
        textDecoration: 'none',
        color: "white",
        fontSize: "16px",
        // textTransform: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
            color: "white",

        }
    },
    invoicecurveBox: {
        height: '30px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    invoicecurveInnerBox: {
        border: '1px solid #e0802e',
        height: '30px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    invoicecurveRightBox: {
        height: '33px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
        zIndex: 99,
    },
    curveRightinvoiceInnerBox: {
        border: '1px solid #e0802e',
        height: '32px',
        width: '30px',
        borderBottomLeftRadius: '15px',
        borderTop: 'none',
        borderRight: 'none'
    },
    invoiceTitleBox: {
        minWidth: '115px',
        textAlign: 'center' as 'center',
        padding: '13px 0px',
        border: '0.5px solid #e4e3e3',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        // borderBottom: 'none',
        backgroundColor: "white",
        '&.Mui-selected': {
            background: '#ffffff',
            border: '0.5px solid white',

            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            borderBottom: 'none'
        },
    },

    itemDetailsStyle: {
        color: '#000',
        fontWeight: 600,
        marginTop: "30px"
    },

    resultButtonBox: {
        display: "flex",
        // flexDirection:'column',
        justifyContent: "space-between",
        margin: "20px 0"
    },
    resultGoBackButton: {
        height: "50px",
        width: "180px",
        border: "2px solid gray"
    },
    resultContinueButton: {
        height: "50px",
        width: "180px",
        background: "black",
        color: "white",
        "&.MuiButtonBase-root:hover": {
            background: "black"
        }
    },

    viewquoteTitle: {
        color: '#000',
        fontWeight: 600
    },
    quoteDeatilBox: {
        paddingBottom: '20px'
    },
    quotedetailsContent: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        marginTop: '30px',
        marginLeft: '7px'
    },
    tableBodyBox: {

        // borderRadius: '10px !important',
        "& td": {
            border: '1px solid #80808099 !important',
            borderRadius: '5px',
        }
    },
    subTotalBox: {
        display: 'flex',
        justifyContent: 'end',
        margin: '50px 0',
        alignItems: 'baseline',
        '& .MuiFormControl-root': {
            marginLeft: '20px',
            marginRight: '8px',
            width: '25%'
        }
    },
    invoiceTotalAmountBox: {
        border: '2px solid #eee',
        borderRadius: '8px',
        padding: '20px',
        width: '40%',
        marginLeft: '60%'
    },
    invoiceSubtotalContentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        '& .MuiFormControl-root': {
            width: '35%'
        }
    },
    invoiceTaxContent: {
        display: 'flex',
        // justifyContent: 'space-between',
        marginBottom: '20px',
    },
    invoiceTaxInputBox: {
        textAlign: 'right' as 'right',
        '& .MuiFormControl-root': {
            width: '37%',
        },
        '& .MuiFormControl-root:first-child': {
            width: '20%',
            marginRight: '20px',
        },
    },
    breadcrumbLinkStyle: {
        textDecoration: "underline",
        color: '#87888F',
        fontWeight: 500
    },
    breadcrumbText: {
        color: "orange",
        fontWeight: 500
    },
    switchStyle: {
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            opacity: 0.8
        }
    },
    pageBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '15px 0 0 10px',
    },


    mainDivWrapper: {
        display: "flex",
        flexDirection: "row" as "row",

    },
    outterDivWrapper: {
        border: '15px solid #03037c',
        height: "200px",
        width: "200px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "20px 60px"


    },
    innerDivStyle: {
        border: '15px solid lightgray',
        height: "172px",
        width: "200px",
        borderRadius: "50%",
        // boxSizing: "border-box",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px"
    },
    textStyle1: {
        marginTop: "7px",
        fontSize: "15px",
        fontWeight: 700
    },
    textStyle3: {
        fontSize: "14px",
        fontWeight: 450,
        color: "black"
    },
    textStyle2: {
        color: "green",
        fontSize: "12px",
        fontWeight: 500
    },
    textStyle4: {
        fontWeight: 700,
        fontSize: "12px"
    },
    toggleButtonStyle: {
        display: "flex",
        justifyContent: "flex-end"
    },
    totalInvoiceBoxReport: {
        border: "1px solid gray",
        borderRadius: "5px",
        width: "240px",
        height: "74px",
        padding: "10px"
    },
    innerTotalInvoiceBoxReport: {
        display: "flex", gap: "8px"
    },
    totalQuoteBoxReport: {
        border: "1px solid gray",
        borderRadius: "5px",
        width: "240px",
        height: "74px",
        padding: "10px"
    },
    innerTotalQuoteBoxReport: {
        display: "flex", gap: "8px"
    },
    invoiceSmallBoxReport: {
        width: "20px",
        height: "20px",
        background: "#ccc8c8",
        borderRadius: "4px"
    },
    QuoteSmallBoxReport: {
        width: "20px",
        height: "20px",
        background: "#04045e",
        borderRadius: "4px"


    },

    containerBoxReport: {
        display: "flex",
        flexDirection: "column" as "column",
        gap: "20px",
        marginTop: "25px",
        padding: "30px 25px"
    },
    totalInvoiceQuoteWrapperReport: {
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
        gap: "30px",
        margin: "108px 0"
        // height:"400px"
    },
    innerContainerBox: {
        width: "100%",
        display: "flex",
        flexDirection: "row" as "row",
        justifyContent: "flex-start"
    },
    previewTitle: {
        width: "50%",
        paddingLeft: "5px",
        fontWeight: 700
    },
    previewTitle1: {
        width: "50%",
        paddingLeft: "16px",
        fontWeight: 700

    },
    pdfInnerBox1: {
        // background: '#fcf7f2',
        position: "absolute" as "absolute",
        width: '100%',
        // borderTopLeftRadius: '15px',
        // borderTopRightRadius: '15px',
        top: 0,
        zIndex: 3,
        overflow: 'visible',
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },

        '& span.MuiTabs-indicator': {
            display: 'none!important'
        },
        '& span': {
            // textTransform: 'capitalize',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
        '& MuiBox-root-67': {
            padding: 0
        }
    },
    pdfInnerBox2: {
        // background: '#fcf7f2',
        // position: 'relative' as 'relative',
        width: '100%',
        marginLeft: "12px",
        zIndex: 3,
        // borderTopLeftRadius: '15px',
        // borderTopRightRadius: '15px',
        top: 0,
        overflow: 'visible',
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },

        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& span': {
            // textTransform: 'capitalize',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
        '& MuiBox-root-67': {
            padding: 0
        }
    },
    pdfInnerBox3: {
        // background: '#fcf7f2',
        // position: 'relative' as 'relative',
        width: '100%',
        zIndex: 3,
        // borderTopLeftRadius: '15px',
        // borderTopRightRadius: '15px',
        top: 0,
        overflow: 'visible',
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },

        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& span': {
            // textTransform: 'capitalize',
        },
        '& MuiBox-root-67': {
            padding: 0
        }
    },
    buttonBox1: {
        position: "absolute" as "absolute",
        top: "-16px",
        right: "-2px"
    },

    previewQuoteOutterBox: {
        height: "91%",
        width: "98%",
        border: "1px solid gray",
        borderRadius: "0 8px 8px 8px",
        padding: "15px 15px 20px 15px",
        marginTop: "48px",
        '& body #toolbarContainer': {
            display: "none"
        }
    },
    previewInvoiceOutterBox: {
        height: "91%",
        width: "98%",
        border: "1px solid gray",
        borderRadius: "0 8px 8px 8px",
        padding: "15px 15px 20px 15px",
        marginLeft: "12px",
        marginTop: "-1px",
        overflow: 'hidden'
        // position:"relative"
    },
    previewInvoiceInnerBox: {
        height: '93%',
        // display: 'block',
        // maxHeight: '97%',
        width: '100%',
        borderRadius: "8px",
        border: "1px solid #c3c0c0",
        overflow: "hidden"
    },
    previewInvoicePdfInnerBox: {
        width: '100%',
        borderRadius: "8px",
        border: "1px solid #c3c0c0",
        overflow: "hidden",
        height: '77%'
    },
    pdfBox: {
        position: "relative" as "relative",
        width: "50%",
        color: "black",
        height: "fit-content"
    },
    pdfTitleBox: {
        minWidth: '115px',
        textAlign: 'center' as 'center',
        padding: '13px 0px',
        border: '1px solid gray',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        borderBottom: 'none',
        backgroundColor: "white",
        '&.Mui-selected': {
            background: '#ffffff',
            border: '1px solid #gray',
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
            borderBottom: 'none'
        },
    },
    pdfTitleBox1: {
        minWidth: '115px',
        textAlign: 'center' as 'center',
        padding: '13px 0px',
        border: '1px solid gray',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        borderBottom: 'none',
        backgroundColor: "white",
        '&.Mui-selected': {
            background: '#ffffff',
            border: '1px solid #gray',
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
            borderBottom: 'none'
        },
    },
    pdfTitleBox2: {
        minWidth: '115px',
        width: '150px',
        textAlign: 'center' as 'center',
        // padding: '13px 0px',
        border: '1px solid gray',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        borderBottom: 'none',
        backgroundColor: "white",
        '&.Mui-selected': {
            background: '#ffffff',
            border: '1px solid #gray',
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
            borderBottom: 'none'
        },
    },
    pdfcurveBox: {
        height: '30px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    pdfcurveBox1: {
        height: '30px',
        width: '31px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '-31px',
    },
    pdfcurveInnerBox: {
        border: '1px solid #e0802e',
        height: '30px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    pdfcurveInnerBox1: {
        border: '1px solid #e0802e',
        height: '30px',
        width: '31px',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        borderLeft: 'none'
    },
    pdfcurveRightBox: {
        height: '33px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
        zIndex: 99,
    },
    pdfcurveRightBox1: {
        height: '33px',
        width: '30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: '-30px',
        zIndex: 99,
    },
    curveRightPdfInnerBox: {
        border: '1px solid #e0802e',
        height: '32px',
        width: '30px',
        borderBottomLeftRadius: '15px',
        borderTop: 'none',
        borderRight: 'none'
    },
    curveRightPdfInnerBox1: {
        border: '1px solid #e0802e',
        height: '32px',
        width: '30px',
        borderBottomLeftRadius: '15px',
        borderTop: 'none',
        borderRight: 'none'
    },
    deleteButton: {
        height: "50px",
        width: "180px",
        color: "#ea7145",
        border: "2px solid #ec8c69"
    },
    innerButtonBox: {
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        gap: "0 10px"
    },
    quoteInvoiceFormBox: {
        background: '#fff',
        borderRadius: '10px',
        padding: "22px 26px 0px 31px",
        '& .MuiTable-root': {
            // borderCollapse: 'separate',
            borderSpacing: '8px'
        },
        '& .MuiTableContainer-root': {
            overflowX: 'unset',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        },
        '& .MuiTableCell-head': {
            paddingLeft: 0,
            fontWeight: 600,
            fontSize: "10px"
        }

    },
    quoteInvoiceDetailTitle: {
        color: '#0a0019',
        fontWeight: 500,
        fontSize: "10px",
        margin: '15px 0 5px',
    },
    quoteInvoiceContentBox: {
        color: '#aeaab2',
        fontWeight: 500,
        fontSize: "10px",
        padding: '5px 0px 5px 5px',
        border: '1px solid #d5d5d5',
        borderRadius: '2px',
        marginBottom: '15px',
    },
    quoteInvoiceContentDateBox: {
        color: '#aeaab2',
        fontWeight: 500,
        fontSize: "10px",
        padding: '5px 0px 5px 5px',
        border: '1px solid #d5d5d5',
        borderRadius: '2px',
        marginBottom: '15px',
        // marginRight: '25px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    quoteInvoiceDeatilBox: {
        paddingBottom: '0px',
        '&.MuiGrid-spacing-xs-2 .MuiGrid-item': {
            padding: 0
        }
    },
    quoteInvoiceDetailsContent: {
        color: '#000',
        fontWeight: 600,
        fontSize: '10px',
        marginTop: '50px',
        marginBottom: '20px'
    },
    reportTableBodyBox: {
        "& td": {
            border: '1px solid #e6e6e6 !important',
            borderRadius: '5px',
            color: '#aeaab2',
            fontWeight: 500,
            fontSize: "10px",
            '&:first-child': {
                textAlign: 'center',
                width: '50px',
                padding: 0,
                fontSize: "10px"

            },
            '&:nth-child(4)': {
                width: '53px',
            }
        }
    },
    reportSubtotalBox: {
        display: 'flex',
        justifyContent: 'end',
        margin: '64px 8px 50px 0',
        alignItems: 'baseline',
        '& p:first-child': {
            color: '#000',
            fontSize: "10px"
        },
        '& p:last-child': {
            marginLeft: '20px',
            width: '30%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '7px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
            marginBottom: '10px',
            marginRight: '-3px',
        }
    },
    quoteInvoiceTotalAmountBox: {
        border: '2px solid #eee',
        borderRadius: '8px',
        padding: '20px',
        width: '65%',
        margin: '0 8px 14 auto'
    },
    quoteInvoiceSubTotalContentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
        '& p:last-child': {
            width: '40%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '8px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
            fontSize: "10px"
        }
    },
    totalPrice: {
        fontWeight: 500,
        color: '#0a0019',
        margin: '15px 0 5px',
        fontSize: "11.5px"
    },
    quoteInvoiceTaxContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    quoteInvoiceTaxInputBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        fontSize: "10px",
        '& p:last-child': {
            width: '38%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '8px',
            border: '1px solid #d5d5d5',
            borderRadius: '4px',
            fontSize: "10px"
        },
        '& p:first-child': {
            width: '22%',
            marginRight: '20px',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '8px',
            border: '1px solid #d5d5d5',
            borderRadius: '4px',
            fontSize: "10px"

        },
    },
    itemtableBox: {
        marginRight: '0px',
        '& table': {
            position: 'relative'
        },
        '& table::after': {
            content: '""',
            border: '1px solid #bebebe',
            borderRadius: '10px',
            position: 'absolute',
            top: '0px',
            left: '-7px',
            width: '101%',
            height: '100%',
            zIndex: 9
        },
        '& .MuiPagination-root': {
            marginTop: '5%',
            background: '#fff',
            boxShadow: '0 0 7px #e3d3d3',
            padding: '15px 20px',
            display: 'inline-block',
            marginLeft: '-3px'
        },
        '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '8px'
        },
    },


    resizeAndPageWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    resizeAndPageWrapper1: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    ImgIconStyle: {
        marginTop: "19px",
        marginLeft: "3px",
        fontWeight: 500
    },

    ImgIconStyle1: {
        marginTop: "9px",
        marginLeft: "1px",
        fontWeight: 500
    },


    pdfView: {
        display: 'block',
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        minHeight: 792,
        border: '1px solid white'
    },
    visitedParagraphStyle: { color: "orange" },
    tabBoxStyle: {
        background: '#fff',
        width: '100%'
    },
    tabsReportStyle: {
        margin: '0px',
        marginBottom: "20px",
        borderRadius: '4px',
        border: '1px solid #eee',
        padding: '10px',
        width: '100%',
        '& div': {
            '&.MuiTabs-flexContainer': {
                justifyContent: 'space-between'
            }
        },
        '& button': {
            opacity: 1,
            color: '#939393',
            maxWidth: '320px',
            '&.Mui-selected': {
                borderRadius: '4px',
                background: '#e0802e',
                clipPath: "polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 0 53%, 0% 0%)",
                '& h6': {
                    fontWeight: '600',
                    color: '#ffffff',
                    [theme.breakpoints.down(1610)]: {
                        fontSize: '18px'
                    },
                },
                '& p': {
                    fontWeight: '500',
                    color: '#ffffff',
                }
            }
        },
        '& span.MuiTabs-indicator': {
            display: 'none!important'
        },
        '& span': {
            textTransform: 'capitalize',
            alignItems: 'unset',
        },
    },
    tabTextStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px'
    },
    paragraphStyle: {
        fontWeight: 600
    },
    invoiceTabMenuBoxStyle: {
        textAlign: 'left' as 'left',
        minHeight: '100%',
        '&.MuiTab-root': {
            minHeight: '100%',
        }
    },

    reportTextBox: {
        width: "100%",
        "& .MuiInputBase-root": {
            fontSize: "10px",
        },
        "&.MuiOutlinedInput-input": {

        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',

            },
            '& .MuiOutlinedInput-input': {
                padding: "10.5px 7px"

            },
            '&:hover fieldset': {
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'gray',
                borderwidth: "1px"
            },
        },
    },
    indexBox: {
        width: '7%',
    },
    editQuoteInput: {
        width: '40% !important',
    },
    editunitQuoteContent: {
        width: '10% !important'
    },
    RatesheetTableHead: {
        marginTop: '25px',
        '& th': {
            fontWeight: 600
        },
    },
    uploadLaterBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '5%'
    },
    uploadPdfBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '2%',
        '& img': {
            cursor: 'pointer',
            width: '20px'
        }
    },
    textFieldTaxPercentage: {
        width: "100%",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
                

            },
            '&:hover fieldset': {
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'gray',
                borderwidth: "1px"
            },
            '@media (max-width:1525px)': {
                minWidth:"65px"
            },
            
        },
        "& .MuiOutlinedInput-input":{
            '@media (max-width:1705px)': {
                paddingLeft:"14px",
                paddingRight:"14px",
            },
            '@media (max-width:1630px)': {
                paddingLeft:"12px",
                paddingRight:"12px",
            },
            '@media (max-width:1580px)': {
                paddingLeft:"10px",
                paddingRight:"10px",
            },
            '@media (max-width:1525px)': {
                paddingLeft:"6px",
                paddingRight:"6px",
            },
        }
       
    },
    invoiceTextfieldSegment:{
        width: "20%",
        "& .MuiInputBase-root": {
            fontSize: "10px",
            marginTop:"6px",
        },
        "&.MuiOutlinedInput-input": {

        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',

            },
            '& .MuiOutlinedInput-input': {
                padding: "10.5px 7px"

            },
            '&:hover fieldset': {
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'gray',
                borderwidth: "1px"
            },
        },
    },
    segmentTextAndTextfield:{
        display: "flex",
        alignItems: "center",
        gap: "20px",
        marginTop:"20px",
        marginBottom:"10px",
    },

    quoteInvoiceDetailsBox:{
        paddingBottom: '0px',
        '&.MuiGrid-spacing-xs-2 .MuiGrid-item': {
            padding: 0
        },
        
    },
    subTotalBoxSegments: {
        display: 'flex',
        justifyContent: 'end',
        margin: '50px 0',
        alignItems: 'baseline',
        '& .MuiFormControl-root': {
            marginLeft: '20px',
            marginRight: '8px',
            width: '44%'
        }
    },
})
export { CreateReport }
export default withStyles(invoiceStyle)(CreateReport)

const CreateReportDialog = styled(Dialog)({
    backdropFilter: "blur(8px)",
    "& .MuiDialog-paper": {
        width: "100%",
        maxWidth: "392px",
        borderRadius: 10,
        padding: "15px 33px"
    },
    "& .MuiDialogContent-root": {
        padding: 0
    },
    "& .createReportTitleStyle": {
        fontWeight: 550,
        textAlign: 'center',
    },
    "& .venderNameLabel": {
        color: 'black',
        fontSize: '16px',
        textTransform: 'capitalize',
        marginBottom: 5
    },
    "& .vendorTypeContainer": {
        width: '100%',
        marginTop: "10px",
        marginLeft: "0px"
    },
    "& .vedorEmailLabel": {
        textTransform: 'capitalize',
        color: 'black',
        fontSize: '16px',
        marginTop: "32px",
        marginBottom: 5
    },
    "& .mainContainerStyle": {
        width: '100%'
    },
});

// Customizable Area End
