import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { addDays } from 'date-fns';

import React from 'react'
import moment from "moment";

export interface Props {
  navigation: any;
  handleSaveData: (data: any, docType: string) => void;
  history: any;
}

export interface GlobalData {
  message: string;
  data: Data;
}

interface Data {
  Vendor: Vendor[];
  Project: Project[];
  Report: Report[];
}


interface Vendor {
  id: number;
  name: string;
  created_at: string;  
  updated_at: string;  
  account_id: number;
  email: string;
}

interface Project {
  id: number;
  quote_id: string | null;
  date: string | null;  
  vendor: string | null; 
  project: string;
  created_at: string; 
  updated_at: string; 
  status: "active" | "inactive";  
  account_id: number | null; 
}

interface Report {
  id: number;
  report_id: string;
  date: string;  
  vendor: string; 
  project: string; 
  delta: boolean;
  created_at: string;  
  updated_at: string;  
  report_name: string;
  account_id: number;
  project_name: string;
}

interface SS {
}

interface S {
  hasSearchFilter: boolean;
  settingOptionsData: string[];
  AddNewOptionsData: string[];
  documentTypeOptionsData: string[];
  vendorsOptionsData: string[];
  ProjectOptionsData: string[];
  keywordOptionsData: string[];
  documentValue: string;
  projectValue: string;
  vendorValue: string;
  keywordValue: string[];
  hasDateRange: boolean;
  dateRangeValue: any;
  token: string;
  addNewValue: boolean;
  userActionValue: boolean;
  name: string | null;
  files: any[];
  openQuotesDialog: boolean;
  isLoading: boolean;
  apiError: boolean,
  status: number;
  errMsg: string;
  profileImageUrl: string;
  globalData: string;
  globalResponse: GlobalData;
}

export const configJSON = require("./config");

export default class HeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  searchInput: React.RefObject<unknown>;
  advanceSearchApiId: string = "";
  getProjectAdvanceSearchApiId: string = "";
  getVendorAdvanceSearchApiId: string = "";
  getLoggedInUserDetailsApiId: string = "";
  uploadHeaderFileId: string = "";
  globalSearchApiCallId: string = "";
  /**
   *
   */
  constructor(props: Props) {
    super(props);
    this.searchInput = React.createRef();
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationScreenNameMessage)
    ];

    this.state = {
      hasSearchFilter: false,
      userActionValue: false,
      addNewValue: false,
      settingOptionsData: ['Profile', 'Reset Password', 'Logout'],
      AddNewOptionsData: ['New Quote', 'New Invoice', 'New Customer', 'New Project'],
      documentTypeOptionsData: ['Quote', 'Invoice'],
      vendorsOptionsData: ['huel LLC', 'Roob Group', 'Huels Group', 'Quetzon-Robe'],
      ProjectOptionsData: ['huel LLC', 'Roob Group', 'Huels Group', 'Quetzon-Robe'],
      keywordOptionsData: [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
        'Miriam Wagner',
        'Bradley Wilkerson',
        'Virginia Andrews',
        'Kelly Snyder',
      ],
      documentValue: "",
      projectValue: "",
      vendorValue: "",
      keywordValue: [],
      hasDateRange: false,
      dateRangeValue: [{
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: 'selection'
      }],
      token: '',
      name: '...',
      openQuotesDialog: false,
      isLoading: true,
      apiError: false,
      status: NaN,
      files: [],
      errMsg: '',
      profileImageUrl: '',
      globalData: '',
      globalResponse: {
        message: "Success",
        data: {
          Vendor: [
            {
              id: 2,
              name: "Laxmi vendors",
              created_at: "2024-09-05T04:53:06.685Z",
              updated_at: "2024-09-05T04:58:18.950Z",
              account_id: 1,
              email: "dkevat@deqode.com"
            }
          ],
          Project: [
            {
              id: 310,
              quote_id: null,
              date: null,
              vendor: null,
              project: "testteam",
              created_at: "2024-03-05T06:57:48.194Z",
              updated_at: "2024-03-05T06:57:48.194Z",
              status: "active",
              account_id: null
            }
          ],
          Report: [
            {
              id: 3,
              report_id: "quote1232",
              date: "2024-09-05",
              vendor: "2",
              project: "4",
              delta: true,
              created_at: "2024-09-05T04:45:16.351Z",
              updated_at: "2024-09-05T04:54:21.217Z",
              report_name: "Report1",
              account_id: 1,
              project_name: "Project 1"
            }
          ]
        }
      }
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    const token = await document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1];
    token && this.setState({ token: token });
    this.getLoggedInUserDetails()
    this.getVendors()
    this.getProjects()
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.advanceSearchApiId) {
        this.advanceSearchResponseHandle(responseJson)
      }
      if (apiRequestCallId === this.getProjectAdvanceSearchApiId) {
        this.projectsResponseHandle(responseJson)
      }
      if (apiRequestCallId === this.getVendorAdvanceSearchApiId) {
        this.vendorsResponseHandle(responseJson)
      }
      if (apiRequestCallId === this.getLoggedInUserDetailsApiId) {
        this.getLoggedInUserDetailResponseHandle(responseJson)
      }
      if (apiRequestCallId === this.uploadHeaderFileId) {
        this.getQuotesUploadHeaderResponseHandle(responseJson)
      }
      if(apiRequestCallId === this.globalSearchApiCallId) {
        this.globalSearchResponseHandle(responseJson);
      }


    }
  }

  getQuotesUploadHeaderResponseHandle = (responseJson: any) => {
    this.setState({ isLoading: false })
    this.setState({ status: responseJson.status })
    if (responseJson.errors || responseJson.error) {
      this.setState({ apiError: true, errMsg: responseJson.error })
    } else {
      this.setState({ apiError: false })
    }
  }

  getLoggedInUserDetailResponseHandle = async (responseJson: any) => {
    if (responseJson.data) {
      const updateList = [responseJson.data.user.id.name]
      this.setState({ name: responseJson.data.user.id.name, settingOptionsData: updateList, profileImageUrl: responseJson.data.user.profile_image_url })
    }
  }

  advanceSearchResponseHandle = async (responseJson: any) => {
    if (responseJson) {
      if (responseJson?.error || responseJson?.errors) {
        console.log("responseJson?.error", responseJson.error)
      }
      else if (responseJson.message == "Success" && responseJson.data) {

        this.props.handleSaveData(responseJson.data, this.state.documentValue)

      } else {
        this.props.handleSaveData([], '')
      }
    }
    this.setState({ documentValue: "" })
  }

  projectsResponseHandle = (responseJson: any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ ProjectOptionsData: responseJson.data })
    }
  }

  vendorsResponseHandle = (responseJson: any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ vendorsOptionsData: responseJson.data })
    }
  }

  handleLogout = (value: string) => {
    this.setState({ userActionValue: !this.state.userActionValue })
    if (value == this.state.name) {
      localStorage.setItem('isChangePassword', 'false')
      if (this.props.navigation === undefined) {
        this.props.history.push('/profile')
      } else {
        const navigation = this.props?.navigation;
        const paramsToStore = { param: (navigation && typeof navigation.getLocation === 'function') && navigation.getLocation().pathname };
        localStorage.setItem('previousParams', JSON.stringify(paramsToStore));
        this.props.navigation.navigate('ProfileMain')
      }
    }
    if (value === 'Reset Password') {
      localStorage.setItem('isChangePassword', 'true')
      this.props.navigation.navigate('ProfileDetailWeb')
    }
    if (value == "Logout") {
      localStorage.clear();
      document.cookie = `Token=; expires=Thu, 18 Dec 2013 12:00:00 UTC"; path=/;`;
      document.cookie = `RefreshToken=; expires=Thu, 18 Dec 2013 12:00:00 UTC"; path=/;`;
      if (this.props.navigation === undefined) {
        this.props.history.push('/login')
      } else {
        this.props.navigation.navigate('EmailAccountLoginWeb')
      }
    }
  }

  handleAddNew = (item: string) => {
    switch (item) {
      case 'New Quote': {
        this.setState({ openQuotesDialog: true })
        break;
      }
      case 'New Invoice':
        this.props.navigation.navigate('DashboardInvoice')
        break;
      case 'New Customer':
        break;
      case 'New Project':
        break;
      default:
        break;
    }
    this.setState({ addNewValue: !this.state.addNewValue })
  }

  setSearchValue = () => {
    this.setState({ hasSearchFilter: true })
  }

  setDocumentValue = (event: any) => {
    this.setState({ documentValue: event.target.value })
  }

  setProjectValue = (event: any) => {
    this.setState({ projectValue: event.target.value })
  }

  setVendorsValue = (event: any) => {
    this.setState({ vendorValue: event.target.value })
  }

  setKeywordsValue = (data: any) => {
    this.setState({ keywordValue: data })
  }

  handleCancelButton = () => {
    this.setState({
      hasSearchFilter: false, documentValue: "",
      projectValue: "",
      vendorValue: "",
      keywordValue: [],
      dateRangeValue: [{
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: 'selection'
      }]
    })
  }

  handleApplyButton = async () => {
    await this.handleAdvanceSearch()
    this.setState({
      hasSearchFilter: false,
      projectValue: "",
      vendorValue: "",
      keywordValue: [],
      dateRangeValue: [{
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: 'selection'
      }]
    })
  }

  handleDateRangeModal = () => {
    this.setState({ hasDateRange: !this.state.hasDateRange })
  }

  handleDateRangeValue = (data: any) => {
    this.setState({ dateRangeValue: data })
  }

  handleCloseHeaderDialog = () => {
    this.setState({ openQuotesDialog: false, files: [], isLoading: true, apiError: false });
  };

  getProjects = async () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectAdvanceSearchApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getVendors = async () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVendorAdvanceSearchApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleAdvanceSearch = async () => {
    const token = this.state.token;

    const data = {
      document_type: this.state.documentValue,
      keyword: "",
      vendor: this.state.vendorValue,
      project: this.state.projectValue,
      start_date: moment(this.state.dateRangeValue[0].startDate).format('YYYY-MM-DD'),
      end_date: moment(this.state.dateRangeValue[0].endDate).format('YYYY-MM-DD')
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advanceSearchApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.advanceSearchApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getLoggedInUserDetails = async () => {
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLoggedInUserDetailsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDetailsApiEndPoint}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  onDropHeader = (acceptedFiles: any) => {
    this.setState({ files: acceptedFiles });
    if (acceptedFiles.length > 0) {
      const header = {
        "token": this.state.token
      };

      const headerQuotesFormData: any = new FormData();

      acceptedFiles.forEach((file: any) => {
        headerQuotesFormData.append('files[]', file);
      });

      const requestHeaderQuotesMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.uploadHeaderFileId = requestHeaderQuotesMessage.messageId;
      requestHeaderQuotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.fileUploadApiEndPoint
      );

      requestHeaderQuotesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestHeaderQuotesMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        headerQuotesFormData
      );

      requestHeaderQuotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.fileUploadApiMethod
      );

      runEngine.sendMessage(requestHeaderQuotesMessage.id, requestHeaderQuotesMessage);

      return true;
    }
  };

  closeModal = () => {
    this.setState({ globalData: "" });
  };


  globalSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    const trimQuery = query.trimStart();
    this.setState({ globalData: trimQuery, isLoading: true });
    const token = this.state.token;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const searchRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.globalSearchApiCallId = searchRequestMessage.messageId;

    searchRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.globalSearchApiEndPoint}${trimQuery}`
    );

    searchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    searchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);

    return true;
  };

  globalSearchResponseHandle = (responseJson: GlobalData) => {
    this.setState({ globalResponse: responseJson, isLoading: false });
  };

  projectDetails = (project: string) => {
    const projectDetails = { projectDetails: project };
    this.setState({ globalData: "" });
    const projectSearchMessage = new Message(getName(MessageEnum.NavigationMessage));
    projectSearchMessage.addData(getName(MessageEnum.NavigationTargetMessage), `ProjectS`);
    projectSearchMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), projectDetails);
    projectSearchMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(projectSearchMessage)
  };

  reportDetails = (projectId: string, projectName: string) => {
    this.setState({ globalData: "" });
    const projectData = { id: projectId, projectName: projectName };
    const reportSearchMessage = new Message(getName(MessageEnum.NavigationMessage));
    reportSearchMessage.addData(getName(MessageEnum.NavigationTargetMessage), `Viewreport`);
    reportSearchMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), projectData);
    reportSearchMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(reportSearchMessage)
  };
  
}
