// Customizable Area Start
import React from "react";

import {
    Grid, Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    Select, MenuItem, OutlinedInput, Button,
    TextField, TableSortLabel, InputLabel, Slider, Chip,
    Dialog, Menu, CircularProgress, TablePagination, DialogTitle, DialogContent, Badge
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { styled, createTheme, withStyles, createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TuneIcon from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { NavLink } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { CSVLink } from "react-csv";


import {
    create_new_invoice,
    create_new_project,
    upload_invoice,
    exportCSV,calendarImage
} from "./assets";
import Header from "../../landingpage/src/Header.web";
import ProjectsController, { Props, configJSON, TablebodyCellProps, FilterDialogProps, columnProject } from "./ProjectsController.web";
import { StyledTableRowItems, TableSortIcon } from "../../landingpage/src/DashboardNewTable.web";
import moment from "moment";
import { AddProjectNameDialog } from "../../landingpage/src/Dashboard.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({});

export const DashboardNavNewCard = (props: any) => {
    const classes = useStyles();
    return (
        <>
            <div style={webStyle.actionCardWebStyle} onClick={props.handleOnClick}>
                <div
                    className={`${classes.iconImageWrapper}`}
                    style={props.imageStyle}
                >
                    <img src={props.img} alt="" />
                </div>
                <div className={classes.actionDataTxt} style={props.textStyle}>
                    {props.actionCardText}
                </div>
            </div>
        </>
    );
};

const StartDateCalendar = styled(KeyboardDatePicker)({
    height: "55px",
    border: '1px solid #bdbdbd',
    borderRadius:'4px',
    margin: 0,
    display:'flex',
    justifyContent:"center",
    "& .MuiFormHelperText-root.Mui-error": {
        display: 'none !important'
    },
    "& .MuiInputBase-input": {
        paddingLeft:'11px'
    },
   
});

const StyledTableMenuItem = styled(Menu)({
    "& .MuiPaper-elevation8": {
        overflow: 'visible',
        boxShadow: "none",
        border: "1px solid #d2d1d1",
        marginTop: '54px',
        transform: 'translate(-33%, -2%) !important',
        '&::before': {
            content: '" "',
            width: '0',
            position: 'absolute',
            borderBottom: 'solid 12px #dbdbdb',
            borderLeft: 'solid 20px transparent',
            height: '0',
            borderRight: 'solid 12px transparent',
            top: '-12px',
            left: '121px',
        },
        '&::after': {
            position: 'absolute',
            width: '0',
            height: '0',
            content: '" "',
            borderBottom: 'solid 9px white',
            borderLeft: 'solid 20px transparent',
            borderRight: 'solid 10px transparent',
            top: '-9px',
            left: '121px',
        }
    }
});

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#05052B',
        color: 'white',
    },
}))(Badge);

export const ProjectNavCard = (props: any) => {
    const classes = useStyles();
    return (
        <>
            <div style={webStyle.navProjectActionCard} onClick={props.handleOnClick}>
                <div
                    className={`${classes.iconProjectImageWrapper}`}
                    style={props.imageStyle}
                >
                    <img src={props.img} className={classes.iconImage} alt="" />
                </div>
                <div className={classes.actionprojectText} style={props.textStyle}>
                    {props.actionCardText}
                </div>
            </div>
        </>
    );
};

export const TableFooter = (props: any) => {
    const classes = useStyles();
    const { reportProjects, page, handleClickChangePage, rowsPerPage, handleChangePerPage, isLoading } = props
    return (
        <>
            {reportProjects > 5 && <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                className={classes.paginated}
                count={reportProjects}
                component="div"
                page={page}
                onPageChange={handleClickChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangePerPage}
            />}
            {!(isLoading || reportProjects > 0) &&
                <Typography style={webStyle.noDataContentstyle}>
                    {configJSON.noDataContent}
                </Typography>
            }
        </>
    )
}

export const FilterDialog = (props: FilterDialogProps) => {
    const { classes, daterangeOptionsData, vendorListData, StatusOptionsData, endDateDisabledVal, toDateVal, resetAllHandler, handleToDateChange, handleFromDateChange, dateVal, MaxSliderVal, MinSliderVal, filterApplyHandler, handleMinSliderChange, handleMaxSliderChange, statusValue, handleStatusChange, handleVendorChange, handleClose, handleOpen, vendorName, handleSliderChange, sliderValue, isCustomValue, handleDateChange ,minNumberSilder,maxNumberSilder} = props
    
   

    return (
        <>
            <Dialog className={classes.dialogBoxStyle} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <Box className={classes.filterBox}>
                    <Typography variant="h6">{configJSON.filterTitle}</Typography>
                    <CloseIcon onClick={handleClose} data-testid="filterCloseId" style={{ cursor: "pointer" }} />
                </Box>
                <Box className={classes.selectMenuBox}>
                    <Box className={classes.vendorOptionBox}>
                        <Box className={classes.vendorBox}>
                            <InputLabel id="label">{configJSON.vendorTitle}</InputLabel>
                            <Select
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                value={vendorName == "" ? 'selected' : vendorName}
                                onChange={handleVendorChange}
                                input={<OutlinedInput />}
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                data-testid="vendorTestId"
                            >
                                <MenuItem value="selected" disabled className={classes.placeHolderMenu}>{vendorName == "" ? configJSON.vendorPlaceholder : vendorName}</MenuItem>
                                {vendorListData.map((vendorItem) => {
                                    return (
                                        <MenuItem key={vendorItem} value={vendorItem} className={classes.selectMenubox}>
                                            {vendorItem}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box className={classes.vendorBox}>
                            <InputLabel id="label">{configJSON.statusTitle}</InputLabel>
                            <Select
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                value={statusValue == "" ? 'selected' : statusValue}
                                onChange={handleStatusChange}
                                input={<OutlinedInput />}
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                data-testid="statusTestid"
                            >
                                <MenuItem value={"selected"} disabled className={classes.placeHolderMenu}>{configJSON.selectPlaceholder}</MenuItem>
                                {
                                    StatusOptionsData.map((statusOption) => {
                                        return (
                                            <MenuItem key={statusOption} value={statusOption} className={classes.selectMenubox}>
                                                {statusOption}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>
                        <Box className={classes.vendorBox}>
                            <InputLabel id="label">{configJSON.dateCreatedTitle}</InputLabel>
                            <Select
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                value={isCustomValue == "" ? "selected" : isCustomValue}
                                onChange={handleDateChange}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                data-testid="dateTestId"
                            >
                                <MenuItem value={"selected"} disabled className={classes.placeHolderMenu}>{configJSON.dateRangePlaceholder}</MenuItem>
                                {daterangeOptionsData.map((dateValue) => {
                                    return (
                                        <MenuItem key={dateValue} value={dateValue} className={classes.selectMenubox}>
                                            {dateValue}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Box>
                    <Box className={classes.discrepancyBox}>
                        <InputLabel id="label">Discrepancy</InputLabel>
                        <Box className={classes.PrettoSliderBox}>
                            <Slider
                                value={sliderValue}
                                onChange={handleSliderChange}
                                aria-labelledby="range-slider"
                                data-testid="sliderTestId"
                                min={minNumberSilder}
                                max={maxNumberSilder}
                                className={classes.slider}
                            />
                        </Box>
                        <Box className={classes.minMaxValueBox}>
                            <Box className={classes.minValueBox}>
                                <InputLabel id="label">{configJSON.minTitle}</InputLabel>
                                <NumericFormat
                                    value={MinSliderVal}
                                    placeholder="$ 100"
                                    prefix="$ "
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={4}
                                    onValueChange={(values) => handleMinSliderChange(values.floatValue)}
                                    customInput={TextField}
                                    data-testid="minSliderTestId"
                                    variant="outlined"
                                />
                            </Box>
                            <Box>
                                <InputLabel id="label">{configJSON.maxTitle}</InputLabel>
                                <NumericFormat
                                    value={MaxSliderVal}
                                    placeholder="$ 200"
                                    prefix="$ "
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={4}
                                    onValueChange={(values) => handleMaxSliderChange(values.floatValue)}
                                    customInput={TextField}
                                    data-testid="maxSliderTestId"
                                    variant="outlined"
                                />
                            </Box>
                        </Box>
                        {
                            isCustomValue === "Custom" &&
                            <Box className={classes.dateBox}>
                                <Box className={classes.minValueBox}>
                                    <InputLabel id="label">From</InputLabel>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <StartDateCalendar
                                                 autoOk
                                                 disableToolbar
                                                 variant="inline"
                                                 format="MM-DD-YYYY"
                                                 margin="normal"
                                                 value={dateVal}
                                                 placeholder="MM-DD-YYYY"
                                                 onChange={handleFromDateChange}
                                                 KeyboardButtonProps={{
                                                   'aria-label': 'change date',
                                                 }}
                                                 InputProps={{
                                                   disableUnderline: true,
                                                 }}
                                                 data-testid="datechangeId"
                                                 className={classes.datePicker} 
                                                 keyboardIcon={<img src={calendarImage}  alt="fromimage" />
                                               }
                                            />
                                    </MuiPickersUtilsProvider>
                                </Box>
                                <Box >
                                    <InputLabel id="label">To</InputLabel>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <StartDateCalendar
                                                 autoOk
                                                 disableToolbar
                                                 variant="inline"
                                                 format="MM-DD-YYYY"
                                                 margin="normal"
                                                 value={toDateVal}
                                                 placeholder="MM-DD-YYYY"
                                                 data-testid="toDateChangeId"
                                                 disabled={endDateDisabledVal}
                                                 onChange={handleToDateChange}
                                                 KeyboardButtonProps={{
                                                   'aria-label': 'change date',
                                                 }}
                                                 InputProps={{
                                                   disableUnderline: true,
                                                 }}
                                                 className={classes.datePicker} 
                                                 keyboardIcon={<img src={calendarImage}  alt="toimage" />
                                               }
                                            />
                                        </MuiPickersUtilsProvider>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box className={classes.buttonBox}>
                    <Button variant="outlined" onClick={resetAllHandler} data-testid="resetAllHandlerTestId">Reset All</Button>
                    {
                        (vendorName !== "" || statusValue !== "" || isCustomValue !== "" || MinSliderVal !== null || MaxSliderVal !== null) ?
                            < Button variant="contained" onClick={filterApplyHandler} data-testid="filterApplyHandlerTestid">Apply</Button>
                            :
                            < Button variant="contained" onClick={filterApplyHandler} disabled data-testid="filterApplyHandlerTestid">Apply</Button>
                    }
                </Box>
            </Dialog >
        </>
    )
}

export const TablebodyCell = (props: TablebodyCellProps) => {
    const { index, classes, project_name, created_at, status, report_created, reports_without_invoice, total_discrepancy, last_modified } = props
    return (
        <>
            <TableCell
                className={classes.IndexBox}
               align="center"
            >
                {index + 1} 
            </TableCell>
            <TableCell className={classes.Project_nameBox} align="center" ><span>{project_name}</span></TableCell>
            <TableCell className={classes.CenterBox} align="center" >{created_at ? moment(created_at).format('MM-DD-YYYY, hh:mm A') : "-"}</TableCell>
            <TableCell className={classes.StatusBox} align="center" style={{ color: status === "active" ? "green" : "red", textTransform: "capitalize" }} ><span>{status || "-"}</span></TableCell>
            <TableCell className={classes.CenterBox} align="center" >
            <span >{report_created}</span>
            </TableCell >
            <TableCell className={classes.CenterBox} align="center">
            <span >{reports_without_invoice}</span>
            </TableCell>
            <TableCell className={classes.CenterBox} align="center"><span >{last_modified ? moment(last_modified).format('MM-DD-YYYY, hh:mm A') : "-"}</span></TableCell>
            <TableCell className={classes.CenterBox} align="center">
            <span >$ {total_discrepancy}</span>           
            </TableCell>
        </>
    )
}

// Customizable Area End

class Projects extends ProjectsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    modelUploadInvoice = () => {

        return (
            <Dialog open={this.state.uploadInvoiceOpenModel} onClose={this.handleClose} PaperProps={{ style: { borderRadius: "16px", width: '30%', overflow: 'inherit' } }}>

                <DialogTitle><Typography style={webStyle.modelHeading}>{configJSON.uploadInvoice}</Typography></DialogTitle>

                <DialogContent>
                    <Typography style={webStyle.modelProject}>{configJSON.modelProject} </Typography>
                    <Box data-test-id='projectSelect' onClick={ this.selectProject } style={{ ...webStyle.modelProjectPlaceholder, border: `1px solid ${!this.state.projectEmptyValue ? '#000000' : '#FF0000'}`, }}>{this.state.projectValue.length > 0 ? this.state.projectValue : 'Select project'}   <ExpandMoreIcon /></Box>
                    {this.state.uploadInvoiceProjectDataFlag &&
                        <Box style={webStyle.projectSelectList}>
                            <TextField variant="outlined" placeholder="Search in projects..." data-testId='projectSearch' onChange={this.handleProjectSearch} value={this.state.searchProjectQuery} fullWidth InputProps={{ startAdornment: (<SearchIcon style={{ marginRight: 8 }} />) }} />
                            {this.state.projectFilteredData.map((item: any, index: any) => (
                                <option key={index}
                                    value={item.value} data-testId='projectOption' onClick={() => this.setState({ projectValue: item.value, uploadInvoiceProjectDataFlag: false, projectId: item.id })}
                                    style={{ ...webStyle.bothOptions, borderBottom: index === this.state.projectFilteredData.length - 1 ? "none" : "1px solid #8080803b" }}
                                >
                                    {item.value}
                                </option>
                            ))}
                        </Box>
                    }
                </DialogContent>

                <DialogContent>
                    <Typography style={webStyle.modelReportheading}>{configJSON.modelReport}</Typography>
                    <Box data-testId='reportSelect' onClick={ this.selectReport } style={{ ...webStyle.modelReportPlaceholder, border: `1px solid ${!this.state.reportEmptyValue ? '#000000' : '#FF0000'}`, }}>{this.state.ReportValue.length > 0 ? this.state.ReportValue : 'Select report'} <ExpandMoreIcon /></Box>

                    {this.state.ReportDataFlag &&
                        <Box style={webStyle.reportSelectList}>
                            <TextField variant="outlined" data-testId='reportSearch' placeholder="Search in reports..." onChange={this.handleReportSerach} value={this.state.searchReportQuery} fullWidth InputProps={{ startAdornment: (<SearchIcon style={{ marginRight: 8 }} />) }} />
                            {this.state.reportFilteredData.map((item: any, index: any) => (
                                <option key={index}
                                    value={item.value} data-testId='reportOption'
                                    onClick={() => this.setState({ ReportValue: item.value, ReportDataFlag: false })}
                                    style={{ ...webStyle.bothOptions, borderBottom: index === this.state.reportFilteredData.length - 1 ? "none" : "1px solid #8080803b" }}
                                >
                                    {item.value}
                                </option>
                            ))}
                        </Box>
                    }
                </DialogContent>
                <Grid style={webStyle.modelBothButton}>
                    <Button style={webStyle.modelCancleButton} variant="outlined" onClick={this.handleCloseModel} > {configJSON.modelCancleButton} </Button>
                    <NavLink style={webStyle.buttonLink} data-test-id="navigate-btn" to={{ pathname: "/report/create-report", state: { projectValue: this.state.projectId, reportValue: this.state.ReportValue, value: 2,continueBtnClicked:true } }} onClick={(e) => this.handleEmptyValue(e)}>
                        <Button variant="outlined" style={webStyle.modelCountinueButton}>{configJSON.modelCountinueButton} </Button>
                    </NavLink>
                </Grid>
            </Dialog>
        )
    }

    sortingLable = (projectsList: columnProject) => {
        return (
            <TableSortLabel data-testId={"sortDataId"} IconComponent={projectsList.label === "Action" ? undefined : TableSortIcon} onClick={() => { this.sortClick(projectsList.key) }}>
                {projectsList.label}
            </TableSortLabel>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        
        const { classes, navigation, handleSaveData } = this.props
        const {dataChipArray, reportProjects, columnsProject } = this.state

        const keyMappings: any = {
            "Date Created": "created_at",
            "Reports Created": "report_created",
            "Report without invoice": "reports_without_invoice",
            "Project Name": "project_name",
            "Status": "status",
            "Last Modified": "last_modified",
            "Total Discrepancy": "total_discrepancy"
        };

        const csvData = [
            [...columnsProject
                .filter((heading) => heading.label !== 'Action')
                .map(heading => heading.label)],
            ...reportProjects.map((item: { [x: string]: string; }, index: number) => {
                return [
                    index + 1,
                    ...columnsProject.map(heading => {
                        let value = "";

                        const key = keyMappings[heading.label] || '';
                        value = item[key];

                        if (value === undefined) {
                            const adjustedKey = heading.label.split(" ").join("_").toLowerCase();
                            value = item[adjustedKey];
                        }

                        return value !== undefined ? value : null;
                    }).filter(value => value !== null)
                ];
            })
        ];


        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <AddProjectNameDialog addNameErrMsg={this.state.addNameErrMsg} addProject={this.handleAddProjectName} inputName={this.state.inputName} handleInputChange={this.handleInputNameChange} title={`Add a new project`} type="project" handleClose={this.handleAddNameDialogClose} handleOpen={this.state.openAddNameDialog} />
                <Box>
                    <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
                    <FilterDialog
                        classes={classes}
                        StatusOptionsData={this.state.StatusOptionsData}
                        handleVendorChange={this.handleVendorChange}
                        vendorName={this.state.vendorName}
                        daterangeOptionsData={this.state.daterangeOptionsData}
                        handleDateChange={this.handleDateChange}
                        handleClose={this.handleClose}
                        handleOpen={this.state.openDialog}
                        isCustomValue={this.state.isCustomValue}
                        sliderValue={this.state.sliderValue}
                        handleSliderChange={this.handleSliderChange}
                        statusValue={this.state.statusValue}
                        minNumberSilder={this.state.minNumberSilder}
                        maxNumberSilder={this.state.maxNumberSilder}
                        handleStatusChange={this.handleStatusChange}
                        handleMinSliderChange={this.handleMinSliderChange}
                        handleMaxSliderChange={this.handleMaxSliderChange}
                        MinSliderVal={this.state.MinSliderVal}
                        MaxSliderVal={this.state.MaxSliderVal}
                        filterApplyHandler={this.filterApplyHandler}
                        handleFromDateChange={this.handleFromDateChange}
                        dateVal={this.state.dateVal}
                        endDateDisabledVal={this.state.endDateDisabledVal}
                        vendorListData={this.state.vendorListData}
                        handleToDateChange={this.handleToDateChange}
                        toDateVal={this.state.toDateVal}
                        resetAllHandler={this.resetAllHandler}
                    />
                    <Grid container direction="row" spacing={6} style={webStyle.fontColor}>
                        <Grid item xs={12} md={4} lg={4}>
                            <DashboardNavNewCard
                                className="a"
                                data-testid="toggleDataId"
                                img={create_new_project}
                                imageStyle={webStyle.uploadImage}
                                textStyle={webStyle.compareTextStyle}
                                handleOnClick={this.handleOpenDialog}
                                actionCardText={configJSON.addNewProject}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <NavLink activeStyle={{ textDecoration: 'none' }} style={webStyle.linkStyle} to="/report/create-report">
                                <DashboardNavNewCard
                                    className="a"
                                    img={create_new_invoice}
                                    imageStyle={webStyle.generateImgStyle}
                                    textStyle={webStyle.generateTextStyle}
                                    actionCardText={configJSON.createNewReport}
                                />
                            </NavLink>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <DashboardNavNewCard
                                datatestid='openModal'
                                className="a"
                                img={upload_invoice}
                                imageStyle={webStyle.uploadImage}
                                textStyle={webStyle.compareTextStyle}
                                handleOnClick={() => this.handleOpenModel()}
                                actionCardText={configJSON.uploadInvoice}
                            />

                            {this.state.uploadInvoiceOpenModel && (
                                this.modelUploadInvoice()
                            )}
                        </Grid>
                    </Grid>
                    <Typography variant="h5" className={classes?.projectsTitle}>{configJSON.projectsTitle}</Typography>
                    <Box className={classes?.containerforProject}>
                        <Box className={classes?.searchMainBox}>
                            <Box className={classes?.projectSearchBox}>
                                <Box className={classes?.projectSearchIconBox}>
                                    <SearchIcon />
                                    <Textfield
                                        style={webStyle.projectTextInpurStyle}
                                        placeholder="Search in projects..."
                                        data-testId="searchDataId"
                                        onChange={this.projectSearch}
                                        value={this.state.projectSearchValue}
                                        autoComplete="on"                                        
                                    />
                                </Box>
                                <StyledBadge onClick={this.handleOpen} badgeContent={this.state.filterTotalValue}>
                                    <TuneIcon style={{transform: 'rotate(-90deg)'}}/>
                                </StyledBadge>
                            </Box>
                            {dataChipArray.map((data, index) => {
                                let labelParts = ['', ''];
                                if (typeof data.label === 'string') {
                                    labelParts = data.label.split(': ');
                                }
                                return (
                                    <Box className={classes?.ChipAdjust}>
                                        <Chip
                                        
                                            key={index}
                                            className={classes?.ChipStyleForMiniSearch}
                                            label={
                                                <Typography component="span">
                                                    {labelParts[0]} : <span style={{ fontWeight: 'bold' }}>{labelParts[1]}</span>
                                                </Typography>
                                            }
                                            deleteIcon={<CloseIcon />}
                                            onDelete={() => this.handleDelete(data)}
                                        />
                                    </Box>
                                );
                            })}
                        </Box>

                        <Box className={classes?.downloadCsvBox}>
                            <Button>
                                <CSVLink data={csvData} filename={"data.csv"}>
                                    <img src={exportCSV} /><Typography>{configJSON.downloadCSV}</Typography>
                                </CSVLink>
                            </Button>
                        </Box>
                    </Box>
                    <TableContainer style={webStyle.tableProjectsContainerStyle}>
                        <Table className={classes?.tableProjectBox} >
                            <TableHead>
                                <TableRow>
                                    {this.state.columnsProject.map((projectsList, index) => (
                                        <TableCell
                                            key={projectsList.id}
                                            style={webStyle.tableProjectsCellStyle}
                                            align="center"
                                        >
                                            {this.sortingLable(projectsList)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody data-testid="tableBodyId" className={classes?.styledTableBody}>
                                {this.state.isLoading ? <CircularProgress style={{ position: 'absolute', left: '55%', marginTop: '2%' }} />
                                    : <>
                                        {this.state.reportProjects.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                            .map((item: any, index: any) => {
                                                return (
                                                    <StyledTableRowItems
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                    >

                                                        <TablebodyCell
                                                            index={item.idx-1}
                                                            classes={classes}
                                                            project_name={item.project_name}
                                                            created_at={item.created_at}
                                                            status={item.status}
                                                            report_created={item.report_created}
                                                            reports_without_invoice={item.reports_without_invoice}
                                                            total_discrepancy={item.total_discrepancy}
                                                            last_modified={item.last_modified}
                                                        />

                                                        <TableCell align="center">
                                                            <MoreVertIcon data-testid="iconId" onClick={(event: any) => this.handleOpenMenu(event, index, item)} />
                                                             
                                                            <StyledTableMenuItem
                                                                data-testid="styledTableMenuItemId"
                                                                id="simple-menu"
                                                                anchorEl={this.state.anchorEl}
                                                                keepMounted
                                                                open={Boolean(this.state.anchorEl)}
                                                                onClose={this.handleClickCloseMenu}
                                                            >
                                                                <MenuItem
                                                                    onClick={() => this.navigateToViewReport(this.state.projectItem.project_id, this.state.projectItem.project_name)}
                                                                    data-testid="TableMenuOptionsOpenerId">
                                                                    View report
                                                                </MenuItem>
                                                                <MenuItem onClick={this.handleDeactivateStatus} data-testid="TableMenuOptionsId">
                                                                    {this.state.projectItem.status === "active" ? "Deactivate Project" : "Activate Project"}
                                                                </MenuItem>
                                                            </StyledTableMenuItem>
                                                        </TableCell>
                                                    </StyledTableRowItems>
                                                )
                                            })}
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableFooter reportProjects={this.state.reportProjects.length} page={this.state.page} handleClickChangePage={this.handleClickChangePage} rowsPerPage={this.state.rowsPerPage} handleChangePerPage={this.handleChangePerPage} isLoading={this.state.isLoading} classes={classes} />
                </Box >
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconImageWrapper: {
            borderRadius: "13px",
            marginLeft: "20px"
        },

        actionDataTxt: {
            fontFamily: "Poppins",
            fontSize: "22px",
            fontWeight: 700,
            width: "100%",
            textAlign: "center"
        },
        iconProjectImageWrapper: {
            borderRadius: "13px",
            marginLeft: "20px"
        },
        iconImage: {

        },
        actionprojectText: {
            fontSize: "22px",
            fontWeight: 700,
            fontFamily: "Poppins",
            width: "100%",
            textAlign: "center"
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '200px',
        },
        paginated: {
            "& .MuiPagination-ul": {
                justifyContent: 'flex-end',
            },
            "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: "orange",

                borderRadius: 12,
                color: "blue"
            },
            "& .MuiPaginationItem-icon": {
                backgroundColor: "orange",
                padding: 1,
                borderRadius: 2
            }
        },
    })
);
const webStyle = {
    actionCardWebStyle: {
        height: "100px",
        borderRadius: "16px",
        backgroundColor: "#ffffff",
        border: "0px solid #39b68e",
        width: "100%",
        display: "flex",
        alignItems: "center",
        cursor: 'pointer'
    },
    fontColor: {
        color: 'black'
    },
    uploadImage: {
        opacity: "0.655",
        backgroundImage:
            "linear-gradient(180deg, rgba(198,255,230,0.55) 0%, #96e0c8 100%)"
    },
    projectTextInpurStyle: {
        width: "300px",
        marginRight: "15px",
        color: "red"
    },
    compareTextStyle: {
        color: " #4670b6"
    },

    generateImgStyle: {
        backgroundImage:
            "linear-gradient(180deg, #fefadb 0%, #ffeba8 78%, #ffe79a 100%)"
    },
    generateTextStyle: {
        color: " #df802d"
    },
    linkStyle: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },

    uploadProjectImg: {
        opacity: "0.655",
        backgroundImage:
            "linear-gradient(180deg, rgba(198,255,230,0.55) 0%, #96e0c8 100%)"
    },
    noDataContentstyle: {
        margin: 'auto',
        textAlign: 'center' as 'center',
        marginTop: '5%',
        fontSize: '18px',
        fontWeight: 550
    },
    compareText: {
        color: " #4670b6"
    },
    generateImgBox: {
        backgroundImage:
            "linear-gradient(180deg, #fefadb 0%, #ffeba8 78%, #ffe79a 100%)"
    },
    generateContent: {
        color: " #df802d"
    },
    navLinkProjectStyle: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },

    navProjectActionCard: {
        height: "100px",
        borderRadius: "16px",
        backgroundColor: "#ffffff",
        border: "0px solid #39b68e",
        width: "100%",
        display: "flex",
        alignItems: "center",
        cursor: 'pointer',
        padding: '0 60px'
    },
    tableProjectsContainerStyle: {
        marginTop: '2%',
        overflowX: "auto" as "auto"
    },
    tableProjectsCellStyle: {
        fontSize: '18px',
        fontWeight: 550
    },
    modelHeading: {
        padding: "10px 0",
        fontWeight: 600,
        textAlign: "center" as 'center',
        fontSize: '21px',
        color: "#141423",
    },
    projHeading: {
        marginLeft:"-30px"
    },
    statusHeading: {
        marginLeft:"8px"
    },
    modelProject: {
        fontSize: "19px",
        fontWeight: 400,
        color: "#141423",
    },
    modelProjectPlaceholder: {
        display: 'flex',
        borderRadius: '5px',
        padding: '18px',
        border: '1px solid',
        justifyContent: 'space-between',
        marginTop: '10px',
        fontSize: '17px',
        color: '#424040',
        position: 'relative' as 'relative',
        cursor: "pointer",
    },
    projectSelectList: {
        zIndex: 1,
        border: '1px solid gray',
        width: '89%',
        borderRadius: '5px',
        overflowY: 'scroll' as "scroll",
        height: "40%",
        position: 'absolute' as 'absolute',
        padding: '12px',
        background: 'white',
    },
    bothOptions: {
        fontSize: "18px",
        padding: "12px 0px 12px 6px"
    },
    modelReportheading: {
        marginTop: '10px',
        fontSize: "19px",
        fontWeight: 400,
        color: "#141423",
    },
    modelReportPlaceholder: {
        position: 'relative' as 'relative',
        border: '1px solid',
        borderRadius: '5px',
        padding: '18px',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        fontSize: '17px',
        color: '#424040',
        cursor: "pointer"
    },
    reportSelectList: {
        position: 'absolute' as 'absolute',
        border: '1px solid gray',
        zIndex: 1,
        background: 'white',
        width: '89%',
        padding: '12px',
        borderRadius: '5px',
        overflowY: 'scroll' as "scroll",
        height: "40%"
    },
    modelBothButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0px 30px 0px",
        gap: "15px",
        marginTop: '15px',
    },
    modelCancleButton: {
        height: "50px",
        width: "30%",
        border: "1px solid #05052b",
        textTransform: "none" as "none",
        fontSize: '16px'
    },
    buttonLink: {
        width: "30%"
    },
    modelCountinueButton: {
        height: "50px",
        width: "100%",
        backgroundColor: "#05052b",
        color: "#ffff",
        textTransform: "none" as "none",
        fontSize: '16px'
    }
}
export const ProjectsTableStyle = (theme: any) => ({
    projectsTitle: {
        color: '#000',
        margin: '30px 0',
        fontWeight: 600
    },

    searchMainBox: {
        display: 'flex',
        justifyContent: 'space-between',
        gap:'10px',
        overflowX: 'auto' as 'auto',
    },
    projectSearchBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px',
        background: '#fff',
        padding: '0 20px !important',
        height: '60px',
        boxShadow: '0 0 0 2px #eee',
        '& svg:nth-child(2)': {
            transform: 'rotate(90deg)',
            transformOrigin: '50% 50%',
        }
    },
    projectSearchIconBox: {
        display: 'inline-flex',
        '& svg': {
            marginRight: '10px',
            marginTop: '2px',
            fontSize: '25px',

        },
        '& p': {
            fontSize: '18px'
        }
    },
    menuBox: {
        '& .MuiSelect-select.MuiSelect-select': {
            background: '#fff',
            boxShadow: '0 0 0 2px #eee',
            color: '#000',
            width: '124px',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '25px',
            '& span': {
                fontSize: '18px',
                fontWeight: '500'
            }
        },
        '& svg': {
            marginTop: '-2px',
            fontSize: '30px',
            marginRight: '7px'
        },
        '& fieldset': {
            display: 'none'
        }
    },
    projectSelectBox: {
        display: 'inline-flex',
        alignItems: 'center',
        height: '60px',
        '& p': {
            fontSize: '16px',
            color: '#000',
            marginRight: '8px',
            marginLeft: '10px'
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            display: 'none'
        },
        '& form': {
            marginBottom: 0
        },
        '& .MuiFormControl-root': {
            background: '#fff',
            margin: '0 10px',
            boxShadow: '0 0 12px #eee',
            height: '60px',
            padding: '12px',
            width: '200px',
            display: 'inline-block !important',
        },
        '& .MuiInput-underline::before': {
            display: 'none'
        },
        '& .MuiInput-underline::after': {
            display: 'none'
        },
        '& label + .MuiInput-formControl': {
            marginTop: 0,
            width: '130px'
        },
        '& .MuiInputLabel-formControl': {
            position: 'unset',
            display: 'inline-block'
        }
    },
    selectMenubox: {
        color: '#000',
        '& .MuiMenu-list li.MuiListItem-root': {
            padding: 0,
            border: 'none',
            background: 'transparent',
        },
        '& .MuiPaper-root .MuiListItem-root:hover': {
            background: 'transparent !important',
        },
        '&:hover': {
            background: 'transparent !important',
            color: '#000 !important'
        },
        '&.MuiListItem-root.Mui-selected': {
            background: 'transparent !important',
            color: '#000 !important',
            '& svg': {
                color: '#05052b'
            }
        },
        '&.MuiListItem-root.Mui-selected:hover': {
            background: 'transparent !important',
            color: '#000 !important',

        },
        '&.MuiListItem-root': {
            padding: '10px !important',
        }
    },
    containerforProject: {
        display: "flex",
        justifyContent: "space-between"
    },
    downloadCsvBox: {
        height: '60px',
        background: '#fff',
        boxShadow: '0 0 2px #eee',
        display: 'flex',
        padding: '0 15px',
        '& button': {
            textTransform: 'Capitalize',
            padding: '0 5px',
            '& a': {
                color: '#000'
            },
            '& p': {
                fontWeight: 500,
                display: 'inline',
                fontSize: '14px'
            },
            '& img': {
                marginRight: '10px',
                width: '20px',
                height: '20px'
            }
        }
    }, ChipAdjust: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    ChipStyleForMiniSearch: {
        backgroundColor: '#FFFFFF',
        color: 'black',
        borderColor: '#DF802D',
        borderRadius:"20px",
        height:"39px",
        borderWidth: 1,
        padding: "12px 7px",
        borderStyle: 'solid',
        '& .MuiChip-deleteIcon': {
            color: "black",
            backgroundColor: 'transparent !important',
        },
    },
    tableProjectBox: {
        '& thead': {
            background: '#fff'
        }
    },
    dialogBoxStyle: {
        "& .MuiPaper-root": {
            width: "800px",
            maxWidth: "800px",
            padding: '25px',
            borderRadius: '15px',
        },
        "& .MuiDialog-container": {
            height: "auto"
        },
        '& .MuiOutlinedInput-root': {
            background: '#fff',
            width: '100%',
            border: '1px solid #bdbdbd'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'transparent',
        },
        '& .MuiSelect-select:focus': {
            background: '#fff',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            outline: 'none',
        },
    },
    filterBox: {
        display: "flex",
        justifyContent: "space-between",
        '& svg': {
            padding: '4px',
            borderRadius: '6px',
            border: '1px solid #545355'
        },
        '& h6': {
            fontWeight: 600
        }
    },
    selectMenuBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    vendorOptionBox: {
        width: '90%',
        marginRight: '5%',
    },
    discrepancyBox: {
        width: '90%',
        marginTop: '39px',
        '& label': {
            fontSize: "14px",
            fontWeight: 500,
            color: "#000"
        },
    },
    vendorBox: {
        marginTop: '20px',
        '& label': {
            marginBottom: "5px",
            fontSize: "14px",
            fontWeight: 500,
            color: "#000",
        },
        
    },
    PrettoSliderBox: {
        padding: "2px 23px 3px 17px",
        border: "1px solid #bdbdbd",
        borderRadius: "25px",
        marginTop: '6px'
    },
    slider: {
        color: '#000',
        height: 0,
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-thumb': {
            height: 18,
            width: 18,
            marginTop: "-8px",
            backgroundColor: '#fff',
            border: '2px solid black',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: '#52af77',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
                transform: 'rotate(45deg)',
            },
        },
    },
    minMaxValueBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '28px',
        '& label': {
            marginBottom: "5px",
            fontSize: "14px",
            fontWeight: 500,
            color: "#000"
        },
    },
    minValueBox: {
        marginRight: '10px'
    },
    dateBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '22px',
        '& label': {
            marginBottom: "4px",
            fontSize: "14px",
            fontWeight: 500,
            color: "#000"
        },
    },
    buttonBox: {
        textAlign: "center" as 'center',
        marginTop: "45px",
        '& button': {
            textTransform: "none",
            marginRight: "20px",
            border: "1px solid black",
            padding: '10px 40px',
            background: '#fff',
            fontWeight: 500,
            '&:hover': {
                background: '#000',
                color: '#fff'
            }
        }
    },
    placeHolderMenu: {
        display: 'none',
    },
    highlightItemBox: {
        display: 'inline-block !important',
        '& .react-pdf__Page__textContent': {
            height: '100% !important'
        },
    },

    IndexBox :{
        scope:"row",
        padding:"none",
        align:"center"
    },
    Project_nameBox:{
        maxWidth: '200px', 
        textOverflow: 'ellipsis', 
        overflow: 'hidden',
        scope: "row",
        padding: "none",
        align: "center"
    },
    CenterBox:{
        align: 'center'
    },
    StatusBox:{
        
    }
})

export { Projects }
export default withStyles(ProjectsTableStyle)(Projects)

const Textfield = styled(TextField)({
    "& .MuiInput-underline::before":{
        borderBottom: "none",
        transition: "none",
     },
     "& .MuiInput-underline::after":{
        borderBottom: "none"
     },
     "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
        borderBottom: "none"
    },
});

// Customizable Area End
