Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.updateAccountApiMethodType = "PUT";
exports.patchApiMethodType = "PATCH";
exports.deleteApiMethodType = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.addNewProject = "Add a New Project";
exports.createNewReport = "Create a New Report";
exports.uploadQuote = "Upload Quote";
exports.uploadInvoice = "Upload an Invoice";
exports.totalDiscrepancy = "Total discrepancy";
exports.noDataAvailable = "No data available";
exports.createReportAndQuote = "Create reports and upload quotes or Invoices to get started...";
exports.totalLiveProjects = "Total Live Projects"
exports.comparedLastWeek = "Compared to last week"
exports.totalReportGenerated = "Total Reports Generated"
exports.totalInvoiceAmount = "Total invoice amount"
exports.totalQuoteAmount = "Total quote amount"
exports.reportGeneratedWithoutInvoice = "Total Reports Generated without Invoice"
exports.reportWithoutInvoice = "Report without invoice"
exports.popupProject = "Project";
exports.popupReport = 'Report';
exports.popupCancleButton = "Cancel";
exports.popupCountinueButton = "Continue";
exports.emailRegex = /^(?=[^\s@]{1,256})(?=[^\s@])[^@]{1,64}@(?=[^\s@])[^@]{1,255}\.(?=[^\s@])[^@]{2,}$/;

exports.forgotPasswordApiEndPoint ="bx_block_forgot_password/passwords/forgot";
exports.resetPasswordApiEndPoint ="bx_block_forgot_password/passwords";
exports.loginUserApiEndPoint ="bx_block_login/logins";
exports.fileUploadApiEndPoint = "bx_block_filter_items/quotes_libraries";
exports.addProjectEndPoint = "bx_block_projects/projects"
exports.fileUploadApiMethod = "POST";
exports.fileUploadFormDataContentType = "multipart/form-data";
exports.fileUploadApiContentType = "application/json";
exports.fileGetApiMethod = "Get";
exports.quoteUploadDeleteApiMethod = "DELETE"
exports.invoiceFileUploadApiEndPoint = "bx_block_filter_items/quotes_libraries/invoice_against_quote_upload";
exports.getQuoteApiEndPoint = "bx_block_filter_items/quotes_libraries/upload_file"
exports.getInvoiceApiEndPoint = "bx_block_filter_items/invoice_libraries";
exports.invoicePdfOpenEndPoint = "bx_block_filter_items/invoice_libraries/invoice_pdf_open?"
exports.quotePdfOpenEndPoint = "bx_block_filter_items/quotes_libraries/quote_pdf_open?"
exports.btnExampleTitle = "CLICK ME";
exports.advanceSearchApiEndPoint = "bx_block_filter_items/search/search";
exports.getProjectsApiEndPoint = "bx_block_filter_items/search/projects";
exports.getVendorsApiEndPoint = "bx_block_filter_items/search/vendors";
exports.getDocumentTypeApiEndPoint = "bx_block_filter_items/search/document_type";
exports.getkeywordsApiEndPoint = "bx_block_filter_items/search/key_words";
exports.quoteUploadDeleteEndPoint = "bx_block_filter_items/quotes_libraries";
exports.invoiceUploadDeleteEndPoint = "bx_block_filter_items/invoice_libraries";
exports.getUserDetailsApiEndPoint = "account_block/accounts/:id";
exports.updateEmilEndpoint = "account_block/accounts";
exports.getPasswordUpdatedApiEndPoint = "bx_block_forgot_password/passwords/change_password";
exports.projectsDeleteEndPoint = "bx_block_projects/projects"
exports.documentComparisonDeleteEndPoint = "bx_block_reports/reports/delete_document"
exports.quotesComparisonAPiEndPoint = "bx_block_filter_items/quotes_libraries/comparison_file";
exports.reportWithoutInvoiceAPiEndPoint = "/bx_block_reports/reports/report_invoice_dashboard";
exports.getVendorsEndPoint = "account_block/vendors";
exports.getReasonsEndPoint = "account_block/delete_reasons";
exports.getProjectsApiEndPoint = "bx_block_analytics9/dashboard_variations/dashboard_filter";
exports.vendorChangeApiEndPoint = "bx_block_reports/reports/dashboard_data_by_vendor";
exports.projectDataSelected = "bx_block_projects/projects/all_project";
exports.reportDataSelected = "bx_block_reports/reports/all_report";
exports.videoURLEndpoint = "bx_block_landingpage3//home_page_videos";
exports.quoteUploadDeleteApiMethod = "GET";
exports.voiceOfUsersEndpoint = "bx_block_landingpage3/voice_of_users";
exports.voiceOfUsersApiMethod = "GET";
exports.deleteCheckError = "Please check the box to confirm account deletion";
exports.vendorsChartSelected = "bx_block_reports/reports/dashboard_data_vendors";
exports.bestMatches = "Best matches";
exports.globalSearchApiEndPoint = "bx_block_filter_items/search?query=";
// Customizable Area End
