Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.editQuoteDetailAPIMethod = "PUT";
exports.editQuoteDetailAPIMethodPATCH = "PATCH";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.editQuoteDetailAPIEndPoint = "bx_block_reports/reports";
exports.quoteDeleteApiEndPoint = "bx_block_reports/report_quote_descriptions";
exports.deleteSegmentAPI = "bx_block_reports/report_quote_descriptions/delete_multiple";
exports.addNewItemAPI = "bx_block_reports/reports/add_new_item";
exports.invoiceAddNewItemAPI = "bx_block_filter_items/invoice_libraries/add_new_invoice_item";
exports.getProjectsEndPoint = "bx_block_reports/reports/project_dashboard";
exports.updateProjectStatusEndPoint = "bx_block_reports/reports/toggle_project_status";
exports.addProjectNameEndPoint="bx_block_projects/projects";
exports.addSegmentEndPoint = "bx_block_reports/reports/add_segment";
exports.invoiceDeleteApi="bx_block_filter_items/invoice_libraries/delete_invoice_description?ids=";
exports.invoiceEditApi="bx_block_filter_items/invoice_libraries/update_invoice/";
exports.viewReportApi = "bx_block_projects/projects/project_reports_main?id="
exports.filterVendorsAPIEndPoint = "bx_block_reports/reports/filter_project_dashboard_data";
exports.vendorsAPIEndpoint = "account_block/vendors"
exports.reportDetailsApi = "bx_block_reports/reports/";
exports.filterReportsFilter = "bx_block_projects/projects/filter_report_dashboard_data";
exports.listVendorApiFilter = "bx_block_projects/projects/list_vendors?id="

exports.uploadInvoiceprojectDataSelected = "bx_block_projects/projects/all_project";
exports.invoicereportDataSelected ="bx_block_reports/reports/all_report";
exports.callApiContentType = "application/json";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";
exports.projectsTitle = "Projects";
exports.searcTitle = "Search in projects...";
exports.selectTimetitle = "Select time range";
exports.thisWeekTitle = "This week";
exports.addNewProject = "Add a New Project";
exports.createNewReport = "Create a New Report";
exports.uploadInvoice = "Upload an Invoice";
exports.downloadCSV = "Download CSV";
exports.noDataContent = "No Data Available"
exports.modelProject = "Project";
exports.modelReport ='Report';
exports.modelCancleButton ="Cancel"; 
exports.modelCountinueButton ="Continue";

exports.viewquoteTitle = "View Quote";
exports.quotedetailsContent = "Quote details";
exports.quoteNumberContent = "Quote Number";
exports.quoteDateContent = "Quote Date";
exports.expiryquoteTitle = "Quote Expiry Date";
exports.supplierdetailsContent = "Supplier details";
exports.supplierNameContent = "Supplier Name";
exports.supplierAddressContent = "Supplier Address";
exports.salesPersonTitle = "Sales Person";
exports.supplierContactContent = "Supplier Contact";
exports.projectdetailsContent = "Ship to (Project Details)";
exports.siteNameContent = "Site Name";
exports.siteAddressContent = "Site Address";
exports.contactPersonTitle = "Contact Person";
exports.siteContactContent = "Site Contact"
exports.itemdetailsContent = "Item details";
exports.RentalItemdetailsContent = "Rental item details";
exports.SalesItemdetailsContent = "Sales item details";
exports.segmentContent = "Subtotal";
exports.subTotalContent = "Subtotal/Gross Amount";
exports.chargesContent = "S&H Charges";
exports.otherchargesContent = "Other Charges";
exports.discountContent = "-Discount";
exports.taxContent = "Tax";
exports.totalContent = "Total";
exports.editQuote = "Edit quote";
exports.goBack = "Go Back";
exports.addNewItem = "Add New Item";
exports.atleastOneInvoice="At least one invoice uploaded";

exports.invoiceDashbord = "Dashboard";
exports.invoiceTitle = "Edit Invoice";
exports.invoiceDetails = "Invoice details";
exports.shipDate = "ship Date";
exports.invoiceNumberContent = "Invoice Number";
exports.invoiceDateContent = "Invoice Date";
exports.invoiceTerms = "Terms";
exports.invoiceSupplier = "Supplier details";
exports.invoiceShip = "Ship to(Project Details)";
exports.newSegment = "Add New Segment";
exports.invoiceCancel = "Cancel";
exports.invoiceSave = "Save";
exports.invoiceIosSwitch="This Invoice has item-wise tax";
exports.invoiceCategory = "Segment/Category";
exports.invoiceEnterCategory = "Enter name of category";
exports.invoiceCategoryTotal = "Segment/Category Total";
exports.invoiceBoxText ="No items in this Segment/Category";
exports.invoiceBoxTextBold ="Drag & Drop Selected items to move here";
exports.invoiceAllDeleteButton = "Delete selected items";

exports.viewInvoiceTitle = "View Invoice";
exports.editInvoice = "Edit Invoice";
exports.invoicedetailsContent = "Invoice details";
exports.invoiceNumberContent = "Invoice Number";
exports.invoiceShipDateContent = "Ship Date";
exports.invoiceDateContent = "Invoice Date";
exports.termsinvoiceTitle = "Terms";

exports.enterDatePlaceholder = "Please enter date";
exports.deleteSelectedButton = "Delete selected items";
exports.itemWiseTax = "This Quote has item-wise tax";
exports.deleteItemTitle = "Delete Item";
exports.deleteItemsTitle = "Delete Items";
exports.deleteItemContent = "Are you sure you want to delete this item?";
exports.deleteItemsContent = "Are you sure you want to delete this items?";
exports.deleteMultipleItemContent = "Deleted items cannot be recovered, but you can always add a new item";
exports.deleteSingleItemContent = "Delete item cannot be recovered, but you can always add a new item";
exports.deleteButton = "Delete";
exports.reportOf = "Reports of "
exports.reportTitle = "Search in reports...";
exports.totalInvoiceAmount = "Total invoice amount"
exports.totalQuoteAmount = "Total quote amount";
exports.discrepancyApiEndPoint = "bx_block_reports/reports/dashboard_data_by_vendor";
exports.deleteReportAPIEndPoint = "bx_block_reports/reports/delete_report_type?id="
exports.validationApiMethodType = "GET";
exports.searchReportEndPoint="/bx_block_projects/projects/filter_report_data?search=&id=";
exports.noDataAvailable = "No data available";
exports.createReportAndQuote = "Create reports and upload quotes or Invoices to get started...";
exports.discrepancyByVendorTitle = "Discrepancy by vendors";
exports.seeAll = "See all";


exports.filterTitle = "Filter";
exports.vendorTitle = "Vendor";
exports.vendorPlaceholder = "Select Vendor";
exports.statusTitle = "Status";
exports.selectPlaceholder="Select Status";
exports.dateCreatedTitle = "Date Created";
exports.dateRangePlaceholder = "Select date range";
exports.minTitle = "Min.";
exports.maxTitle = "Max.";
exports.allTitle = "All";
exports.totalVendor = "Total Unique Vendors";
exports.totalDiscrepancy = "Total discrepancy";
exports.sno = "S.no";
exports.quoteText = "Quote";
exports.invoiceText= "Invoice";

exports.subTotalText="Subtotal"
exports.subTotalOrGrossAmountText = "Subtotal/Gross Amount"
exports.sAndHChargesText = "S&H Charges"
exports.otherChargesText ="Other Charges"
exports.discountText = "Discount"
exports.taxText = "Tax"
exports.totalText = "Total"
exports.dayTitle = "A day";
exports.weekTitle = "A week";
exports.monthTitle = "A month";
exports.showRateTitle = "Show rates for";
exports.paginationPage = "Page";
exports.approvedPaymentstatus = "bx_block_reports/reports/approved_payment";

exports.ViewInvoiceScanResults = "View Invoice Scan Results";
exports.ViewInvoiceProjects = "Projects";
exports.invoiceUploadApiEndPoint = "bx_block_filter_items/invoice_libraries";
exports.invoiceUpload ="Upload another Invoice";
exports.validationMessage ="A max of 8 PDFs can be uploaded";
exports.loadingMessage = "Data not available";
exports.discrepancy = "Discrepancy summary";
exports.viewDiscrep = "View discrepancy summary";
exports.viewBred = "project";
exports.viewHeading = "Details";
exports.totalDiscrepancy = "Total Discrepancy";
exports.discreHeading = "Discrepancy Type";
exports.itemsHeading = "Name of Item";
exports.overcharged = "Overcharges only";
exports.undercharged = "Undercharged";
exports.discrepency = "Discrepancy";
exports.orderQty = "Order Qty.";
exports.unit = "Unit";
exports.unitPrice = "Unit Price";
exports.downloadSummary = "Download Summary";
exports.sendVendor = "Send to Vendor";
exports.sendEmail ="Email successfully sent"
exports.downloadpage = "Download Discrepancy Summary";
exports.overcharge = "Overcharge only report";
exports.undercharge = "Overcharge and undercharge report";
exports.pdfGenerate = "Generate";
exports.emailReport = "Email this Report";
exports.subject = "Subject";
exports.message = "Message";
exports.send = "Send";
exports.overchargeRep = "Overcharge report";
exports.quoteHighlight = "Quote with highlighting";
exports.invoiceHighlight = "Invoice with highlighting";
exports.includeUndercharge = "Include undercharges";
exports.summaryApi = "bx_block_reports/reports/discrepancy_summary?id=";
exports.filterSummaryApi = "bx_block_reports/reports/filter_common_product?key=";
exports.pdfUrl="https://www.orimi.com/pdf-test.pdf.pdf#toolbar=0"
exports.pdfName = "Anderson House Project - Overcharge and undercharge report.pdf";
exports.emailListApi = "bx_block_reports/reports/";
exports.quoteText = "QUOTE";
exports.invoiceText= "INVOICE"; 
exports.emailPlaceholder= "Enter sender's email address"; 
exports.emailSubject= "Enter subject"; 
exports.emailMessage= "Enter Message"; 
exports.invoiceText= "INVOICE"; 
exports.projectSearchApiEndPoint = "bx_block_reports/reports/filter_project_data?search=";
exports.sendEmailApi = "bx_block_reports/reports/8645/send_email_report";
exports.emailError = "Invalid email address";
exports.emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.confirmEmailHead= " Would you like to have this email associated with this vendor?"
exports.backBtn= "Back"; 
exports.proceed= "Proceed"; 
exports.getVendorsEndPoint= "account_block/vendors";
exports.venderHeading= "Sent to Vendor"; 
exports.dataHeading= "Date Sent"; 

exports.recipientHeading= "Recipient Name"; 
exports.recipientEmail= "Recipient Email"; 
exports.recipientSubject= "Email Subject"; 

exports.dispHeading= "Total Discrepancy"; 
exports.attachments= "Attachments"; 
exports.previewBtn= "Preview"; 
exports.downloadButton= "Download"; 
exports.pdfNames= " Overcharge and undercharge report.pdf"; 
exports.units = "2 mb";
exports.editInvoiceSaveButton = "bx_block_reports/reports/update_invoice/"
exports.reportType = "Report Type";
exports.downloadQuote = "Download Quote";
exports.downloadInvoice = "Download Invoice";
exports.updateVentor = "account_block/update_vendor";
// Customizable Area End