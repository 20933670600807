export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");

export const upload_invoice = require("../assets/upload_invoice.png")
export const create_new_project = require("../assets/create_new_project.png")
export const create_new_invoice = require("../assets/create_new_invoice.png")
export const exportCSV = require("../assets/Export csv.png")
export const editSvg = require("../assets/edit.svg")
export const deletePng = require("../assets/trash.png")
export const deleteIcon = require("../assets/deleteicon.png")
export const no_data = require("../assets/no_data.png")
export const resize = require("../assets/maximize.png")
export const upload_image = require("../assets/download_invoice.svg");
export const download_invoice = require("../assets/downloadInvoice.svg");
export const calendarImage = require("../assets/calendaricon.svg");
export const arrowIcon = require("../assets/arrow.svg");
export const sendIcon = require("../assets/send.svg");
export const checkBox = require("../assets/checkbox.svg");
export const download = require("../assets/download.svg");
export const sucess = require("../assets/success.svg");