// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import {
    bitmap,
    bitmaptwo,
    bitmapthree,
    bitmapfour,
    bitmapfive,
    bitmapsix,
    bitmapseven,
    bitmapeight,
    bitmapnine,
    bitmapten
} from "./assets";
import { ChangeEvent } from "react";

export const configJSON = require("./config");

export interface Props {
    anchorEl: any;
    classes: any;
    navigation: any;
    id: string;
    handleSaveData: () => {};
    history: any;
}

interface S {
    // Customizable Area Start
    profileDetails: any;
    profileImageUrl: any;
    isEditModalOpen: any;
    isChangeEmailModalOpen: any;
    isChangePasswordModalOpen: any;
    isChangeContactModalOpen: any;
    isPermanentDeleteModalOpen: any;
    isAccountDeleteModalOpen: any;
    isConfirmationDeleteModalOpen: any
    personalDetails: any;
    isEmailValid: any;
    showOldPassword: any;
    showNewPassword: any;
    passwordErrMsg: any;
    isPasswordValid: any;
    reasonValue: any;
    reasonItems: any;
    isEmailSentModalOpen: any;
    updatePasswordPopup:boolean;
    isAvatarModalOpen: any;
    image: any;
    editor: any;
    isProfileModalOpen: any;
    options: any;
    avatars: any;
    selectedAvatarIndex: any;
    contactErrMsg: any;
    errors: string;
    isChecked: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ProfileMainController extends BlockComponent<
    Props,
    S,
    SS
> {
    getProfileDetailsId: string = "";
    updateUserDetailsId: string = "";
    updateUserEmailId: string = "";
    updateUserPasswordId: string = "";
    updateContactId: string = "";
    deleteUserId: string = "";
    profileImageId: string = "";
    getReasonsId: string = '';
    deleteImageId: string = "";
    getLocationsId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            profileDetails: {},
            profileImageUrl: "",
            isEditModalOpen: false,
            updatePasswordPopup: false,
            isChangeEmailModalOpen: false,
            isChangePasswordModalOpen: false,
            isChangeContactModalOpen: false,
            isPermanentDeleteModalOpen: false,
            isAccountDeleteModalOpen: false,
            isConfirmationDeleteModalOpen: false,
            personalDetails: {
                name: '',
                companyName: '',
                location: '',
                jobTitle: '',
                email: '',
                old_password: '',
                new_password: '',
                phone_number: '',
                feedback: '',
            },
            isEmailValid: true,
            showOldPassword: false,
            showNewPassword: false,
            isEmailSentModalOpen: false,
            isAvatarModalOpen: false,
            isProfileModalOpen: false,
            passwordErrMsg: "",
            image: null,
            editor: null,
            isPasswordValid: {
                minimumEightCharacters: false,
                oneUpperCase: false,
                oneNumber: false,
                oneSpecialCharacter: false
            },
            reasonValue: "-1",
            reasonItems: [],
            options: [],
            avatars: [{ image: bitmap }, { image: bitmaptwo }, { image: bitmapthree }, { image: bitmapfour }, { image: bitmapfive }, { image: bitmapsix }, { image: bitmapseven }, { image: bitmapeight }, { image: bitmapnine }, { image: bitmapten }],
            selectedAvatarIndex: "",
            contactErrMsg: '',
            errors: "",
            isChecked: false
        };
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        this.getUserDetails()
    }

    componentDidUpdate(prevProps: any, prevState: any): void {
        if (prevState.personalDetails !== this.state.personalDetails && this.state.options.length === 0) {
            this.getLocations()
        }
    }

    getToken = () => {
        const token = document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
        return token;
    }

    getUserDetails = () => {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProfileDetailsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getUserDetailsApiEndPoint}?token=${this.getToken()}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    getLocations = () => {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getLocationsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.profileDetails.id}/all_location`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleEditor = (ref: any) => {
        this.setState({ editor: ref })
    }

    handleNavigateData =(value:any)=>{
      return value ? value : "/projects"
    }
    handleClickShowOldPassword = () => {
        this.setState({ showOldPassword: !this.state.showOldPassword })
    };

    handleClickShowNewPassword = () => {
        this.setState({ showNewPassword: !this.state.showNewPassword })
    };

    updateUserDetails = () => {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };
        const quoteGraphRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateUserDetailsId = quoteGraphRequestMessage.messageId;
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.profileDetails.id}?name=${this.state.personalDetails.name}&company_name=${this.state.personalDetails.company_name}&location=${this.state.personalDetails.location}&job_title=${this.state.personalDetails.job_title}&phone_number=${this.state.personalDetails.phone_number}`
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateAccountApiMethodType
        );
        runEngine.sendMessage(quoteGraphRequestMessage.id, quoteGraphRequestMessage);

        return true;
    }

    updateContactDetails = () => {
        const phoneNumberRegex = /^\d{10}$/;
        if (phoneNumberRegex.test(this.state.personalDetails.phone_number)) {
            const header = {
                "Content-Type": configJSON.fileUploadApiContentType,
                "token": this.getToken()
            };
            const contactMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.updateContactId = contactMessage.messageId;
            contactMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.updateEmilEndpoint}/${this.state.profileDetails.id}?name=${this.state.personalDetails.name}&company_name=${this.state.personalDetails.company_name}&location=${this.state.personalDetails.location}&job_title=${this.state.personalDetails.job_title}&phone_number=${this.state.personalDetails.phone_number}`
            );
            contactMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            contactMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.updateAccountApiMethodType
            );
            runEngine.sendMessage(contactMessage.id, contactMessage);
            return true;
        } else {
            this.setState({ contactErrMsg: 'Invalid phone number. Please enter a 10-digit number.' })
        }
    }

    updateUserEmail = () => {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };
        const quoteGraphRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateUserEmailId = quoteGraphRequestMessage.messageId;
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.profileDetails.id}/update_email?email=${this.state.personalDetails.email}`
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );
        runEngine.sendMessage(quoteGraphRequestMessage.id, quoteGraphRequestMessage);

        return true;
    }

    handleApplyImage = () => {
        if (this.state.editor && this.state.image) {
            const canvasDataUrl = this.state.editor.getImage().toDataURL();
            fetch(canvasDataUrl)
                .then((res) => res.blob())
                .then((blob) => {
                    const croppedImageFile = new File([blob], 'cropped-image.png', { type: 'image/png' });
                    this.updateImageApi(croppedImageFile)
                })
        }
    };

    handleApplyAvatar = () => {
        if (this.state.selectedAvatarIndex) {
            const selectedAvatar = this.state.avatars[this.state.selectedAvatarIndex]?.image
            fetch(selectedAvatar)
                .then((res) => res.blob())
                .then((blob) => {
                    const croppedImageFile = new File([blob], 'cropped-image.png', { type: 'image/png' });
                    this.updateImageApi(croppedImageFile)
                })
        }
    }

    updateImageApi = (croppedImageFile: any) => {
        const header = {
            "token": this.getToken()
        };

        const formData: any = new FormData();

        formData.append('profile_image', croppedImageFile);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.profileImageId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.profileDetails.id}/update_image`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    updateUserPassword = () => {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };
        const quoteGraphRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateUserPasswordId = quoteGraphRequestMessage.messageId;
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.profileDetails.id}/update_password?old_password=${this.state.personalDetails.old_password}&new_password=${this.state.personalDetails.new_password}`
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );
        runEngine.sendMessage(quoteGraphRequestMessage.id, quoteGraphRequestMessage);

        return true;
    }

    deleteUserAccount = () => {
        if (!this.state.isChecked) {
            this.setState({ errors: configJSON.deleteCheckError })
        } else {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };
        const data = {
            current_password: this.state.personalDetails.old_password,
            delete_reason_id: this.state.reasonValue,
            feedback: this.state.personalDetails.feedback
        }
        const quoteGraphRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteUserId = quoteGraphRequestMessage.messageId;
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/delete_account/?id=${this.state.profileDetails.id}`
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(quoteGraphRequestMessage.id, quoteGraphRequestMessage);

        return true;
    }}

    validatePassword = (name: any, value: any) => {
        // Regular expressions for validation
        const minLengthRegex = /^(?!.*\s).{8,}$/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /\d/;
        const specialCharRegex = /[!@£$%&]/;

        const trimmedValue = value.trim();
        const newPasswordRegex = /^[!@£$%&a-zA-Z0-9]*$/;

        if (!newPasswordRegex.test(value)) {
            if (value !== '') {
                return;
            }
        }

        const isMinLengthValid = minLengthRegex.test(trimmedValue);
        const isUppercaseValid = uppercaseRegex.test(trimmedValue);
        const isNumberValid = numberRegex.test(trimmedValue);
        const isSpecialCharValid = specialCharRegex.test(trimmedValue);
        this.setState(prevData => ({
            ...prevData,
            isPasswordValid: {
                minimumEightCharacters: isMinLengthValid,
                oneUpperCase: isUppercaseValid,
                oneNumber: isNumberValid,
                oneSpecialCharacter: isSpecialCharValid
            }, personalDetails: { ...prevData.personalDetails, [name]: trimmedValue }, passwordErrMsg: ""
        }));
    };

    handlePasswordChange = (name: any, value: any, val: any) => {
        if (name === 'new_password') {
            this.validatePassword(name, value)
        } else {
            this.setState(prevData => ({ ...prevData, personalDetails: { ...prevData.personalDetails, [name]: value, location: val ? val : this.state.personalDetails.location }, passwordErrMsg: "", contactErrMsg: '' }));
        }
    }

    handleChange = (event: any, val: any) => {
        const { name, value } = event.target;
        if (name === 'email') {

            this.setState(prevData => ({ ...prevData, personalDetails: { ...prevData.personalDetails, [name]: value }, isEmailValid: configJSON.emailRegex.test(value) }));
        } else {
            this.handlePasswordChange(name, value, val)
        }
    };

    handelRemoveImage = () => {
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };
        const quoteGraphRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteImageId = quoteGraphRequestMessage.messageId;
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateEmilEndpoint}/${this.state.profileDetails.id}/delete_image`
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        quoteGraphRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(quoteGraphRequestMessage.id, quoteGraphRequestMessage);

        return true;
    }

    handleModalClose = () => {
        this.setState({
            isEditModalOpen: false, isChangeEmailModalOpen: false, isChangePasswordModalOpen: false, isChangeContactModalOpen: false, isPermanentDeleteModalOpen: false,
            isAccountDeleteModalOpen: false,
            isConfirmationDeleteModalOpen: false,
            isAvatarModalOpen: false,
            isProfileModalOpen: false,
            isEmailSentModalOpen: false,
            isPasswordValid:{
                minimumEightCharacters:false,
                oneUpperCase:false,
                oneNumber:false,
                oneSpecialCharacter:false,
            }
        });
    }

    handleLogout = async () => {
        this.setState({ isConfirmationDeleteModalOpen: false })
        localStorage.clear()
        document.cookie = `Token=; expires=Thu, 18 Dec 2013 12:00:00 UTC"; path=/;`;
        document.cookie = `RefreshToken=; expires=Thu, 18 Dec 2013 12:00:00 UTC"; path=/;`;
        this.props.history.push('/login')
    }

    handleProfileRedirection = () => {
        this.props?.history?.push('/profile')
    }

    handleLandingPageRedirection = () => {
        localStorage.clear()
        document.cookie = `Token=; expires=Thu, 18 Dec 2013 12:00:00 UTC"; path=/;`;
        document.cookie = `RefreshToken=; expires=Thu, 18 Dec 2013 12:00:00 UTC"; path=/;`;
        this.props?.history?.push('/')
    }

    handleOpenChangeContactModal = () => {
        this.setState({ isChangeContactModalOpen: true })
    }

    handleOpenChangePasswordModal = () => {
        this.setState(prevData => ({ ...prevData, isChangePasswordModalOpen: true, personalDetails: { ...prevData.personalDetails, old_password: '', new_password: '' } }))
    }

    handleOpenEmailModal = () => {
        this.setState({ isChangeEmailModalOpen: true });
    }

    handleOpenProfileModal = () => {
        this.setState({ isProfileModalOpen: true });
    }

    handleOpenEditModal = () => {
        this.setState({ isEditModalOpen: true });
    }

    handleOpenPermanentDeleteModal = () => {
        this.setState({ isPermanentDeleteModalOpen: true });
        const header = {
            "Content-Type": configJSON.fileUploadApiContentType,
            "token": this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getReasonsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getReasonsEndPoint}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileGetApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleOpenAccountDeleteModal = (responseJson: any, apiRequestCallId: any) => {
        if (apiRequestCallId === this.deleteUserId) {
            if (!(responseJson?.error)) {
                this.setState(prevData => ({ ...prevData, isAccountDeleteModalOpen: true, isPermanentDeleteModalOpen: false, personalDetails: { ...prevData.personalDetails, old_password: "" }, showOldPassword: false }));
            } else {
                this.setState({ passwordErrMsg: responseJson.error })
            }
        }
    }

    handleOpenConfirmationDeleteModal = () => {
        this.setState({ isConfirmationDeleteModalOpen: true });
    }
    handleUpdatedPasswordResponse = (responseJson: any, apiRequestCallId: any) => {
        if (apiRequestCallId === this.updateUserPasswordId)
            if (!(responseJson.error)) {
                this.setState({ isChangePasswordModalOpen: false,updatePasswordPopup:true });
            } else {
                this.setState({ passwordErrMsg: responseJson.error })
            }
    }


    handleDrop = (acceptedFiles: any) => {
        const file = acceptedFiles[0];
        this.setState({ image: file, isAvatarModalOpen: true, isProfileModalOpen: false })
    };

    handleAvatarClick = (index: any) => {
        this.setState({ selectedAvatarIndex: index })
    }

    handleUpdatedEmailResponse = (responseJson: any, apiRequestCallId: any) => {

        if (apiRequestCallId === this.updateUserEmailId && !(responseJson.error)) {
            this.setState(prevData => ({ ...prevData, profileDetails: { ...prevData.profileDetails, email: this.state.personalDetails.email }, isChangeEmailModalOpen: false, isEmailSentModalOpen: true }));
        }
    }

    handleUpdatedUserDetailsResponse = (responseJson: any, apiRequestCallId: any) => {
        if (apiRequestCallId === this.updateUserDetailsId && !(responseJson.error)) {
            this.setState(prevData => ({ ...prevData, profileDetails: { ...prevData.profileDetails, name: this.state.personalDetails.name, job_title: this.state.personalDetails.job_title, company_name: this.state.personalDetails.company_name, location: this.state.personalDetails.location, phone_number: this.state.personalDetails.phone_number }, isChangeContactModalOpen: false, isEditModalOpen: false }));
        }
    }

    handleUpdateContactNumber = (responseJson: any, apiRequestCallId: any) => {
        if (apiRequestCallId === this.updateContactId && !(responseJson.error)) {
            this.setState(prevData => ({ ...prevData, profileDetails: { ...prevData.profileDetails, phone_number: this.state.personalDetails.phone_number }, isChangeContactModalOpen: false }));
        }
    }
    handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ isChecked: event.target.checked,errors:"" })
      };


    handleReasonChange = (event: any) => {
        if (event.target.value !== undefined) {
            this.setState({ reasonValue: event.target.value })
        }
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getProfileDetailsId) {
                this.setState({ personalDetails: { name: responseJson.data.user.id.name, location: responseJson.data.user.id.location, job_title: responseJson.data.user.id.job_title, company_name: responseJson.data.user.id.company_name, email: responseJson.data.user.id.email }, profileDetails: responseJson.data.user.id, profileImageUrl: responseJson.data.user.profile_image_url })
            }
            this.handleUpdatedUserDetailsResponse(responseJson, apiRequestCallId)
            this.handleUpdatedEmailResponse(responseJson, apiRequestCallId)
            this.handleUpdatedPasswordResponse(responseJson, apiRequestCallId)
            this.handleOpenAccountDeleteModal(responseJson, apiRequestCallId);
            this.handleUpdateContactNumber(responseJson, apiRequestCallId)
            if (apiRequestCallId === this.profileImageId) {
                this.setState({ isAvatarModalOpen: false, profileImageUrl: this.state.editor ? this.state.editor.getImage().toDataURL() : this.state.avatars[this.state.selectedAvatarIndex].image, isProfileModalOpen: false, selectedAvatarIndex: "" })
            }
            if (apiRequestCallId === this.getReasonsId) {
                this.setState({ reasonItems: responseJson })
            }
            if (apiRequestCallId === this.deleteImageId) {
                this.setState({ profileImageUrl: "" })
            }
            if (apiRequestCallId === this.getLocationsId) {
                this.setState({ options: responseJson.data.cities_name })
            }
        }

    }
}

// Customizable Area End