// Customizable Area Start
import React from 'react'
import {
    Formik,
    FormikProps,
    Form,
} from 'formik';
import * as Yup from 'yup';
import {
    TextField, Button, Grid, InputAdornment, Box, IconButton,
    Typography,
    FormControlLabel,
    Checkbox,
    Stepper,
    Step,
    StepLabel,
    StepIcon
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import { View, Image } from 'react-native';
import Dropzone from "react-dropzone";
import { Logo } from '../../email-account-registration/src/assets';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import AccountCircle from '@material-ui/icons/AccountCircle';
import './index.css';
import SignupController, { Props } from './SignupController.web';
import AvatarEditor from 'react-avatar-editor';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    home_background, MyInvoiceCheckIcon, Wave, Muscle
} from "../../landingpage/src/assets";
// Customizable Area End

// Customizable Area Start
export interface MyFormValues {
    name: string;
    email: string;
    password: string;
}
export interface RenderStepContentParams {
    step: any;
    avatars: any;
    selectedAvatarIndex: any;
    handleAvatarClick: any;
    handleDrop: any;
    image: any;
    handleSelectAvatar: any;
    handleEditor: any;
    handleSkip: any;
    handleApplyImage: any;
    handleApplyAvatar: any;
    personalDetails: any;
    handleChange: any;
    options: any;
    handleUserDetails: any;
    contactErrMsg: any;
    goToDashboard: any
}

const TermsAndConditionsTextBox = styled(Box)({
   marginTop:"20px"
})

const TermsAndConditionsText = styled(Typography)({
fontFamily:"Poppins",
fontSize:"15px",
fontWeight:400,
lineHeight:"21px",
textAlign:"center",
color:"#05052B",
})

const HighlightedBox = styled(Box)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    gap:"5px",
    marginTop:"3px"
})

const HighlightedTermsAndConditionsText = styled("span")({
    fontWeight:400,
    lineHeight:"21px",
    textAlign:"center",
    fontFamily:"Poppins",
    color:"#e0802e",
    fontSize:"15px",
    cursor:"pointer",
    textDecoration:"underline"
    })

const StyledSignupButton = styled(Button)({
    background: "#292946",
    width: '60%',
    height: "45px",
    textTransform: 'capitalize',
    padding: '16px',
    fontWeight: 600,
    margin: 'auto',
    marginTop: '8%',
    display: 'flex',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
        background: '#292946',
    }

});

const StyledFormTextFeild = styled(TextField)({
    '& input': {
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '35px',
    },
    '& .MuiFormLabel-root[data-shrink="false"]': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
        transform: "translate(12px, -6px) scale(0.70)"
    }

});

const BrowseButtonStyle = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "8px",
        border: "1px solid #df802d",
        color: "#df802d",
        textTransform: "uppercase",
        fontWeight: 500,
        fontSize: "16px",
        width: '100%',
        padding: '4px 8px'
    }
});

const StyledAlert = styled(Alert)({
    marginBottom: "10px"
})

const SaveButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#fff",
        background: "#292946",
        width: '150px',
        textTransform: 'capitalize'
    },
    "&.Mui-disabled": {
        background: "#2929467d",
    },
})

const StyledTextFeildLogin = styled(TextField)({
    '& input': {
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '35px',
        width: '100%',
        backgroundColor: '#fef8f3',
    },
    '& .MuiFormLabel-root[data-shrink="false"]': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
        transform: "translate(12px, -6px) scale(0.70)"
    }
});

export const AutocompleteStyle = styled(Autocomplete)({
    "& .MuiAutocomplete-inputRoot": {
        padding: '5px !important'
    }
})

const CustomStepIcon = styled(StepIcon)({
    "& .MuiStepIcon-root.MuiStepIcon-active": {
        color: 'green' /* Change color to green when active */
    }
})

export const UserSignup = ({ values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    handlePasswordChange, password, handleClickShowPassword, showPassword, isPasswordValid, isEmailEntered, handleEmailEntered, emailErrMsg,navigation }: any) => {
    return (
        <>
            {isEmailEntered ?
                <EmailAccountSignupForm values={values} errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isSubmitting={isSubmitting}
                    handlePasswordChange={handlePasswordChange} password={password} handleClickShowPassword={handleClickShowPassword} showPassword={showPassword} isPasswordValid={isPasswordValid} emailErrMsg={emailErrMsg}  navigation={navigation}/>
                :
                <EnterEmail values={values} errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur} handleEmailEntered={handleEmailEntered} />}
        </>
    )
}

export const EnterEmail = ({ values,
    errors,
    touched,
    handleChange,
    handleBlur, handleEmailEntered }: any) => {
    const emailError = errors?.email && touched?.email ? errors.email : '';
    const isEmailError = !!errors?.email && !!touched?.email;
    return (
        <Box style={{ marginTop: "5%" }}>
            <View style={webStyle.txt}>
                <label style={webStyle.labelTxt}>Email</label>
                <StyledFormTextFeild
                    name="email"
                    id="email"
                    label="Enter your email"
                    data-test-id="txtInputEmail"
                    value={values?.email}
                    variant="outlined"
                    type="email"
                    helperText={
                        emailError
                    }
                    error={
                        !!emailError
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </View>
            <StyledSignupButton
                type="button"
                variant="contained"
                data-test-id="btnEmailRegister"
                color="secondary"
                disabled={isEmailError || values?.email.length <= 0}
                onClick={handleEmailEntered}
            >
                Sign up
            </StyledSignupButton>
        </Box>
    )
}

export const UserDetailsForm = (props: any) => {
    const { personalDetails, handleChange, options, handleSkip, handleUserDetails, contactErrMsg } = props
    const anyFieldFilled = Boolean(personalDetails.company_name && personalDetails.job_title && personalDetails.phone_number && personalDetails.location);
    return (
        <Box>
            <Typography style={{ fontWeight: 550, fontSize: '16px', textAlign: 'center', color: 'black', paddingBottom: '8%' }}>Enter your details</Typography>
            <Box sx={webStyle.labelBox}>
                <label style={webStyle.labelNameStyle}>Location</label>
                <AutocompleteStyle
                    options={options || []}
                    onChange={handleChange}
                    data-testid="autocompleteid"
                    renderInput={(params: any) => (
                        <TextField {...params} name="location" placeholder="Select your location" variant="outlined" />
                    )}
                />
            </Box>
            <Box sx={webStyle.labelBox}>
                <label style={webStyle.labelNameStyle}>Company Name</label>
                <StyledTextFeildLogin
                    name="company_name"
                    id="name"
                    placeholder="Enter your company name"
                    onChange={handleChange}
                    value={personalDetails.company_name}
                    data-testid="nameInputId"
                    variant="outlined"
                    type="text"
                />
            </Box>
            <Box sx={webStyle.labelBox}>
                <label style={webStyle.labelNameStyle}>Job Title</label>
                <StyledTextFeildLogin
                    name="job_title"
                    id="name"
                    placeholder="Enter your job title"
                    data-testid="nameInputId"
                    onChange={handleChange}
                    value={personalDetails.job_title}
                    variant="outlined"
                    type="text"
                />
            </Box>
            <Box sx={webStyle.labelBox}>
                <label style={webStyle.labelNameStyle}>Contact number</label>
                <StyledTextFeildLogin
                    name="phone_number"
                    id="name"
                    data-testid="nameInputId"
                    value={personalDetails.phone_number}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder="Enter your contact number"
                    type="tel"
                    helperText={contactErrMsg}
                    error={contactErrMsg?.length > 0}
                />
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '14px', justifyContent: 'center', marginTop: '7%', alignItems: 'center' }}>
                {anyFieldFilled &&
                    <SaveButton variant='contained' onClick={handleUserDetails}>
                        Save
                    </SaveButton>
                }
                <Button variant="text" style={{ fontWeight: 500, textTransform: 'initial' }} onClick={handleSkip}>
                    Skip for now
                </Button>
            </Box>

        </Box>
    )
}

export const SelectProfilePic = (props: any) => {
    const { avatars, selectedAvatarIndex, handleAvatarClick, handleDrop, image, handleSelectAvatar, handleEditor, handleSkip, handleApplyImage, handleApplyAvatar } = props
    return (
        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', flexDirection: 'column' }}>
            {(selectedAvatarIndex !== "" || image !== null) ?
                <>
                    {selectedAvatarIndex !== "" ?
                        <img src={avatars[selectedAvatarIndex].image} alt='' style={{ width: '50%' }} />
                        :
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '25px' }}>
                            <Typography style={{ color: 'black', fontWeight: 500 }}>Add profile image</Typography>
                            {image && (
                                <div>
                                    <AvatarEditor
                                        ref={handleEditor}
                                        image={image}
                                        width={200}
                                        height={200}
                                        border={50}
                                        borderRadius={150}
                                        // color={[255, 255, 255, 0.6]}
                                        color={[0, 0, 0, 0.72]}
                                        scale={1.2}
                                        style={webStyle.avatarStyle}
                                    />
                                </div>
                            )}
                        </Box>
                    }
                </>
                :
                <>
                    <AccountCircle style={{ color: 'gray', fontSize: 100 }} />
                    <Box style={{ display: "flex", justifyContent: 'center', gap: '5%', width: '100%', marginBottom: '5%' }}>
                        <Dropzone
                            accept={{
                                'image/*': ['.jpeg', '.png']
                            }}
                            onDrop={handleDrop}
                            data-testid="dropzone"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input id="input" {...getInputProps()} />
                                    <BrowseButtonStyle>Browse Image</BrowseButtonStyle>
                                </div>
                            )}
                        </Dropzone>
                    </Box>
                    <Typography style={{ fontWeight: 500 }}>OR</Typography>
                </>
            }
            {image === null &&
                <Box>
                    <Typography style={{ fontWeight: 500, width: '100%', paddingLeft: '3%', paddingBottom: '3%' }}>Select Avatar</Typography>
                    <Box style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '5%' }}>
                        {avatars?.map((item: any, index: any) => {
                            return (
                                <img key={index} style={selectedAvatarIndex === index ? webStyle.selectedAvatarStyle : webStyle.avatarImageStyle} src={item.image} alt="" onClick={() => handleAvatarClick(index)}
                                />
                            )
                        })}
                    </Box>
                </Box>
            }
            {(selectedAvatarIndex !== "" || image !== null) &&
                <SaveButton variant='contained' onClick={image !== null ? handleApplyImage : handleApplyAvatar}>
                    Save
                </SaveButton>
            }
            <Box style={{ display: 'flex' }}>
                {(selectedAvatarIndex !== '' || image !== null) &&
                    <>
                        {selectedAvatarIndex === "" ?
                            <Button variant="text" style={{ fontWeight: 500, textTransform: 'initial', color: 'orange', textDecoration: 'underline' }} onClick={handleSelectAvatar}>
                                Select Avatar
                            </Button>
                            :
                            <Dropzone
                                accept={{
                                    'image/*': ['.jpeg', '.png']
                                }}
                                onDrop={handleDrop}
                                data-testid="dropzone2"
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input id="input" {...getInputProps()} />
                                        <Button variant="text" style={{ fontWeight: 500, textTransform: 'initial', color: 'orange', textDecoration: 'underline' }}>
                                            Browse Image
                                        </Button>
                                    </div>
                                )}
                            </Dropzone>
                        }
                    </>
                }
                <Button variant="text" style={{ fontWeight: 500, textTransform: 'initial' }} onClick={handleSkip}>Skip for now</Button>
            </Box>
        </Box>
    )
}

export function renderStepContent({
    step,
    avatars,
    selectedAvatarIndex,
    handleAvatarClick,
    handleDrop,
    image,
    handleSelectAvatar,
    handleEditor,
    handleSkip,
    handleApplyImage,
    handleApplyAvatar,
    personalDetails,
    handleChange,
    options,
    handleUserDetails,
    contactErrMsg,
    goToDashboard
}: RenderStepContentParams) {
    switch (step) {
        case 0:
            return <SelectProfilePic avatars={avatars} selectedAvatarIndex={selectedAvatarIndex} handleAvatarClick={handleAvatarClick} handleDrop={handleDrop} image={image} handleSelectAvatar={handleSelectAvatar} handleEditor={handleEditor} handleSkip={handleSkip} handleApplyImage={handleApplyImage} handleApplyAvatar={handleApplyAvatar} />;
        case 1:
            return <UserDetailsForm personalDetails={personalDetails} handleChange={handleChange} options={options} handleSkip={handleSkip} handleUserDetails={handleUserDetails} contactErrMsg={contactErrMsg} />;
        case 2:
            return <SaveButton onClick={goToDashboard} style={{ width: '100%' }} variant='contained'>
                Go to Dashboard
            </SaveButton>;
    }
}

// Customizable Area End
class Signup extends SignupController {

    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start


    initialValues: MyFormValues = { name: this.state.name, email: this.state.email, password: this.state.password };
    onSubmitHandler = (values: any, func: any) => {
        this.setState({ name: values.name, email: values.email, password: this.state.password })
        this.onSubmit()
        setTimeout(() => {
            func.setSubmitting(false)
        }, 2000)
    };
    // Customizable Area End


    render() {
        // Customizable Area 
        return (

            <div style={webStyle.imageContainer}>
                {/* 
        // @ts-ignore */}
                <View style={webStyle.signUp}>
                    <Grid container spacing={3}>

                        <Grid item style={{ padding: 0 }} sm={6} lg={8}>
                            {/* 
        // @ts-ignore */}
                            <i style={webStyle.logoWrap} onClick={this.gotoHome}>
                                {/* 
                                // @ts-ignore */}
                                <img
                                    style={{ width: '30%' }}
                                    src={MyInvoiceCheckIcon}
                                    alt=""
                                />
                            </i>
                            <Box style={{ marginTop: '7%', color: 'white' }}>
                                <Typography style={{ fontSize: '20px', fontWeight: 550 }}>Navigating Construction Costs Hassel-Free</Typography>
                                <Typography style={{ width: '57%', marginTop: '2%' }}>My Invoice Check simplifies the comparison between project quotes and invoices, ensuring clarity and confidence in every transaction</Typography>
                            </Box>
                            {/* 
        // @ts-ignore */}
                        </Grid>

                        <Grid item style={{ padding: 0, paddingBottom: '4%' }} sm={6} lg={4}>
                            {/* 
        // @ts-ignore */}
                            <View style={webStyle.signFormStyle}>
                                {/* 
        // @ts-ignore */}

                                {/* 
        // @ts-ignore */}
                                {this.state.activeStep === 2 ?
    <h3 style={webStyle.heading}>Profile changes saved successfully</h3> :
    <div>
        <h3 style={webStyle.heading}>
            {this.state.flag ? 
                <span>Enhance Your Profile<img src={Muscle} alt="" style={{ marginLeft: '1%', width: '6%' }} /></span> : 
                <span>Welcome to My Invoice Check<img src={Wave} alt="" style={{ marginLeft: '1%', width: '6%' }} /></span>
            }
        </h3>
        {!this.state.flag && 
            <div style={{ textAlign: 'center', color: '#333' }}>
                <p style={{fontSize:'16px', color: '#292946', fontWeight:401}}>It seems you don't have an account with us.</p>
                <p style={{fontSize:'16px', color: '#292946', fontWeight:401}}>Join us now!</p>
            </div>
        }
    </div>
}

                                {(this.state.flag && this.state.image !== null && this.state.activeStep === 0) && <Typography style={{ color: 'black', textAlign: 'center', fontWeight: 400 }}>A few steps only...</Typography>}
                                {(this.state.flag && (this.state.image === null || this.state.activeStep === 1) && (this.state.activeStep === 0 || this.state.activeStep === 1)) && <Typography style={{ color: 'black', textAlign: 'center', fontWeight: 400 }}> Let's make this profile truly yours</Typography>}
                                {this.state.flag ?
                                    <>
                                        <Stepper activeStep={this.state.activeStep}>
                                            {this.state.steps.map((label: any, index: any) => (
                                                <Step key={label}>
                                                    <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        {renderStepContent({
                                            step: this.state.activeStep,
                                            avatars: this.state.avatars,
                                            selectedAvatarIndex: this.state.selectedAvatarIndex,
                                            handleAvatarClick: this.handleAvatarClick,
                                            handleDrop: this.handleDrop,
                                            image: this.state.image,
                                            handleSelectAvatar: this.handleSelectAvatar,
                                            handleEditor: this.handleEditor,
                                            handleSkip: this.handleSkip,
                                            handleApplyImage: this.handleApplyImage,
                                            handleApplyAvatar: this.handleApplyAvatar,
                                            personalDetails: this.state.personalDetails,
                                            handleChange: this.handleValueChange,
                                            options: this.state.options,
                                            handleUserDetails: this.handleUserDetails,
                                            contactErrMsg: this.state.contactErrMsg,
                                            goToDashboard: this.goToDashboard
                                        })}
                                    </>
                                    :
                                    <Formik
                                        data-testid="formikId"
                                        initialValues={this.initialValues}
                                        onSubmit={this.onSubmitHandler}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string()
                                                .email()
                                                .required('Enter valid email'),
                                            name: Yup.string().required('Please enter full name'),
                                            password: Yup.string()
                                        })}
                                    >
                                        {(props: FormikProps<MyFormValues>) => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                handleBlur,
                                                handleChange,
                                                isSubmitting,
                                            } = props
                                            const customHandleChange = (event: any) => {
                                                this.setState({ emailErrMsg: '' })
                                                handleChange(event);
                                            };
                                            return (
                                                //@ts-ignore
                                                <Form translate={undefined}>
                                                    <UserSignup
                                                        values={values}
                                                        errors={errors}
                                                        touched={touched}
                                                        handleChange={customHandleChange}
                                                        handleBlur={handleBlur}
                                                        isSubmitting={isSubmitting}
                                                        handlePasswordChange={this.handlePasswordChange}
                                                        password={this.state.password}
                                                        handleClickShowPassword={this.handleClickShowPassword}
                                                        showPassword={this.state.showPassword}
                                                        isPasswordValid={this.state.isPasswordValid}
                                                        handleEmailEntered={this.handleEmailEntered}
                                                        isEmailEntered={this.state.isEmailEntered}
                                                        emailErrMsg={this.state.emailErrMsg}
                                                        navigation={this.props.navigation}
                                                    />
                                                </Form>
                                            )
                                        }}
                                    </Formik>}
                            </View>
                        </Grid>
                    </Grid>
                </View>
            </div >
        )
        // Customizable Area End
    }
}
{/* 
    // @ts-ignore */}
export { Signup }
// Customizable Area Start
export const EmailAccountSignupForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    handlePasswordChange,
    password,
    handleClickShowPassword,
    showPassword,
    isPasswordValid,
    emailErrMsg,
    navigation
}: any) => {
    const isFieldError = (fieldName: string) => !!errors?.[fieldName] && !!touched?.[fieldName];

    const getFieldError = (fieldName: string) => errors?.[fieldName] && touched?.[fieldName] ? errors[fieldName] : '';

    const isDisabled =
        isSubmitting ||
        ['name', 'email', 'password'].some(field => isFieldError(field)) ||
        !(isPasswordValid?.minimumEightCharacters &&
            isPasswordValid?.oneUpperCase &&
            isPasswordValid?.oneNumber &&
            isPasswordValid?.oneSpecialCharacter);

    return (
        <Box style={{ marginTop: '20px' }}>
            <View style={webStyle.txt}>
                <label style={webStyle.labelTxt}>Name</label>
                <StyledFormTextFeild
                    name="name"
                    id="name"
                    label="Enter your name"
                    data-test-id="txtInputName"
                    value={values?.name}
                    type="text"
                    variant="outlined"
                    helperText={getFieldError('name')}
                    error={!!getFieldError('name')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </View>
            <View style={webStyle.txt}>
                <label style={webStyle.labelTxt}>Email</label>
                <StyledFormTextFeild
                    name="email"
                    id="email"
                    label="Enter your email"
                    data-test-id="txtInputEmail"
                    value={values?.email}
                    variant="outlined"
                    type="email"
                    helperText={getFieldError('email') || emailErrMsg}
                    error={!!getFieldError('email') || emailErrMsg?.length > 0}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete='off'
                />
            </View>
            <View style={webStyle.txt}>
                <label style={webStyle.labelTxt}>Password</label>
                <StyledFormTextFeild
                    name="password"
                    id="password"
                    label="Enter your password"
                    data-test-id="txtInputPassword"
                    value={password}
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    helperText={getFieldError('password')}
                    error={!!getFieldError('password')}
                    onChange={handlePasswordChange}
                    onBlur={handleBlur}
                    autoComplete='off'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </View>
            {password?.length > 0 &&
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {[
                        { label: 'Minimum 8 characters', value: isPasswordValid.minimumEightCharacters },
                        { label: '1 Uppercase letter', value: isPasswordValid.oneUpperCase },
                        { label: '1 Number', value: isPasswordValid.oneNumber },
                        { label: '1 Special character', value: isPasswordValid.oneSpecialCharacter },
                    ].map((indicator, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox checked={indicator.value} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon style={{ color: '#008000a1' }} />} />
                            }
                            label={<Typography style={{ color: indicator.value ? '#008000a1' : 'gray' }}>{indicator.label}</Typography>}
                        />
                    ))}
                </Box>
            }
            <StyledSignupButton
                type="submit"
                variant="contained"
                data-test-id="btnEmailRegister"
                color="secondary"
                disabled={isDisabled}
            >
                Signup
            </StyledSignupButton>
            <TermsAndConditionsTextBox>
            <TermsAndConditionsText>By signing up, you agree to My Invoice Check’s</TermsAndConditionsText>
            </TermsAndConditionsTextBox>
            <HighlightedBox>
                <HighlightedTermsAndConditionsText data-test-id="terms-and-conditions" onClick={()=>navigation.navigate("Termsandconditions")}>Terms of Services</HighlightedTermsAndConditionsText><TermsAndConditionsText>and</TermsAndConditionsText><HighlightedTermsAndConditionsText data-test-id="privacy-policy" onClick={()=>navigation.navigate("PrivacyPolicy")}>Privacy Policy</HighlightedTermsAndConditionsText>
            </HighlightedBox>
        </Box>
    )
}
// Customizable Area End
// Customizable Area Start
const webStyle = {
    activeStepIcon: {
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: '#008000a1',
        }
    },
    imageContainer: {
        backgroundImage: `url(${home_background})`,
        backgroundSize: 'cover', /* Cover the entire div with the background image */
        backgroundPosition: 'center', /* Center the background image */
        /* Add other styles as needed */
        height: '100vh',
        padding: '8%',
        overflowY: 'auto' as 'auto'
    },
    passwordBoxStyle: {
        display: 'flex',
        flexDirection: 'column'
    },
    avatarStyle: {
        border: '1px solid #ccc',
        marginTop: '20px',
    },
    labelBox: {
        width: '100%',
        marginBottom: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    labelNameStyle: {
        marginBottom: 7,
        fontWeight: 500,
        width: '100%',
        color: 'black',
        fontSize: '16px',
    },
    userBox: {
        width: '150px',
        height: '150px',
        borderRadius: '100px',
        margin: 'auto',
        overflow: 'hidden',
        background: '#e3e3e3',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    signUp: {
        width: '100%',
        borderRadius: "22px",
        flexShrink: 'inherit',
    },
    signImg: {
        marginTop: 50,
        display: 'flex',
        alignItems: 'center'
    },
    selectedAvatarStyle: { border: '1px solid black', borderRadius: '50%', width: '50px', marginBottom: '4%', cursor: 'pointer' },
    avatarImageStyle: { width: '50px', marginBottom: '4%', cursor: 'pointer' },
    signFormStyle: {
        background: '#fff',
        padding: '35px',
        border: '1px solid rgb(243 241 241)',
        borderRadius: "12px",
        boxShadow: '23px 9px 36px -10px rgb(124 120 120 / 27%)',
    },
    txt: {
        marginBottom: 20,
        fontWeight: 500,
    },
    heading: {
        textAlign: 'center' as 'center',
        fontWeight: 550,
        fontSize: '20px',
        color: 'black'
    },
    labelTxt: {
        marginBottom: 6,
        fontWeight: 500,
        color: 'black'
    },
    leftImage: {
        width: '100%',
        minHeight: '560px',
        maxWidth: '550px',
        '@media (max-width:1620px)': {
            minHeight: '400px !important',
        }
    },
    logoWrap: {
        cursor: 'pointer',
        display: 'block'
    }

}
{/* 
        // @ts-ignore */}
// export default withRouter(EmailAccountRegistrationWeb)
// Customizable Area End

