// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
export const configJSON = require("./config");

import { Message } from "../../../framework/src/Message";
import moment from "moment";
import Emitter from "./util";

export interface Props {
  navigation?: any;
  id?: any;
  history?: any;
  notificationStates?: {};
  openDialog?: boolean;
  handleCloseDialogFunction?: () => void;
  handleImageChange?: any;
  handleSaveData?: any;
  tabValue?: number;
  handleNotificationClick: (data: []) => void;
  deletenotificationMsg: any;
  handleNotificationDeleteData: (data: {}) => void;
  // Customizable Area End
}

export interface ListProps {
  quotes?: any;
  invoice?: any;
}

type notification = {
  date: any;
  data: any[];
}

interface S {
  // Customizable Area Start
  open: boolean;
  id: any;
  anchorEl: HTMLButtonElement | null;
  imgChange: boolean;
  SmallEnchor: HTMLButtonElement | null;
  smallPopoverOpen: boolean;
  simplePopover: string | undefined;
  myAncher: any;
  apiError: boolean;
  serverSideError: boolean;
  notificationList: notification[];
  selectedItem: any;
  token: string | undefined;
  calledDeleteFunction: boolean;
  badgeCount: number;
  openAddProjectDialog: any;
  inputName: any;
  addNameErrMsg: any,
  goToReport:boolean,
}
interface SS {
  id: any;
}
export default class NotificationPopupController extends BlockComponent<
  Props,
  S,
  SS
> {
  deleteNotificationsApiId: string = "";
  allNotificationApiId: string = "";
  replyNotificationApiId: string = "";
  markAsReadNotificationApiId: string = "";
  addProjectId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      id: "",
      anchorEl: null,
      imgChange: true,
      smallPopoverOpen: false,
      open: false,
      serverSideError: false,
      SmallEnchor: null,
      simplePopover: undefined,
      apiError: false,
      notificationList: [],
      myAncher: null,
      selectedItem: {},
      token: "",
      calledDeleteFunction: false,
      badgeCount: 0,
      openAddProjectDialog: false,
      inputName: '',
      addNameErrMsg: '',
      goToReport:false
    };
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    const getToken: any = await document.cookie
      .split("; ")
      .find((row) => row.startsWith("Token="))
      ?.split("=")[1];
    this.setState({ token: getToken });
    this.allNotificationListApi();
    Emitter.on(
      "FETCH_NOTIFICATION_LIST_FOR_POPUP",
      this.allNotificationListApi
    );
  }

  async componentWillUnmount() {
    Emitter.off("FETCH_NOTIFICATION_LIST_FOR_POPUP", undefined);
  }

  handleAddProjectResponse = (responseJson: any) => {
    if (responseJson.errors) {
      if(responseJson.errors[0].project){
        this.setState({ addNameErrMsg: responseJson.errors[0].project })
      }else{
        this.setState({ addNameErrMsg: responseJson.errors })
      }
     
    } else {
      this.setState({ openAddProjectDialog: false, inputName: '', open: false });
    }
  }

  handleReportText = (text: any) => {
    const regex = /\$[\d,]+\.\d{1,2}/;
    const newTextReq = text.replace(regex, '').trim();
    return newTextReq
  }

  redirectToReportDetails = () => {
    const data = { id: this.state.selectedItem.attributes.report_id }
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(getName(MessageEnum.NavigationTargetMessage), "ViewReportDetails");
    msgs.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data);
    msgs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgs)
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.deleteNotificationsApiId) {
        this.handleDeleteNotificationApiResponse(responseJson);
      }
      if (apiRequestCallId === this.allNotificationApiId) {
        this.handleAllNotificationListApiResponse(responseJson);
      }

      if (apiRequestCallId === this.replyNotificationApiId) {
        this.handleNotificationReplyData(responseJson)
      }

      if (apiRequestCallId === this.markAsReadNotificationApiId) {
        this.handleMarkAsReadNotificationsAPiResponse(responseJson)
      }

      if (apiRequestCallId === this.addProjectId) {
        this.handleAddProjectResponse(responseJson)
      }
    }
  }

  handleNotificationReplyData = (responseJson: any) => {
    if (responseJson.data) {
      this.handleCloseFunction();
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'NotificationPanel'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
        data: responseJson.data,
      });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event?.currentTarget }, () => {
      this.setState({ open: Boolean(this.state.anchorEl) }, () => {
        this.setState({ id: this.state.open });
      });
    });
  };

  handleMarkAsReadNotificationsAPiResponse = (responseJson: any) => {
    if (responseJson.error || responseJson.errors) {
      this.setState({ serverSideError: true })
    }
    else {
      Emitter.emit("FETCH_TAB_NOTIFICATION_LIST", {});
      this.allNotificationListApi()
    }
  }

  handleDeleteNotificationApiResponse = (responseJson: any) => {
    if (responseJson) {
      if (responseJson.error || responseJson.errors) {
        this.setState({ serverSideError: true });
      } else {
        this.allNotificationListApi();
        this.setState({ selectedItem: {} });
        Emitter.emit("FETCH_TAB_NOTIFICATION_LIST", {});
      }
    }
  };

  handleAllNotificationListApiResponse = (responseJson: any) => {
    if (responseJson) {
      if (responseJson.error || responseJson.errors) {
        this.setState({
          serverSideError: true,
          apiError: true,
          notificationList: [],
          selectedItem: {},
          badgeCount: 0
        });
      } else {
        const result = this.sortNotificationsData(responseJson.data);
        const unReadNotifications = result.reduce((acc: notification[], list) => {
          let filterData = list.data.filter((x) => x.attributes.is_read !== true);
          if (filterData.length > 0) {
            acc.push({ date: list.date, data: filterData });
          }
          return acc;

        }, [] as notification[]);
        const badgeCount = unReadNotifications.flatMap((x) => x.data).length;
        this.setState({
          serverSideError: false,
          apiError: false,
          selectedItem: {},
          notificationList: result,
          badgeCount: badgeCount,
        });
      }
    }
  };

  handleDeleteAll = () => {
    this.setState({ smallPopoverOpen: false });
    this.deleteNotificationApi();
  };

  getNewDate = (date: string | number | Date) =>
    new Date(date).toLocaleDateString();

  handleDatesDifference = (diffDays: number) =>
    diffDays === 1 ? "Yesterday" : `${diffDays} days ago`;

  convertDates = (date: string | number | Date) => {
    const oldDate: any = new Date(date);
    const diffDays = moment().utc().diff(oldDate, "days");
    return diffDays === 0 ? "Today" : this.handleDatesDifference(diffDays);
  };

  sortNotificationsData = (data: any[]) => {
    let result: {
      date: any;
      data: any[];
    }[] = [];
    data.forEach((value, index) => {
      let foundIndex = result.findIndex(
        (item) => item.date === this.getNewDate(value.attributes.created_at)
      );
      if (foundIndex >= 0) result[foundIndex].data.push(value);
      else {
        result.push({
          date: this.getNewDate(value.attributes.created_at),
          data: [value],
        });
      }
    });
    return result;
  };

  handleCloseFunction = () => {
    this.setState({ anchorEl: null, open: false, id: false });
  };

  deleteNotificationApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteNotificationsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteNotificationsApiEndPoint}${this.state.selectedItem.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  allNotificationListApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allNotificationApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClickPopover = (event: any, item: any,goToReport:boolean) => {
    this.setState(
      { SmallEnchor: event.currentTarget, selectedItem: item },
      () => {
        this.setState({ smallPopoverOpen: Boolean(this.state.SmallEnchor),goToReport });
      }
    );
  };

  handleOpenDialog = (event: any) => {
    event.stopPropagation();
    this.setState({ openAddProjectDialog: true })
  }

  handleAddProject = () => {
    const header = {
      token: this.state.token
    };

    const formData: any = new FormData();

    formData.append('project', this.state.inputName);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addProjectId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addProjectEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  handleClose = () => {
    this.setState({ openAddProjectDialog: false, inputName: '', addNameErrMsg: '' });
  };

  handleInputChange = (event: any) => {
    const { target: { value } } = event;
    this.setState({ inputName: value, addNameErrMsg: '' })
  }

  handleReplyNotification = (items: any) => {
    const header = {
      "token": this.state.token
    };

    const clickHereNotificationRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.replyNotificationApiId = clickHereNotificationRequestMessage.messageId;

    clickHereNotificationRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClickNotificationApiEndPoint}/${items?.attributes?.id || this.state.selectedItem?.attributes?.id}`
    );
    clickHereNotificationRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    clickHereNotificationRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(clickHereNotificationRequestMessage.id, clickHereNotificationRequestMessage);

  }

  markAsReadNotificationReplyApi = (items: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadNotificationApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markAsReadNotificationApiEndPoint}${this.state.selectedItem?.attributes?.id || items?.attributes?.id || items}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putNotificationsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleClosePopover = () => {
    this.setState({ SmallEnchor: null, smallPopoverOpen: false });
  };

}
// Customizable Area End
